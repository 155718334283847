@import 'ngx-toastr/toastr';

html, body { height: 100%; }

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  color: #000 !important;
  background-color: #fff !important;
}

:root {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: white;
}

a {
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.drawing {
  max-height: 150px;
}

.mat-toolbar {
  background: #EAEAE8 !important;
  border-bottom: 1px solid #000;
}

/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
.mat-tab-body-content {
  padding: 5px;
}

.dz-message {
  background-color: #fff !important;
  border: 1px dashed rgba(0, 0, 0, 0.04);
  color: #000 !important;

  &:hover {
    border-color: #6e84a3;
    color: #000;
  }
}

.bg-auth {
  background-color: #3F3F3F !important;
}

.cdk-overlay-container {
  z-index: 9999 !important;
}

.table {
  color: #000 !important;
  thead th {
    background-color: #f5f5f5 !important;
    color: #000 !important;
  }
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mdc-menu-surface--open {
  background: #fff;
}

.mat-mdc-menu-item-highlighted:not([disabled]), .mat-menu-item.cdk-keyboard-focused:not([disabled]), .mat-menu-item.cdk-program-focused:not([disabled]), .mat-menu-item:hover:not([disabled]) {
  background: rgba(0,0,0,.04);
}

ngx-mat-intl-tel-input input {
  bottom: -4px;
}

.country-selector-flag {
  height: 13px !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version.*/
.mat-form-field-infix {
  display: flex !important;
}

.card {
  background-color: #3F3F3F !important;
  border: 1px solid #3F3F3F !important;
}





// Dragula library styles
/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}
