// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For m  ore information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat.m2-define-palette(mat.$m2-indigo-palette);
$frontend-accent: mat.m2-define-palette(mat.$m2-pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat.m2-define-light-theme((
  color: (
    primary: $frontend-primary,
    accent: $frontend-accent,
    warn: $frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-theme);

:root {
  --bs-blue: #2c7be5;
  --bs-indigo: #727cf5;
  --bs-purple: #6b5eae;
  --bs-pink: #ff679b;
  --bs-red: #e63757;
  --bs-orange: #fd7e14;
  --bs-yellow: #f6c343;
  --bs-green: #00d97e;
  --bs-teal: #02a8b5;
  --bs-cyan: #39afd1;
  --bs-gray: #95aac9;
  --bs-gray-dark: #3b506c;
  --bs-primary: #2c7be5;
  --bs-secondary: #6e84a3;
  --bs-success: #00d97e;
  --bs-info: #39afd1;
  --bs-warning: #f6c343;
  --bs-danger: #e63757;
  --bs-light: #152e4d;
  --bs-dark: #12263f;
  --bs-white: #fff;
  --bs-font-sans-serif: Roboto, "Helvetica Neue", sans-serif, sans-serif;
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, hsla(0, 0%, 100%, 0.15), hsla(0, 0%, 100%, 0))
}

*, :after, :before {
  box-sizing: border-box
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #12263f;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(18, 38, 63, 0)
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important
}

hr {
  margin: 1rem 0;
  color: #1e3a5c;
  background-color: currentColor;
  border: 0;
  opacity: 1
}

hr:not([size]) {
  height: 1px
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1.125rem;
  font-weight: 500;
  line-height: 1.1
}

.h1, h1 {
  font-size: 1.625rem
}

.h2, h2 {
  font-size: 1.25rem
}

.h3, h3 {
  font-size: 1.0625rem
}

.h4, h4 {
  font-size: .9375rem
}

.h5, h5 {
  font-size: .8125rem
}

.h6, h6 {
  font-size: .625rem
}

p {
  margin-top: 0;
  margin-bottom: 1rem
}

abbr[data-original-title], abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit
}

ol, ul {
  padding-left: 2rem
}

dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem
}

ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0
}

dt {
  font-weight: 600
}

dd {
  margin-bottom: .5rem;
  margin-left: 0
}

blockquote {
  margin: 0 0 1rem
}

b, strong {
  font-weight: bolder
}

.small, small {
  font-size: .8125rem
}

.mark, mark {
  padding: .2em;
  background-color: #fcf8e3
}

sub, sup {
  position: relative;
  font-size: .75em;
  line-height: 0;
  vertical-align: baseline
}

sub {
  bottom: -.25em
}

sup {
  top: -.5em
}

a {
  color: #2c7be5
}

a, a:hover {
  text-decoration: none
}

a:hover {
  color: #1657af
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none
}

code, kbd, pre, samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 87.5%;
  -ms-overflow-style: scrollbar
}

pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal
}

code {
  font-size: 87.5%;
  color: #2c7be5;
  word-wrap: break-word
}

a > code {
  color: inherit
}

kbd {
  padding: .2rem .4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #283e59;
  border-radius: .25rem
}

kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 600
}

figure {
  margin: 0 0 1rem
}

img, svg {
  vertical-align: middle
}

table {
  caption-side: bottom;
  border-collapse: collapse
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #6e84a3;
  text-align: left
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent
}

tbody, td, tfoot, th, thead, tr {
  border: 0 solid;
  border-color: inherit
}

label {
  display: inline-block
}

button {
  border-radius: 0
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit
}

button, input {
  overflow: visible
}

button, select {
  text-transform: none
}

[role=button] {
  cursor: pointer
}

select {
  word-wrap: normal
}

[list]::-webkit-calendar-picker-indicator {
  display: none
}

[type=button], [type=reset], [type=submit], button {
  -webkit-appearance: button
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
  cursor: pointer
}

::-moz-focus-inner {
  padding: 0;
  border-style: none
}

textarea {
  resize: vertical
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  white-space: normal
}

legend + * {
  clear: left
}

::-webkit-datetime-edit-day-field, ::-webkit-datetime-edit-fields-wrapper, ::-webkit-datetime-edit-hour-field, ::-webkit-datetime-edit-minute, ::-webkit-datetime-edit-month-field, ::-webkit-datetime-edit-text, ::-webkit-datetime-edit-year-field {
  padding: 0
}

::-webkit-inner-spin-button {
  height: auto
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield
}

::-webkit-search-decoration {
  -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
  padding: 0
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button
}

output {
  display: inline-block
}

iframe {
  border: 0
}

summary {
  display: list-item;
  cursor: pointer
}

progress {
  vertical-align: baseline
}

[hidden] {
  display: none !important
}

.lead {
  font-size: 1.17188rem;
  font-weight: 300
}

.display-1 {
  font-size: 4rem
}

.display-1, .display-2 {
  font-weight: 600;
  line-height: 1.1
}

.display-2 {
  font-size: 3.25rem
}

.display-3 {
  font-size: 2.625rem
}

.display-3, .display-4 {
  font-weight: 600;
  line-height: 1.1
}

.display-4 {
  font-size: 2rem
}

.list-inline, .list-unstyled {
  padding-left: 0;
  list-style: none
}

.list-inline-item {
  display: inline-block
}

.list-inline-item:not(:last-child) {
  margin-right: 6px
}

.initialism {
  font-size: .8125rem;
  text-transform: uppercase
}

.blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.17188rem
}

.blockquote > :last-child {
  margin-bottom: 0
}

.blockquote-footer {
  margin-top: -1.5rem;
  margin-bottom: 1.5rem;
  font-size: .8125rem;
  color: #95aac9
}

.blockquote-footer:before {
  content: "\2014\00A0"
}

.img-fluid, .img-thumbnail {
  max-width: 100%;
  height: auto
}

.img-thumbnail {
  padding: .25rem;
  background-color: #12263f;
  border: 1px solid #e3ebf6;
  border-radius: .375rem
}

.figure {
  display: inline-block
}

.figure-img {
  margin-bottom: .75rem;
  line-height: 1
}

.figure-caption {
  font-size: .8125rem;
  color: #95aac9
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: .75rem;
  padding-left: .75rem;
  margin-right: auto;
  margin-left: auto
}

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px
  }
}

@media (min-width: 768px) {
  .container, .container-md, .container-sm {
    max-width: 720px
  }
}

@media (min-width: 992px) {
  .container, .container-lg, .container-md, .container-sm {
    max-width: 960px
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1140px
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2)
}

.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y)
}

.col {
  flex: 1 0 0%
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.33333%
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.66667%
}

.col-auto {
  flex: 0 0 auto;
  width: auto
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333%
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66667%
}

.col-3 {
  flex: 0 0 auto;
  width: 25%
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333%
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66667%
}

.col-6 {
  flex: 0 0 auto;
  width: 50%
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333%
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66667%
}

.col-9 {
  flex: 0 0 auto;
  width: 75%
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333%
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66667%
}

.col-12 {
  flex: 0 0 auto;
  width: 100%
}

.offset-1 {
  margin-left: 8.33333%
}

.offset-2 {
  margin-left: 16.66667%
}

.offset-3 {
  margin-left: 25%
}

.offset-4 {
  margin-left: 33.33333%
}

.offset-5 {
  margin-left: 41.66667%
}

.offset-6 {
  margin-left: 50%
}

.offset-7 {
  margin-left: 58.33333%
}

.offset-8 {
  margin-left: 66.66667%
}

.offset-9 {
  margin-left: 75%
}

.offset-10 {
  margin-left: 83.33333%
}

.offset-11 {
  margin-left: 91.66667%
}

.g-0, .gx-0 {
  --bs-gutter-x: 0
}

.g-0, .gy-0 {
  --bs-gutter-y: 0
}

.g-1, .gx-1 {
  --bs-gutter-x: .1875rem
}

.g-1, .gy-1 {
  --bs-gutter-y: .1875rem
}

.g-2, .gx-2 {
  --bs-gutter-x: .375rem
}

.g-2, .gy-2 {
  --bs-gutter-y: .375rem
}

.g-3, .gx-3 {
  --bs-gutter-x: .75rem
}

.g-3, .gy-3 {
  --bs-gutter-y: .75rem
}

.g-4, .gx-4 {
  --bs-gutter-x: 1.5rem
}

.g-4, .gy-4 {
  --bs-gutter-y: 1.5rem
}

.g-5, .gx-5 {
  --bs-gutter-x: 2.25rem
}

.g-5, .gy-5 {
  --bs-gutter-y: 2.25rem
}

.g-6, .gx-6 {
  --bs-gutter-x: 4.5rem
}

.g-6, .gy-6 {
  --bs-gutter-y: 4.5rem
}

.g-7, .gx-7 {
  --bs-gutter-x: 6.75rem
}

.g-7, .gy-7 {
  --bs-gutter-y: 6.75rem
}

.g-8, .gx-8 {
  --bs-gutter-x: 13.5rem
}

.g-8, .gy-8 {
  --bs-gutter-y: 13.5rem
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333%
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66667%
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66667%
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333%
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66667%
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%
  }
  .offset-sm-0 {
    margin-left: 0
  }
  .offset-sm-1 {
    margin-left: 8.33333%
  }
  .offset-sm-2 {
    margin-left: 16.66667%
  }
  .offset-sm-3 {
    margin-left: 25%
  }
  .offset-sm-4 {
    margin-left: 33.33333%
  }
  .offset-sm-5 {
    margin-left: 41.66667%
  }
  .offset-sm-6 {
    margin-left: 50%
  }
  .offset-sm-7 {
    margin-left: 58.33333%
  }
  .offset-sm-8 {
    margin-left: 66.66667%
  }
  .offset-sm-9 {
    margin-left: 75%
  }
  .offset-sm-10 {
    margin-left: 83.33333%
  }
  .offset-sm-11 {
    margin-left: 91.66667%
  }
  .g-sm-0, .gx-sm-0 {
    --bs-gutter-x: 0
  }
  .g-sm-0, .gy-sm-0 {
    --bs-gutter-y: 0
  }
  .g-sm-1, .gx-sm-1 {
    --bs-gutter-x: .1875rem
  }
  .g-sm-1, .gy-sm-1 {
    --bs-gutter-y: .1875rem
  }
  .g-sm-2, .gx-sm-2 {
    --bs-gutter-x: .375rem
  }
  .g-sm-2, .gy-sm-2 {
    --bs-gutter-y: .375rem
  }
  .g-sm-3, .gx-sm-3 {
    --bs-gutter-x: .75rem
  }
  .g-sm-3, .gy-sm-3 {
    --bs-gutter-y: .75rem
  }
  .g-sm-4, .gx-sm-4 {
    --bs-gutter-x: 1.5rem
  }
  .g-sm-4, .gy-sm-4 {
    --bs-gutter-y: 1.5rem
  }
  .g-sm-5, .gx-sm-5 {
    --bs-gutter-x: 2.25rem
  }
  .g-sm-5, .gy-sm-5 {
    --bs-gutter-y: 2.25rem
  }
  .g-sm-6, .gx-sm-6 {
    --bs-gutter-x: 4.5rem
  }
  .g-sm-6, .gy-sm-6 {
    --bs-gutter-y: 4.5rem
  }
  .g-sm-7, .gx-sm-7 {
    --bs-gutter-x: 6.75rem
  }
  .g-sm-7, .gy-sm-7 {
    --bs-gutter-y: 6.75rem
  }
  .g-sm-8, .gx-sm-8 {
    --bs-gutter-x: 13.5rem
  }
  .g-sm-8, .gy-sm-8 {
    --bs-gutter-y: 13.5rem
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333%
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66667%
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66667%
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333%
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66667%
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%
  }
  .offset-md-0 {
    margin-left: 0
  }
  .offset-md-1 {
    margin-left: 8.33333%
  }
  .offset-md-2 {
    margin-left: 16.66667%
  }
  .offset-md-3 {
    margin-left: 25%
  }
  .offset-md-4 {
    margin-left: 33.33333%
  }
  .offset-md-5 {
    margin-left: 41.66667%
  }
  .offset-md-6 {
    margin-left: 50%
  }
  .offset-md-7 {
    margin-left: 58.33333%
  }
  .offset-md-8 {
    margin-left: 66.66667%
  }
  .offset-md-9 {
    margin-left: 75%
  }
  .offset-md-10 {
    margin-left: 83.33333%
  }
  .offset-md-11 {
    margin-left: 91.66667%
  }
  .g-md-0, .gx-md-0 {
    --bs-gutter-x: 0
  }
  .g-md-0, .gy-md-0 {
    --bs-gutter-y: 0
  }
  .g-md-1, .gx-md-1 {
    --bs-gutter-x: .1875rem
  }
  .g-md-1, .gy-md-1 {
    --bs-gutter-y: .1875rem
  }
  .g-md-2, .gx-md-2 {
    --bs-gutter-x: .375rem
  }
  .g-md-2, .gy-md-2 {
    --bs-gutter-y: .375rem
  }
  .g-md-3, .gx-md-3 {
    --bs-gutter-x: .75rem
  }
  .g-md-3, .gy-md-3 {
    --bs-gutter-y: .75rem
  }
  .g-md-4, .gx-md-4 {
    --bs-gutter-x: 1.5rem
  }
  .g-md-4, .gy-md-4 {
    --bs-gutter-y: 1.5rem
  }
  .g-md-5, .gx-md-5 {
    --bs-gutter-x: 2.25rem
  }
  .g-md-5, .gy-md-5 {
    --bs-gutter-y: 2.25rem
  }
  .g-md-6, .gx-md-6 {
    --bs-gutter-x: 4.5rem
  }
  .g-md-6, .gy-md-6 {
    --bs-gutter-y: 4.5rem
  }
  .g-md-7, .gx-md-7 {
    --bs-gutter-x: 6.75rem
  }
  .g-md-7, .gy-md-7 {
    --bs-gutter-y: 6.75rem
  }
  .g-md-8, .gx-md-8 {
    --bs-gutter-x: 13.5rem
  }
  .g-md-8, .gy-md-8 {
    --bs-gutter-y: 13.5rem
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333%
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66667%
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66667%
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333%
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66667%
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%
  }
  .offset-lg-0 {
    margin-left: 0
  }
  .offset-lg-1 {
    margin-left: 8.33333%
  }
  .offset-lg-2 {
    margin-left: 16.66667%
  }
  .offset-lg-3 {
    margin-left: 25%
  }
  .offset-lg-4 {
    margin-left: 33.33333%
  }
  .offset-lg-5 {
    margin-left: 41.66667%
  }
  .offset-lg-6 {
    margin-left: 50%
  }
  .offset-lg-7 {
    margin-left: 58.33333%
  }
  .offset-lg-8 {
    margin-left: 66.66667%
  }
  .offset-lg-9 {
    margin-left: 75%
  }
  .offset-lg-10 {
    margin-left: 83.33333%
  }
  .offset-lg-11 {
    margin-left: 91.66667%
  }
  .g-lg-0, .gx-lg-0 {
    --bs-gutter-x: 0
  }
  .g-lg-0, .gy-lg-0 {
    --bs-gutter-y: 0
  }
  .g-lg-1, .gx-lg-1 {
    --bs-gutter-x: .1875rem
  }
  .g-lg-1, .gy-lg-1 {
    --bs-gutter-y: .1875rem
  }
  .g-lg-2, .gx-lg-2 {
    --bs-gutter-x: .375rem
  }
  .g-lg-2, .gy-lg-2 {
    --bs-gutter-y: .375rem
  }
  .g-lg-3, .gx-lg-3 {
    --bs-gutter-x: .75rem
  }
  .g-lg-3, .gy-lg-3 {
    --bs-gutter-y: .75rem
  }
  .g-lg-4, .gx-lg-4 {
    --bs-gutter-x: 1.5rem
  }
  .g-lg-4, .gy-lg-4 {
    --bs-gutter-y: 1.5rem
  }
  .g-lg-5, .gx-lg-5 {
    --bs-gutter-x: 2.25rem
  }
  .g-lg-5, .gy-lg-5 {
    --bs-gutter-y: 2.25rem
  }
  .g-lg-6, .gx-lg-6 {
    --bs-gutter-x: 4.5rem
  }
  .g-lg-6, .gy-lg-6 {
    --bs-gutter-y: 4.5rem
  }
  .g-lg-7, .gx-lg-7 {
    --bs-gutter-x: 6.75rem
  }
  .g-lg-7, .gy-lg-7 {
    --bs-gutter-y: 6.75rem
  }
  .g-lg-8, .gx-lg-8 {
    --bs-gutter-x: 13.5rem
  }
  .g-lg-8, .gy-lg-8 {
    --bs-gutter-y: 13.5rem
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%
  }
  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto
  }
  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%
  }
  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%
  }
  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%
  }
  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%
  }
  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto
  }
  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333%
  }
  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%
  }
  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.66667%
  }
  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%
  }
  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }
  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.66667%
  }
  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%
  }
  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333%
  }
  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.66667%
  }
  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%
  }
  .offset-xl-0 {
    margin-left: 0
  }
  .offset-xl-1 {
    margin-left: 8.33333%
  }
  .offset-xl-2 {
    margin-left: 16.66667%
  }
  .offset-xl-3 {
    margin-left: 25%
  }
  .offset-xl-4 {
    margin-left: 33.33333%
  }
  .offset-xl-5 {
    margin-left: 41.66667%
  }
  .offset-xl-6 {
    margin-left: 50%
  }
  .offset-xl-7 {
    margin-left: 58.33333%
  }
  .offset-xl-8 {
    margin-left: 66.66667%
  }
  .offset-xl-9 {
    margin-left: 75%
  }
  .offset-xl-10 {
    margin-left: 83.33333%
  }
  .offset-xl-11 {
    margin-left: 91.66667%
  }
  .g-xl-0, .gx-xl-0 {
    --bs-gutter-x: 0
  }
  .g-xl-0, .gy-xl-0 {
    --bs-gutter-y: 0
  }
  .g-xl-1, .gx-xl-1 {
    --bs-gutter-x: .1875rem
  }
  .g-xl-1, .gy-xl-1 {
    --bs-gutter-y: .1875rem
  }
  .g-xl-2, .gx-xl-2 {
    --bs-gutter-x: .375rem
  }
  .g-xl-2, .gy-xl-2 {
    --bs-gutter-y: .375rem
  }
  .g-xl-3, .gx-xl-3 {
    --bs-gutter-x: .75rem
  }
  .g-xl-3, .gy-xl-3 {
    --bs-gutter-y: .75rem
  }
  .g-xl-4, .gx-xl-4 {
    --bs-gutter-x: 1.5rem
  }
  .g-xl-4, .gy-xl-4 {
    --bs-gutter-y: 1.5rem
  }
  .g-xl-5, .gx-xl-5 {
    --bs-gutter-x: 2.25rem
  }
  .g-xl-5, .gy-xl-5 {
    --bs-gutter-y: 2.25rem
  }
  .g-xl-6, .gx-xl-6 {
    --bs-gutter-x: 4.5rem
  }
  .g-xl-6, .gy-xl-6 {
    --bs-gutter-y: 4.5rem
  }
  .g-xl-7, .gx-xl-7 {
    --bs-gutter-x: 6.75rem
  }
  .g-xl-7, .gy-xl-7 {
    --bs-gutter-y: 6.75rem
  }
  .g-xl-8, .gx-xl-8 {
    --bs-gutter-x: 13.5rem
  }
  .g-xl-8, .gy-xl-8 {
    --bs-gutter-y: 13.5rem
  }
}

@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%
  }
  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto
  }
  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%
  }
  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%
  }
  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%
  }
  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%
  }
  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto
  }
  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333%
  }
  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66667%
  }
  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%
  }
  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333%
  }
  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66667%
  }
  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%
  }
  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333%
  }
  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66667%
  }
  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%
  }
  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333%
  }
  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66667%
  }
  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%
  }
  .offset-xxl-0 {
    margin-left: 0
  }
  .offset-xxl-1 {
    margin-left: 8.33333%
  }
  .offset-xxl-2 {
    margin-left: 16.66667%
  }
  .offset-xxl-3 {
    margin-left: 25%
  }
  .offset-xxl-4 {
    margin-left: 33.33333%
  }
  .offset-xxl-5 {
    margin-left: 41.66667%
  }
  .offset-xxl-6 {
    margin-left: 50%
  }
  .offset-xxl-7 {
    margin-left: 58.33333%
  }
  .offset-xxl-8 {
    margin-left: 66.66667%
  }
  .offset-xxl-9 {
    margin-left: 75%
  }
  .offset-xxl-10 {
    margin-left: 83.33333%
  }
  .offset-xxl-11 {
    margin-left: 91.66667%
  }
  .g-xxl-0, .gx-xxl-0 {
    --bs-gutter-x: 0
  }
  .g-xxl-0, .gy-xxl-0 {
    --bs-gutter-y: 0
  }
  .g-xxl-1, .gx-xxl-1 {
    --bs-gutter-x: .1875rem
  }
  .g-xxl-1, .gy-xxl-1 {
    --bs-gutter-y: .1875rem
  }
  .g-xxl-2, .gx-xxl-2 {
    --bs-gutter-x: .375rem
  }
  .g-xxl-2, .gy-xxl-2 {
    --bs-gutter-y: .375rem
  }
  .g-xxl-3, .gx-xxl-3 {
    --bs-gutter-x: .75rem
  }
  .g-xxl-3, .gy-xxl-3 {
    --bs-gutter-y: .75rem
  }
  .g-xxl-4, .gx-xxl-4 {
    --bs-gutter-x: 1.5rem
  }
  .g-xxl-4, .gy-xxl-4 {
    --bs-gutter-y: 1.5rem
  }
  .g-xxl-5, .gx-xxl-5 {
    --bs-gutter-x: 2.25rem
  }
  .g-xxl-5, .gy-xxl-5 {
    --bs-gutter-y: 2.25rem
  }
  .g-xxl-6, .gx-xxl-6 {
    --bs-gutter-x: 4.5rem
  }
  .g-xxl-6, .gy-xxl-6 {
    --bs-gutter-y: 4.5rem
  }
  .g-xxl-7, .gx-xxl-7 {
    --bs-gutter-x: 6.75rem
  }
  .g-xxl-7, .gy-xxl-7 {
    --bs-gutter-y: 6.75rem
  }
  .g-xxl-8, .gx-xxl-8 {
    --bs-gutter-x: 13.5rem
  }
  .g-xxl-8, .gy-xxl-8 {
    --bs-gutter-y: 13.5rem
  }
}

.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #fff;
  --bs-table-striped-bg: #132a46;
  --bs-table-active-color: #fff;
  --bs-table-active-bg: #132a46;
  --bs-table-hover-color: #fff;
  --bs-table-hover-bg: #132a46;
  width: 100%;
  margin-bottom: 1.5rem;
  color: #fff;
  vertical-align: top;
  border-color: #1e3a5c
}

.table > :not(caption) > * > * {
  padding: 1rem;
  background-color: var(--bs-table-bg);
  background-image: linear-gradient(var(--bs-table-accent-bg), var(--bs-table-accent-bg));
  border-bottom-width: 1px
}

.table > tbody {
  vertical-align: inherit
}

.table > thead {
  vertical-align: bottom
}

.table > :not(:last-child) > :last-child > * {
  border-bottom-color: #1e3a5c
}

.caption-top {
  caption-side: top
}

.table-sm > :not(caption) > * > * {
  padding: 1rem
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0
}

.table-bordered > :not(caption) > * > * {
  border-width: 0 1px
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0
}

.table-striped > tbody > tr:nth-of-type(2n) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color)
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color)
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color)
}

.table-primary {
  --bs-table-bg: #2c7be5;
  --bs-table-striped-bg: #3782e6;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #4188e8;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #3c85e7;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #2c7be5
}

.table-secondary {
  --bs-table-bg: #6e84a3;
  --bs-table-striped-bg: #758aa8;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #7d90ac;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #798daa;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #6e84a3
}

.table-success {
  --bs-table-bg: #00d97e;
  --bs-table-striped-bg: #0ddb84;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #1add8b;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #13dc88;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #00d97e
}

.table-info {
  --bs-table-bg: #39afd1;
  --bs-table-striped-bg: #43b3d3;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #4db7d6;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #48b5d4;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #39afd1
}

.table-warning {
  --bs-table-bg: #f6c343;
  --bs-table-striped-bg: #ecbc44;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #e1b645;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #e7b945;
  --bs-table-hover-color: #fff;
  color: #283e59;
  border-color: #f6c343
}

.table-danger {
  --bs-table-bg: #e63757;
  --bs-table-striped-bg: #e7415f;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #e94b68;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #e84664;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #e63757
}

.table-light {
  --bs-table-bg: #152e4d;
  --bs-table-striped-bg: #213856;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #2c435f;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #273e5a;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #152e4d
}

.table-dark {
  --bs-table-bg: #12263f;
  --bs-table-striped-bg: #1e3149;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #2a3c52;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #24364d;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #12263f
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }
}

@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
  }
}

.form-label {
  margin-bottom: .5rem
}

.col-form-label {
  padding-top: calc(.5rem + 1px);
  padding-bottom: calc(.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5
}

.col-form-label-lg {
  padding-top: calc(.75rem + 1px);
  padding-bottom: calc(.75rem + 1px);
  font-size: .9375rem
}

.col-form-label-sm {
  padding-top: calc(.125rem + 1px);
  padding-bottom: calc(.125rem + 1px);
  font-size: .8125rem
}

.form-text {
  margin-top: 0;
  font-size: .8125rem;
  color: #6e84a3
}

.form-control {
  display: block;
  width: 100%;
  min-height: calc(1.5em + 1rem + 2px);
  padding: .5rem .75rem;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  background-color: #1e3a5c;
  background-clip: padding-box;
  border: 1px solid #1e3a5c;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none
  }
}

.form-control:focus {
  color: #fff;
  background-color: #1e3a5c;
  border-color: #2c7be5;
  outline: 0;
  box-shadow: none
}

.form-control::-webkit-input-placeholder {
  color: #95aac9;
  opacity: 1
}

.form-control::-moz-placeholder {
  color: #95aac9;
  opacity: 1
}

.form-control::-ms-input-placeholder {
  color: #95aac9;
  opacity: 1
}

.form-control::placeholder {
  color: #95aac9;
  opacity: 1
}

.form-control:disabled, .form-control[readonly] {
  background-color: #1e3a5c;
  opacity: 1
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: .5rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #fff;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
  padding-right: 0;
  padding-left: 0
}

.form-control-sm {
  min-height: calc(1.5em + .25rem + 2px);
  padding: .125rem .5rem;
  font-size: .8125rem;
  border-radius: .25rem
}

.form-control-lg {
  min-height: calc(1.5em + 1.5rem + 2px);
  padding: .75rem 1.25rem;
  font-size: .9375rem;
  border-radius: .5rem
}

.form-control-color {
  max-width: 3rem;
  padding: .5rem
}

.form-control-color::-moz-color-swatch {
  border-radius: .375rem
}

.form-control-color::-webkit-color-swatch {
  border-radius: .375rem
}

.form-select {
  display: block;
  width: 100%;
  height: calc(1.5em + 1rem + 2px);
  padding: .5rem 2rem .5rem .75rem;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  vertical-align: middle;
  background-color: #1e3a5c;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 9 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1l3.25 3.25L7.5 1' stroke='%2395AAC9' stroke-width='1.083' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right .75rem center;
  background-size: .75em .75em;
  border: 1px solid #1e3a5c;
  border-radius: .375rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.form-select:focus {
  border-color: #2c7be5;
  outline: 0;
  box-shadow: none
}

.form-select:focus::-ms-value {
  color: #fff;
  background-color: #1e3a5c
}

.form-select[multiple], .form-select[size]:not([size="1"]) {
  height: auto;
  padding-right: .75rem;
  background-image: none
}

.form-select:disabled {
  color: #95aac9;
  background-color: #edf2f9
}

.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #fff
}

.form-select-sm {
  height: calc(1.5em + .25rem + 2px);
  padding-top: .125rem;
  padding-bottom: .125rem;
  padding-left: .5rem;
  font-size: .8125rem
}

.form-select-lg {
  height: calc(1.5em + 1.5rem + 2px);
  padding-top: .75rem;
  padding-bottom: .75rem;
  padding-left: 1.25rem;
  font-size: .9375rem
}

.form-check {
  display: block;
  min-height: 1.40625rem;
  padding-left: 1.5rem;
  margin-bottom: .125rem
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5rem
}

.form-check-input {
  width: 1.25em;
  height: 1.25em;
  margin-top: .125em;
  vertical-align: top;
  background-color: #244166;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  border: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
  transition: background-color .15s ease-in-out, background-position .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .form-check-input {
    transition: none
  }
}

.form-check-input[type=checkbox] {
  border-radius: .375rem
}

.form-check-input[type=radio] {
  border-radius: 50%
}

.form-check-input:active {
  -webkit-filter: none;
  filter: none
}

.form-check-input:focus {
  border-color: transparent;
  outline: 0;
  box-shadow: none
}

.form-check-input:checked {
  background-color: #2c7be5;
  border-color: #2c7be5
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3 6-6'/%3E%3C/svg%3E")
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='2' fill='%23fff'/%3E%3C/svg%3E")
}

.form-check-input[type=checkbox]:indeterminate {
  background-color: #2c7be5;
  border-color: #2c7be5;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3E%3C/svg%3E")
}

.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
  filter: none;
  opacity: .5
}

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
  opacity: .5
}

.form-check-label {
  cursor: pointer
}

.form-switch {
  padding-left: 3.5rem
}

.form-switch .form-check-input {
  width: 3rem;
  margin-left: -3.5rem;
  background-position: 0;
  border-radius: 3rem
}

.form-switch .form-check-input, .form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23152E4D'/%3E%3C/svg%3E")
}

.form-switch .form-check-input:checked {
  background-position: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none
}

.form-file {
  --bs-form-file-height: calc(1.5em + 1rem + 2px);
  position: relative
}

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: var(--bs-form-file-height);
  margin: 0;
  opacity: 0
}

.form-file-input:focus-within ~ .form-file-label {
  border-color: #2c7be5;
  box-shadow: none
}

.form-file-input:disabled ~ .form-file-label .form-file-text, .form-file-input[disabled] ~ .form-file-label .form-file-text {
  background-color: #1e3a5c
}

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: var(--bs-form-file-height);
  border-color: #1e3a5c;
  border-radius: .375rem
}

.form-file-text {
  flex-grow: 1;
  overflow: hidden;
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  border: 1px solid;
  border-color: inherit;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit
}

.form-file-button, .form-file-text {
  display: block;
  padding: .5rem .75rem;
  line-height: 1.5;
  color: #fff;
  background-color: #1e3a5c
}

.form-file-button {
  flex-shrink: 0;
  margin-left: -1px;
  border: 1px solid;
  border-color: inherit;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit
}

.form-file-sm {
  --bs-form-file-height: calc(1.5em + 0.25rem + 2px);
  font-size: .8125rem
}

.form-file-sm .form-file-button, .form-file-sm .form-file-text {
  padding: .125rem .5rem
}

.form-file-lg {
  --bs-form-file-height: calc(1.5em + 1.5rem + 2px);
  font-size: .9375rem
}

.form-file-lg .form-file-button, .form-file-lg .form-file-text {
  padding: .75rem 1.25rem
}

.form-range {
  width: 100%;
  height: 1.3rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none
}

.form-range:focus {
  outline: none
}

.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #12263f, none
}

.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #12263f, none
}

.form-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #12263f, none
}

.form-range::-moz-focus-outer {
  border: 0
}

.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -.25rem;
  background-color: #2c7be5;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -webkit-appearance: none;
  appearance: none
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none
  }
}

.form-range::-webkit-slider-thumb:active {
  background-color: #cbdef9
}

.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e3ebf6;
  border-color: transparent;
  border-radius: 1rem
}

.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #2c7be5;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  -moz-appearance: none;
  appearance: none
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none
  }
}

.form-range::-moz-range-thumb:active {
  background-color: #cbdef9
}

.form-range::-moz-range-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: #e3ebf6;
  border-color: transparent;
  border-radius: 1rem
}

.form-range::-ms-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: 0;
  margin-right: .15rem;
  margin-left: .15rem;
  background-color: #2c7be5;
  border: 0;
  border-radius: 1rem;
  -ms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  appearance: none
}

@media (prefers-reduced-motion: reduce) {
  .form-range::-ms-thumb {
    -ms-transition: none;
    transition: none
  }
}

.form-range::-ms-thumb:active {
  background-color: #cbdef9
}

.form-range::-ms-track {
  width: 100%;
  height: .5rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: .5rem
}

.form-range::-ms-fill-lower, .form-range::-ms-fill-upper {
  background-color: #e3ebf6;
  border-radius: 1rem
}

.form-range::-ms-fill-upper {
  margin-right: 15px
}

.form-range:disabled {
  pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
  background-color: #b1c2d9
}

.form-range:disabled::-moz-range-thumb {
  background-color: #b1c2d9
}

.form-range:disabled::-ms-thumb {
  background-color: #b1c2d9
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%
}

.input-group > .form-control, .input-group > .form-file, .input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0
}

.input-group > .form-control:focus, .input-group > .form-file .form-file-input:focus ~ .form-file-label, .input-group > .form-select:focus {
  z-index: 3
}

.input-group > .form-file > .form-file-input:focus {
  z-index: 4
}

.input-group > .form-file:not(:last-child) > .form-file-label {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group > .form-file:not(:first-child) > .form-file-label {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.input-group .btn {
  position: relative;
  z-index: 2
}

.input-group .btn:focus {
  z-index: 3
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: .5rem .75rem;
  font-size: .9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #6e84a3;
  text-align: center;
  white-space: nowrap;
  background-color: #1e3a5c;
  border: 1px solid #1e3a5c;
  border-radius: .375rem
}

.input-group-lg > .form-control {
  min-height: calc(1.5em + 1.5rem + 2px)
}

.input-group-lg > .form-select {
  height: calc(1.5em + 1.5rem + 2px)
}

.input-group-lg > .btn, .input-group-lg > .form-control, .input-group-lg > .form-select, .input-group-lg > .input-group-text {
  padding: .75rem 1.25rem;
  font-size: .9375rem;
  border-radius: .5rem
}

.input-group-sm > .form-control {
  min-height: calc(1.5em + .25rem + 2px)
}

.input-group-sm > .form-select {
  height: calc(1.5em + .25rem + 2px)
}

.input-group-sm > .btn, .input-group-sm > .form-control, .input-group-sm > .form-select, .input-group-sm > .input-group-text {
  padding: .125rem .5rem;
  font-size: .8125rem;
  border-radius: .25rem
}

.input-group-lg > .form-select, .input-group-sm > .form-select {
  padding-right: 2rem
}

.input-group > .dropdown-toggle:nth-last-child(n+3), .input-group > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.valid-feedback {
  width: 100%;
  margin-top: 0;
  color: #00d97e
}

.valid-feedback, .valid-tooltip {
  display: none;
  font-size: .8125rem
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  max-width: 100%;
  padding: .375rem .75rem;
  margin-top: .1rem;
  color: #fff;
  background-color: #00d97e;
  border-radius: .375rem
}

.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
  display: block
}

.form-control.is-valid, .was-validated .form-control:valid {
  border-color: #00d97e
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
  border-color: #00d97e;
  box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .25)
}

.form-select.is-valid, .was-validated .form-select:valid {
  border-color: #00d97e
}

.form-select.is-valid:focus, .was-validated .form-select:valid:focus {
  border-color: #00d97e;
  box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .25)
}

.form-check-input.is-valid, .was-validated .form-check-input:valid {
  border-color: #00d97e
}

.form-check-input.is-valid:checked, .was-validated .form-check-input:valid:checked {
  background-color: #00d97e
}

.form-check-input.is-valid:focus, .was-validated .form-check-input:valid:focus {
  box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .25)
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
  color: #00d97e
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em
}

.form-file-input.is-valid ~ .form-file-label, .was-validated .form-file-input:valid ~ .form-file-label {
  border-color: #00d97e
}

.form-file-input.is-valid:focus ~ .form-file-label, .was-validated .form-file-input:valid:focus ~ .form-file-label {
  border-color: #00d97e;
  box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .25)
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0;
  font-size: .8125rem;
  color: #e63757
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: .375rem .75rem;
  margin-top: .1rem;
  font-size: .8125rem;
  color: #fff;
  background-color: #e63757;
  border-radius: .375rem
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
  display: block
}

.form-control.is-invalid, .was-validated .form-control:invalid {
  border-color: #e63757
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: #e63757;
  box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .25)
}

.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #e63757
}

.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
  border-color: #e63757;
  box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .25)
}

.form-check-input.is-invalid, .was-validated .form-check-input:invalid {
  border-color: #e63757
}

.form-check-input.is-invalid:checked, .was-validated .form-check-input:invalid:checked {
  background-color: #e63757
}

.form-check-input.is-invalid:focus, .was-validated .form-check-input:invalid:focus {
  box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .25)
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
  color: #e63757
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em
}

.form-file-input.is-invalid ~ .form-file-label, .was-validated .form-file-input:invalid ~ .form-file-label {
  border-color: #e63757
}

.form-file-input.is-invalid:focus ~ .form-file-label, .was-validated .form-file-input:invalid:focus ~ .form-file-label {
  border-color: #e63757;
  box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .25)
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: .5rem .75rem;
  font-size: .9375rem;
  border-radius: .375rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none
  }
}

.btn:hover {
  color: #fff
}

.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: none
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: .65
}

.btn-primary {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5
}

.btn-check:focus + .btn-primary, .btn-primary:focus, .btn-primary:hover {
  color: #fff;
  background-color: #1a68d1;
  border-color: #1862c6
}

.btn-check:focus + .btn-primary, .btn-primary:focus {
  box-shadow: 0 0 0 .15rem rgba(76, 143, 233, .5)
}

.btn-check:active + .btn-primary, .btn-check:checked + .btn-primary, .btn-primary.active, .btn-primary:active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #1862c6;
  border-color: #175dba
}

.btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(76, 143, 233, .5)
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5
}

.btn-secondary {
  color: #fff;
  background-color: #6e84a3;
  border-color: #6e84a3
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus, .btn-secondary:hover {
  color: #fff;
  background-color: #5b7190;
  border-color: #566b88
}

.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  box-shadow: 0 0 0 .15rem rgba(132, 150, 177, .5)
}

.btn-check:active + .btn-secondary, .btn-check:checked + .btn-secondary, .btn-secondary.active, .btn-secondary:active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #566b88;
  border-color: #516580
}

.btn-check:active + .btn-secondary:focus, .btn-check:checked + .btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(132, 150, 177, .5)
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #6e84a3;
  border-color: #6e84a3
}

.btn-success {
  color: #fff;
  background-color: #00d97e;
  border-color: #00d97e
}

.btn-check:focus + .btn-success, .btn-success:focus, .btn-success:hover {
  color: #fff;
  background-color: #00b368;
  border-color: #00a660
}

.btn-check:focus + .btn-success, .btn-success:focus {
  box-shadow: 0 0 0 .15rem rgba(38, 223, 145, .5)
}

.btn-check:active + .btn-success, .btn-check:checked + .btn-success, .btn-success.active, .btn-success:active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #00a660;
  border-color: #009959
}

.btn-check:active + .btn-success:focus, .btn-check:checked + .btn-success:focus, .btn-success.active:focus, .btn-success:active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(38, 223, 145, .5)
}

.btn-success.disabled, .btn-success:disabled {
  color: #fff;
  background-color: #00d97e;
  border-color: #00d97e
}

.btn-info {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1
}

.btn-check:focus + .btn-info, .btn-info:focus, .btn-info:hover {
  color: #fff;
  background-color: #2b99b9;
  border-color: #2991ae
}

.btn-check:focus + .btn-info, .btn-info:focus {
  box-shadow: 0 0 0 .15rem rgba(87, 187, 216, .5)
}

.btn-check:active + .btn-info, .btn-check:checked + .btn-info, .btn-info.active, .btn-info:active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #2991ae;
  border-color: #2688a4
}

.btn-check:active + .btn-info:focus, .btn-check:checked + .btn-info:focus, .btn-info.active:focus, .btn-info:active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(87, 187, 216, .5)
}

.btn-info.disabled, .btn-info:disabled {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1
}

.btn-warning {
  color: #283e59;
  background-color: #f6c343;
  border-color: #f6c343
}

.btn-check:focus + .btn-warning, .btn-warning:focus, .btn-warning:hover {
  color: #fff;
  background-color: #f4b71e;
  border-color: #f4b312
}

.btn-check:focus + .btn-warning, .btn-warning:focus {
  box-shadow: 0 0 0 .15rem rgba(215, 175, 70, .5)
}

.btn-check:active + .btn-warning, .btn-check:checked + .btn-warning, .btn-warning.active, .btn-warning:active, .show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #f4b312;
  border-color: #eead0b
}

.btn-check:active + .btn-warning:focus, .btn-check:checked + .btn-warning:focus, .btn-warning.active:focus, .btn-warning:active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(215, 175, 70, .5)
}

.btn-warning.disabled, .btn-warning:disabled {
  color: #283e59;
  background-color: #f6c343;
  border-color: #f6c343
}

.btn-danger {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757
}

.btn-check:focus + .btn-danger, .btn-danger:focus, .btn-danger:hover {
  color: #fff;
  background-color: #db1b3f;
  border-color: #d01a3b
}

.btn-check:focus + .btn-danger, .btn-danger:focus {
  box-shadow: 0 0 0 .15rem rgba(234, 85, 112, .5)
}

.btn-check:active + .btn-danger, .btn-check:checked + .btn-danger, .btn-danger.active, .btn-danger:active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #d01a3b;
  border-color: #c51938
}

.btn-check:active + .btn-danger:focus, .btn-check:checked + .btn-danger:focus, .btn-danger.active:focus, .btn-danger:active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(234, 85, 112, .5)
}

.btn-danger.disabled, .btn-danger:disabled {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757
}

.btn-light {
  color: #fff;
  background-color: #152e4d;
  border-color: #152e4d
}

.btn-check:focus + .btn-light, .btn-light:focus, .btn-light:hover {
  color: #fff;
  background-color: #0d1c2f;
  border-color: #0a1625
}

.btn-check:focus + .btn-light, .btn-light:focus {
  box-shadow: 0 0 0 .15rem rgba(56, 77, 104, .5)
}

.btn-check:active + .btn-light, .btn-check:checked + .btn-light, .btn-light.active, .btn-light:active, .show > .btn-light.dropdown-toggle {
  color: #fff;
  background-color: #0a1625;
  border-color: #07101b
}

.btn-check:active + .btn-light:focus, .btn-check:checked + .btn-light:focus, .btn-light.active:focus, .btn-light:active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(56, 77, 104, .5)
}

.btn-light.disabled, .btn-light:disabled {
  color: #fff;
  background-color: #152e4d;
  border-color: #152e4d
}

.btn-dark {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f
}

.btn-check:focus + .btn-dark, .btn-dark:focus, .btn-dark:hover {
  color: #fff;
  background-color: #0a1421;
  border-color: #070e17
}

.btn-check:focus + .btn-dark, .btn-dark:focus {
  box-shadow: 0 0 0 .15rem rgba(54, 71, 92, .5)
}

.btn-check:active + .btn-dark, .btn-check:checked + .btn-dark, .btn-dark.active, .btn-dark:active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #070e17;
  border-color: #04080d
}

.btn-check:active + .btn-dark:focus, .btn-check:checked + .btn-dark:focus, .btn-dark.active:focus, .btn-dark:active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(54, 71, 92, .5)
}

.btn-dark.disabled, .btn-dark:disabled {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f
}

.btn-white {
  color: #283e59;
  background-color: #fff;
  border-color: #fff
}

.btn-check:focus + .btn-white, .btn-white:focus, .btn-white:hover {
  color: #283e59;
  background-color: #ececec;
  border-color: #e6e6e6
}

.btn-check:focus + .btn-white, .btn-white:focus {
  box-shadow: 0 0 0 .15rem rgba(223, 226, 230, .5)
}

.btn-check:active + .btn-white, .btn-check:checked + .btn-white, .btn-white.active, .btn-white:active, .show > .btn-white.dropdown-toggle {
  color: #283e59;
  background-color: #e6e6e6;
  border-color: #dfdfdf
}

.btn-check:active + .btn-white:focus, .btn-check:checked + .btn-white:focus, .btn-white.active:focus, .btn-white:active:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(223, 226, 230, .5)
}

.btn-white.disabled, .btn-white:disabled {
  color: #283e59;
  background-color: #fff;
  border-color: #fff
}

.btn-outline-primary {
  color: #2c7be5;
  border-color: #2c7be5
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5
}

.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 .15rem rgba(44, 123, 229, .5)
}

.btn-check:active + .btn-outline-primary, .btn-check:checked + .btn-outline-primary, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show, .btn-outline-primary:active {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5
}

.btn-check:active + .btn-outline-primary:focus, .btn-check:checked + .btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
  box-shadow: 0 0 0 .15rem rgba(44, 123, 229, .5)
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #2c7be5;
  background-color: transparent
}

.btn-outline-secondary {
  color: #6e84a3;
  border-color: #6e84a3
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6e84a3;
  border-color: #6e84a3
}

.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 .15rem rgba(110, 132, 163, .5)
}

.btn-check:active + .btn-outline-secondary, .btn-check:checked + .btn-outline-secondary, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show, .btn-outline-secondary:active {
  color: #fff;
  background-color: #6e84a3;
  border-color: #6e84a3
}

.btn-check:active + .btn-outline-secondary:focus, .btn-check:checked + .btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus, .btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 .15rem rgba(110, 132, 163, .5)
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #6e84a3;
  background-color: transparent
}

.btn-outline-success {
  color: #00d97e;
  border-color: #00d97e
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #00d97e;
  border-color: #00d97e
}

.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .5)
}

.btn-check:active + .btn-outline-success, .btn-check:checked + .btn-outline-success, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show, .btn-outline-success:active {
  color: #fff;
  background-color: #00d97e;
  border-color: #00d97e
}

.btn-check:active + .btn-outline-success:focus, .btn-check:checked + .btn-outline-success:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus, .btn-outline-success:active:focus {
  box-shadow: 0 0 0 .15rem rgba(0, 217, 126, .5)
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
  color: #00d97e;
  background-color: transparent
}

.btn-outline-info {
  color: #39afd1;
  border-color: #39afd1
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1
}

.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 .15rem rgba(57, 175, 209, .5)
}

.btn-check:active + .btn-outline-info, .btn-check:checked + .btn-outline-info, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show, .btn-outline-info:active {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1
}

.btn-check:active + .btn-outline-info:focus, .btn-check:checked + .btn-outline-info:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus, .btn-outline-info:active:focus {
  box-shadow: 0 0 0 .15rem rgba(57, 175, 209, .5)
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
  color: #39afd1;
  background-color: transparent
}

.btn-outline-warning {
  color: #f6c343;
  border-color: #f6c343
}

.btn-outline-warning:hover {
  color: #283e59;
  background-color: #f6c343;
  border-color: #f6c343
}

.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 .15rem rgba(246, 195, 67, .5)
}

.btn-check:active + .btn-outline-warning, .btn-check:checked + .btn-outline-warning, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show, .btn-outline-warning:active {
  color: #283e59;
  background-color: #f6c343;
  border-color: #f6c343
}

.btn-check:active + .btn-outline-warning:focus, .btn-check:checked + .btn-outline-warning:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus, .btn-outline-warning:active:focus {
  box-shadow: 0 0 0 .15rem rgba(246, 195, 67, .5)
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
  color: #f6c343;
  background-color: transparent
}

.btn-outline-danger {
  color: #e63757;
  border-color: #e63757
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757
}

.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .5)
}

.btn-check:active + .btn-outline-danger, .btn-check:checked + .btn-outline-danger, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show, .btn-outline-danger:active {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757
}

.btn-check:active + .btn-outline-danger:focus, .btn-check:checked + .btn-outline-danger:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus, .btn-outline-danger:active:focus {
  box-shadow: 0 0 0 .15rem rgba(230, 55, 87, .5)
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: #e63757;
  background-color: transparent
}

.btn-outline-light {
  color: #152e4d;
  border-color: #152e4d
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #152e4d;
  border-color: #152e4d
}

.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 .15rem rgba(21, 46, 77, .5)
}

.btn-check:active + .btn-outline-light, .btn-check:checked + .btn-outline-light, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show, .btn-outline-light:active {
  color: #fff;
  background-color: #152e4d;
  border-color: #152e4d
}

.btn-check:active + .btn-outline-light:focus, .btn-check:checked + .btn-outline-light:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus, .btn-outline-light:active:focus {
  box-shadow: 0 0 0 .15rem rgba(21, 46, 77, .5)
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
  color: #152e4d;
  background-color: transparent
}

.btn-outline-dark {
  color: #12263f;
  border-color: #12263f
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f
}

.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 .15rem rgba(18, 38, 63, .5)
}

.btn-check:active + .btn-outline-dark, .btn-check:checked + .btn-outline-dark, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show, .btn-outline-dark:active {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f
}

.btn-check:active + .btn-outline-dark:focus, .btn-check:checked + .btn-outline-dark:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus, .btn-outline-dark:active:focus {
  box-shadow: 0 0 0 .15rem rgba(18, 38, 63, .5)
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
  color: #12263f;
  background-color: transparent
}

.btn-outline-white {
  color: #fff;
  border-color: #fff
}

.btn-outline-white:hover {
  color: #283e59;
  background-color: #fff;
  border-color: #fff
}

.btn-check:focus + .btn-outline-white, .btn-outline-white:focus {
  box-shadow: 0 0 0 .15rem hsla(0, 0%, 100%, .5)
}

.btn-check:active + .btn-outline-white, .btn-check:checked + .btn-outline-white, .btn-outline-white.active, .btn-outline-white.dropdown-toggle.show, .btn-outline-white:active {
  color: #283e59;
  background-color: #fff;
  border-color: #fff
}

.btn-check:active + .btn-outline-white:focus, .btn-check:checked + .btn-outline-white:focus, .btn-outline-white.active:focus, .btn-outline-white.dropdown-toggle.show:focus, .btn-outline-white:active:focus {
  box-shadow: 0 0 0 .15rem hsla(0, 0%, 100%, .5)
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent
}

.btn-link {
  font-weight: 400;
  color: #2c7be5;
  text-decoration: none
}

.btn-link:hover {
  color: #1657af
}

.btn-link:focus, .btn-link:hover {
  text-decoration: none
}

.btn-link.disabled, .btn-link:disabled {
  color: #b1c2d9
}

.btn-group-lg > .btn, .btn-lg {
  padding: .75rem 1.25rem;
  font-size: .9375rem;
  border-radius: .5rem
}

.btn-group-sm > .btn, .btn-sm {
  padding: .125rem .5rem;
  font-size: .8125rem;
  border-radius: .25rem
}

.btn-block {
  display: block;
  width: 100%
}

.btn-block + .btn-block {
  margin-top: .5rem
}

.fade {
  transition: opacity .15s linear
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none
  }
}

.fade:not(.show) {
  opacity: 0
}

.collapse:not(.show) {
  display: none
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height .35s ease
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none
  }
}

.dropdown, .dropleft, .dropright, .dropup {
  position: relative
}

.dropdown-toggle {
  white-space: nowrap
}

.dropdown-toggle:after {
  display: inline-block;
  margin-left: .2125em;
  vertical-align: .2125em;
  content: "";
  border-top: .25em solid;
  border-right: .25em solid transparent;
  border-bottom: 0;
  border-left: .25em solid transparent
}

.dropdown-toggle:empty:after {
  margin-left: 0
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: .5rem 0;
  margin: 0;
  font-size: .9375rem;
  color: #fff;
  text-align: left;
  list-style: none;
  background-color: #152e4d;
  background-clip: padding-box;
  border: 1px solid #12263f;
  border-radius: .375rem
}

.dropdown-menu-left {
  right: auto;
  left: 0
}

.dropdown-menu-right {
  right: 0;
  left: auto
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto
  }
}

@media (min-width: 1400px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0
  }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0
}

.dropup .dropdown-toggle:after {
  display: inline-block;
  margin-left: .2125em;
  vertical-align: .2125em;
  content: "";
  border-top: 0;
  border-right: .25em solid transparent;
  border-bottom: .25em solid;
  border-left: .25em solid transparent
}

.dropup .dropdown-toggle:empty:after {
  margin-left: 0
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0
}

.dropright .dropdown-toggle:after {
  display: inline-block;
  margin-left: .2125em;
  vertical-align: .2125em;
  content: "";
  border-top: .25em solid transparent;
  border-right: 0;
  border-bottom: .25em solid transparent;
  border-left: .25em solid
}

.dropright .dropdown-toggle:empty:after {
  margin-left: 0
}

.dropright .dropdown-toggle:after {
  vertical-align: 0
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0
}

.dropleft .dropdown-toggle:after {
  display: inline-block;
  margin-left: .2125em;
  vertical-align: .2125em;
  content: "";
  display: none
}

.dropleft .dropdown-toggle:before {
  display: inline-block;
  margin-right: .2125em;
  vertical-align: .2125em;
  content: "";
  border-top: .25em solid transparent;
  border-right: .25em solid;
  border-bottom: .25em solid transparent
}

.dropleft .dropdown-toggle:empty:after {
  margin-left: 0
}

.dropleft .dropdown-toggle:before {
  vertical-align: 0
}

.dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
  right: auto;
  bottom: auto
}

.dropdown-divider {
  height: 0;
  margin: .75rem 0;
  overflow: hidden;
  border-top: 1px solid #12263f
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: .375rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #6e84a3;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0
}

.dropdown-item:focus, .dropdown-item:hover {
  color: #fff;
  background-color: transparent
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: transparent
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #95aac9;
  pointer-events: none;
  background-color: transparent
}

.dropdown-menu.show {
  display: block
}

.dropdown-header {
  display: block;
  padding: .5rem 1.5rem;
  margin-bottom: 0;
  font-size: .8125rem;
  color: inherit;
  white-space: nowrap
}

.dropdown-item-text {
  display: block;
  padding: .375rem 1.5rem;
  color: #6e84a3
}

.btn-group, .btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle
}

.btn-group-vertical > .btn, .btn-group > .btn {
  position: relative;
  flex: 1 1 auto
}

.btn-group-vertical > .btn-check:checked + .btn, .btn-group-vertical > .btn-check:focus + .btn, .btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn-check:checked + .btn, .btn-group > .btn-check:focus + .btn, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
  z-index: 1
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start
}

.btn-toolbar .input-group {
  width: auto
}

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
  margin-left: -1px
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:nth-child(n+3), .btn-group > :not(.btn-check) + .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0
}

.dropdown-toggle-split {
  padding-right: .5625rem;
  padding-left: .5625rem
}

.dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
  margin-left: 0
}

.dropleft .dropdown-toggle-split:before {
  margin-right: 0
}

.btn-group-sm > .btn + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
  padding-right: .375rem;
  padding-left: .375rem
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split {
  padding-right: .9375rem;
  padding-left: .9375rem
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
  width: 100%
}

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
  margin-top: -1px
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.nav-link {
  display: block;
  padding: .5rem 1rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none
  }
}

.nav-link.disabled {
  color: #95aac9;
  pointer-events: none;
  cursor: default
}

.nav-tabs {
  border-bottom: 1px solid #1e3a5c
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border: 1px solid transparent;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  border-color: transparent
}

.nav-tabs .nav-link.disabled {
  color: #95aac9;
  background-color: transparent;
  border-color: transparent
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #fff;
  background-color: transparent;
  border-color: transparent transparent #2c7be5
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.nav-pills .nav-link {
  border-radius: .375rem
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #2c7be5
}

.nav-fill .nav-item, .nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center
}

.nav-justified .nav-item, .nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center
}

.tab-content > .tab-pane {
  display: none
}

.tab-content > .active {
  display: block
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: .75rem 1rem
}

.navbar > .container, .navbar > .container-fluid, .navbar > .container-lg, .navbar > .container-md, .navbar > .container-sm, .navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between
}

.navbar-brand {
  padding-top: 0;
  padding-bottom: 0;
  margin-right: 1rem;
  font-size: 1.0625rem;
  white-space: nowrap
}

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0
}

.navbar-nav .dropdown-menu {
  position: static
}

.navbar-text {
  padding-top: .5rem;
  padding-bottom: .5rem
}

.navbar-collapse {
  align-items: center;
  width: 100%
}

.navbar-toggler {
  padding: .25rem 0;
  font-size: 1.0625rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: .375rem;
  transition: box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none
  }
}

.navbar-toggler:hover {
  text-decoration: none
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100%
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important
  }
  .navbar-expand-sm .navbar-toggler {
    display: none
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important
  }
  .navbar-expand-md .navbar-toggler {
    display: none
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important
  }
  .navbar-expand-lg .navbar-toggler {
    display: none
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important
  }
  .navbar-expand-xl .navbar-toggler {
    display: none
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none
  }
}

.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start
}

.navbar-expand .navbar-nav {
  flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: .5rem;
  padding-left: .5rem
}

.navbar-expand .navbar-collapse {
  display: flex !important
}

.navbar-expand .navbar-toggler {
  display: none
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
  color: #fff
}

.navbar-light .navbar-nav .nav-link {
  color: #6e84a3
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #fff
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(18, 38, 63, .3)
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show > .nav-link {
  color: #fff
}

.navbar-light .navbar-toggler {
  color: #6e84a3;
  border-color: transparent
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%236E84A3' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
  color: #6e84a3
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
  color: #fff
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
  color: #12263f
}

.navbar-dark .navbar-nav .nav-link {
  color: #6e84a3
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
  color: #12263f
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: hsla(0, 0%, 100%, .25)
}

.navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .show > .nav-link {
  color: #12263f
}

.navbar-dark .navbar-toggler {
  color: #6e84a3;
  border-color: transparent
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='%236E84A3' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
  color: #6e84a3
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
  color: #12263f
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #152e4d;
  background-clip: border-box;
  border: 1px solid #1e3a5c;
  border-radius: .5rem
}

.card > hr {
  margin-right: 0;
  margin-left: 0
}

.card > .list-group {
  border-top: inherit;
  border-bottom: inherit
}

.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(.5rem - 1px);
  border-top-right-radius: calc(.5rem - 1px)
}

.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(.5rem - 1px);
  border-bottom-left-radius: calc(.5rem - 1px)
}

.card > .card-header + .list-group, .card > .list-group + .card-footer {
  border-top: 0
}

.card-body {
  flex: 1 1 auto;
  padding: 1.5rem
}

.card-title {
  margin-bottom: .5rem
}

.card-subtitle {
  margin-top: -.25rem
}

.card-subtitle, .card-text:last-child {
  margin-bottom: 0
}

.card-link:hover {
  text-decoration: none
}

.card-link + .card-link {
  margin-left: 1.5rem
}

.card-header {
  padding: 1rem 1.5rem;
  margin-bottom: 0;
  background-color: transparent;
  border-bottom: 1px solid #1e3a5c
}

.card-header:first-child {
  border-radius: calc(.5rem - 1px) calc(.5rem - 1px) 0 0
}

.card-footer {
  padding: 1rem 1.5rem;
  background-color: transparent;
  border-top: 1px solid #1e3a5c
}

.card-footer:last-child {
  border-radius: 0 0 calc(.5rem - 1px) calc(.5rem - 1px)
}

.card-header-tabs {
  margin-right: -.75rem;
  margin-bottom: -1rem;
  margin-left: -.75rem;
  border-bottom: 0
}

.card-header-tabs .nav-link.active {
  background-color: #152e4d;
  border-bottom-color: #152e4d
}

.card-header-pills {
  margin-right: -.75rem;
  margin-left: -.75rem
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem;
  border-radius: calc(.5rem - 1px)
}

.card-img, .card-img-bottom, .card-img-top {
  width: 100%
}

.card-img, .card-img-top {
  border-top-left-radius: calc(.5rem - 1px);
  border-top-right-radius: calc(.5rem - 1px)
}

.card-img, .card-img-bottom {
  border-bottom-right-radius: calc(.5rem - 1px);
  border-bottom-left-radius: calc(.5rem - 1px)
}

.card-group > .card {
  margin-bottom: .75rem
}

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
  }
  .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
    border-top-right-radius: 0
  }
  .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
    border-bottom-right-radius: 0
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }
  .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
    border-top-left-radius: 0
  }
  .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
    border-bottom-left-radius: 0
  }
}

.accordion > .card {
  overflow: hidden
}

.accordion > .card:not(:last-of-type) {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.accordion > .card:not(:first-of-type) {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.accordion > .card > .card-header {
  border-radius: 0;
  margin-bottom: -1px
}

.breadcrumb {
  flex-wrap: wrap;
  padding: .75rem 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: .375rem
}

.breadcrumb, .breadcrumb-item {
  display: flex
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: .35rem
}

.breadcrumb-item + .breadcrumb-item:before {
  display: inline-block;
  padding-right: .35rem;
  content: ""
}

.breadcrumb-item.active {
  color: #95aac9
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none
}

.page, .page-link {
  position: relative;
  display: block;
  color: #fff;
  background-color: #152e4d;
  border: 1px solid #244166
}

.page-link:hover, .page:hover {
  z-index: 2;
  color: #fff;
  background-color: #132a46;
  border-color: #1e3a5c
}

.page-link:focus, .page:focus {
  z-index: 3;
  outline: 0;
  box-shadow: none
}

.page-item:not(:first-child) .page, .page-item:not(:first-child) .page-link {
  margin-left: -1px
}

.page-item.active .page, .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5
}

.page-item.disabled .page, .page-item.disabled .page-link {
  color: #95aac9;
  pointer-events: none;
  background-color: #132a46;
  border-color: #1e3a5c
}

.page, .page-link {
  padding: .5rem .75rem
}

.page-item:first-child .page, .page-item:first-child .page-link {
  border-top-left-radius: .375rem;
  border-bottom-left-radius: .375rem
}

.page-item:last-child .page, .page-item:last-child .page-link {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem
}

.pagination-lg .page, .pagination-lg .page-link {
  padding: .75rem 1.25rem;
  font-size: 1.0625rem
}

.pagination-lg .page-item:first-child .page, .pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: .5rem;
  border-bottom-left-radius: .5rem
}

.pagination-lg .page-item:last-child .page, .pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem
}

.pagination-sm .page, .pagination-sm .page-link {
  padding: .125rem .5rem
}

.pagination-sm .page-item:first-child .page, .pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: .25rem;
  border-bottom-left-radius: .25rem
}

.pagination-sm .page-item:last-child .page, .pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem
}

.badge {
  display: inline-block;
  padding: .33em .5em;
  font-size: 76%;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .375rem
}

.badge:empty {
  display: none
}

.btn .badge {
  position: relative;
  top: -1px
}

.alert {
  position: relative;
  padding: .75rem 1.25rem;
  margin-bottom: 1.5rem;
  border: 1px solid transparent;
  border-radius: .375rem
}

.alert-heading {
  color: inherit
}

.alert-link {
  font-weight: 400
}

.alert-dismissible {
  padding-right: 3.90625rem
}

.alert-dismissible .close {
  position: absolute;
  top: 0;
  right: 0;
  padding: .75rem 1.25rem;
  color: inherit
}

.alert-primary {
  color: #205295
}

.alert-primary .alert-link {
  color: #173b6b
}

.alert-secondary {
  color: #425773
}

.alert-secondary .alert-link {
  color: #2f3e53
}

.alert-success {
  color: #098360
}

.alert-success .alert-link {
  color: #06533d
}

.alert-info {
  color: #266d8b
}

.alert-info .alert-link {
  color: #1b4e63
}

.alert-warning {
  color: #897841
}

.alert-warning .alert-link {
  color: #665a31
}

.alert-danger {
  color: #802f4b
}

.alert-danger .alert-link {
  color: #5b2135
}

.alert-light {
  color: #142a46
}

.alert-light .alert-link {
  color: #09121e
}

.alert-dark {
  color: #12263f
}

.alert-dark .alert-link {
  color: #070e17
}

.alert-white {
  color: #8d97a3
}

.alert-white .alert-link {
  color: #717d8c
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem
  }
}

.progress {
  height: 1rem;
  font-size: .70313rem;
  background-color: #244166;
  border-radius: 200px
}

.progress, .progress-bar {
  display: flex;
  overflow: hidden
}

.progress-bar {
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #2c7be5;
  transition: width .6s ease
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
  background-size: 1rem 1rem
}

.progress-bar-animated {
  -webkit-animation: progress-bar-stripes 1s linear infinite;
  animation: progress-bar-stripes 1s linear infinite
}

@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
    animation: none
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: .375rem
}

.list-group-item-action {
  width: 100%;
  color: #fff;
  text-align: inherit
}

.list-group-item-action:focus, .list-group-item-action:hover {
  z-index: 1;
  color: #fff;
  text-decoration: none;
  background-color: #132a46
}

.list-group-item-action:active {
  color: #fff;
  background-color: #edf2f9
}

.list-group-item {
  position: relative;
  display: block;
  padding: 1rem 1.25rem;
  background-color: transparent;
  border: 1px solid #1e3a5c
}

.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit
}

.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit
}

.list-group-item.disabled, .list-group-item:disabled {
  color: #95aac9;
  pointer-events: none;
  background-color: transparent
}

.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5
}

.list-group-item + .list-group-item {
  border-top-width: 0
}

.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px
}

.list-group-horizontal {
  flex-direction: row
}

.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: .375rem;
  border-top-right-radius: 0
}

.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: .375rem;
  border-bottom-left-radius: 0
}

.list-group-horizontal > .list-group-item.active {
  margin-top: 0
}

.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0
}

.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: .375rem;
    border-bottom-left-radius: 0
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: .375rem;
    border-bottom-left-radius: 0
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: .375rem;
    border-bottom-left-radius: 0
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: .375rem;
    border-bottom-left-radius: 0
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: .375rem;
    border-top-right-radius: 0
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: .375rem;
    border-bottom-left-radius: 0
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
  }
}

.list-group-flush {
  border-radius: 0
}

.list-group-flush > .list-group-item {
  border-width: 0 0 1px
}

.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0
}

.list-group-item-primary {
  color: #205295;
  background-color: #c4daf8
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: #205295;
  background-color: #adccf5
}

.list-group-item-primary.list-group-item-action.active {
  background-color: #205295;
  border-color: #205295
}

.list-group-item-secondary {
  color: #425773;
  background-color: #d6dde5
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: #425773;
  background-color: #c6d0db
}

.list-group-item-secondary.list-group-item-action.active {
  background-color: #425773;
  border-color: #425773
}

.list-group-item-success {
  color: #098360;
  background-color: #b8f4db
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: #098360;
  background-color: #a2f1d0
}

.list-group-item-success.list-group-item-action.active {
  background-color: #098360;
  border-color: #098360
}

.list-group-item-info {
  color: #266d8b;
  background-color: #c8e9f2
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: #266d8b;
  background-color: #b3e1ed
}

.list-group-item-info.list-group-item-action.active {
  background-color: #266d8b;
  border-color: #266d8b
}

.list-group-item-warning {
  color: #897841;
  background-color: #fceeca
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: #897841;
  background-color: #fbe6b2
}

.list-group-item-warning.list-group-item-action.active {
  background-color: #897841;
  border-color: #897841
}

.list-group-item-danger {
  color: #802f4b;
  background-color: #f8c7d0
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: #802f4b;
  background-color: #f5b0bd
}

.list-group-item-danger.list-group-item-action.active {
  background-color: #802f4b;
  border-color: #802f4b
}

.list-group-item-light {
  color: #142a46;
  background-color: #bdc4cd
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: #142a46;
  background-color: #aeb7c2
}

.list-group-item-light.list-group-item-action.active {
  background-color: #142a46;
  border-color: #142a46
}

.list-group-item-dark {
  color: #12263f;
  background-color: #bdc2c9
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: #12263f;
  background-color: #afb5be
}

.list-group-item-dark.list-group-item-action.active {
  background-color: #12263f;
  border-color: #12263f
}

.list-group-item-white, .list-group-item-white.list-group-item-action:focus, .list-group-item-white.list-group-item-action:hover {
  color: #8d97a3
}

.list-group-item-white.list-group-item-action.active {
  background-color: #8d97a3;
  border-color: #8d97a3
}

.close {
  font-size: 1.40625rem;
  font-weight: 600;
  line-height: 1;
  color: #6e84a3;
  text-shadow: none;
  opacity: .5
}

.close:hover {
  color: #6e84a3;
  text-decoration: none
}

.close:focus, .close:hover {
  opacity: .75
}

.close.disabled, .close:disabled {
  pointer-events: none
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0
}

.toast {
  max-width: 300px;
  overflow: hidden;
  font-size: .9375rem;
  background-color: #152e4d;
  background-clip: padding-box;
  border: 1px solid #1e3a5c;
  box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .1);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: .375rem
}

.toast:not(:last-child) {
  margin-bottom: 1rem
}

.toast.showing {
  opacity: 1
}

.toast.show {
  display: block;
  opacity: 1
}

.toast.hide {
  display: none
}

.toast-header {
  display: flex;
  align-items: center;
  padding: .75rem 1rem;
  color: #fff;
  background-color: #152e4d;
  background-clip: padding-box;
  border-bottom: 1px solid #1e3a5c
}

.toast-body {
  padding: 1rem
}

.modal-open {
  overflow: hidden
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: .5rem;
  pointer-events: none
}

.modal.fade .modal-dialog {
  transition: transform .3s ease-out;
  transform: translateY(-50px)
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none
  }
}

.modal.show .modal-dialog {
  transform: none
}

.modal.modal-static .modal-dialog {
  transform: scale(1.02)
}

.modal-dialog-scrollable {
  max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
  overflow: hidden
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem)
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #152e4d;
  background-clip: padding-box;
  border: 1px solid #12263f;
  border-radius: .5rem;
  outline: 0
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #12263f
}

.modal-backdrop.fade {
  opacity: 0
}

.modal-backdrop.show {
  opacity: .5
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  border-bottom: 1px solid #1e3a5c;
  border-top-left-radius: calc(.5rem - 1px);
  border-top-right-radius: calc(.5rem - 1px)
}

.modal-header .close {
  padding: 1rem 1.5rem;
  margin: -1rem -1.5rem -1rem auto
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1.5rem
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 1.25rem;
  border-top: 1px solid #1e3a5c;
  border-bottom-right-radius: calc(.5rem - 1px);
  border-bottom-left-radius: calc(.5rem - 1px)
}

.modal-footer > * {
  margin: .25rem
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem)
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem)
  }
  .modal-sm {
    max-width: 300px
  }
}

@media (min-width: 992px) {
  .modal-lg, .modal-xl {
    max-width: 800px
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px
  }
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0
}

.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0
}

.modal-fullscreen .modal-header {
  border-radius: 0
}

.modal-fullscreen .modal-body {
  overflow-y: auto
}

.modal-fullscreen .modal-footer {
  border-radius: 0
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0
  }
}

@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .8125rem;
  word-wrap: break-word;
  opacity: 0
}

.tooltip.show {
  opacity: 1
}

.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: .8rem;
  height: .4rem
}

.tooltip .tooltip-arrow:before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
  padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
  bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .tooltip-arrow:before, .bs-tooltip-top .tooltip-arrow:before {
  top: 0;
  border-width: .4rem .4rem 0;
  border-top-color: #152e4d
}

.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
  padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .tooltip-arrow, .bs-tooltip-right .tooltip-arrow {
  left: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .tooltip-arrow:before, .bs-tooltip-right .tooltip-arrow:before {
  right: 0;
  border-width: .4rem .4rem .4rem 0;
  border-right-color: #152e4d
}

.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
  padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
  top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .tooltip-arrow:before, .bs-tooltip-bottom .tooltip-arrow:before {
  bottom: 0;
  border-width: 0 .4rem .4rem;
  border-bottom-color: #152e4d
}

.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
  padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .tooltip-arrow, .bs-tooltip-left .tooltip-arrow {
  right: 0;
  width: .4rem;
  height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .tooltip-arrow:before, .bs-tooltip-left .tooltip-arrow:before {
  left: 0;
  border-width: .4rem 0 .4rem .4rem;
  border-left-color: #152e4d
}

.tooltip-inner {
  max-width: 200px;
  padding: .375rem .75rem;
  color: #fff;
  text-align: center;
  background-color: #152e4d;
  border-radius: .375rem
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 10rem;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: .8125rem;
  word-wrap: break-word;
  background-color: #152e4d;
  background-clip: padding-box;
  border: 1px solid #12263f;
  border-radius: .5rem
}

.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: .5rem;
  margin: 0 .5rem
}

.popover .popover-arrow:after, .popover .popover-arrow:before {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid
}

.bs-popover-auto[x-placement^=top], .bs-popover-top {
  margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top] > .popover-arrow, .bs-popover-top > .popover-arrow {
  bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=top] > .popover-arrow:before, .bs-popover-top > .popover-arrow:before {
  bottom: 0;
  border-width: .5rem .5rem 0;
  border-top-color: #12263f
}

.bs-popover-auto[x-placement^=top] > .popover-arrow:after, .bs-popover-top > .popover-arrow:after {
  bottom: 1px;
  border-width: .5rem .5rem 0;
  border-top-color: #152e4d
}

.bs-popover-auto[x-placement^=right], .bs-popover-right {
  margin-left: .5rem
}

.bs-popover-auto[x-placement^=right] > .popover-arrow, .bs-popover-right > .popover-arrow {
  left: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .5rem 0
}

.bs-popover-auto[x-placement^=right] > .popover-arrow:before, .bs-popover-right > .popover-arrow:before {
  left: 0;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #12263f
}

.bs-popover-auto[x-placement^=right] > .popover-arrow:after, .bs-popover-right > .popover-arrow:after {
  left: 1px;
  border-width: .5rem .5rem .5rem 0;
  border-right-color: #152e4d
}

.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
  margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom] > .popover-arrow, .bs-popover-bottom > .popover-arrow {
  top: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=bottom] > .popover-arrow:before, .bs-popover-bottom > .popover-arrow:before {
  top: 0;
  border-width: 0 .5rem .5rem;
  border-bottom-color: #12263f
}

.bs-popover-auto[x-placement^=bottom] > .popover-arrow:after, .bs-popover-bottom > .popover-arrow:after {
  top: 1px;
  border-width: 0 .5rem .5rem;
  border-bottom-color: #152e4d
}

.bs-popover-auto[x-placement^=bottom] .popover-header:before, .bs-popover-bottom .popover-header:before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -.5rem;
  content: "";
  border-bottom: 1px solid #152e4d
}

.bs-popover-auto[x-placement^=left], .bs-popover-left {
  margin-right: .5rem
}

.bs-popover-auto[x-placement^=left] > .popover-arrow, .bs-popover-left > .popover-arrow {
  right: calc(-.5rem - 1px);
  width: .5rem;
  height: 1rem;
  margin: .5rem 0
}

.bs-popover-auto[x-placement^=left] > .popover-arrow:before, .bs-popover-left > .popover-arrow:before {
  right: 0;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #12263f
}

.bs-popover-auto[x-placement^=left] > .popover-arrow:after, .bs-popover-left > .popover-arrow:after {
  right: 1px;
  border-width: .5rem 0 .5rem .5rem;
  border-left-color: #152e4d
}

.popover-header {
  padding: 0;
  margin-bottom: 0;
  font-size: .9375rem;
  background-color: #152e4d;
  border-bottom: 1px solid #102239;
  border-top-left-radius: calc(.5rem - 1px);
  border-top-right-radius: calc(.5rem - 1px)
}

.popover-header:empty {
  display: none
}

.popover-body {
  padding: 0;
  color: #6e84a3
}

.carousel {
  position: relative
}

.carousel.pointer-event {
  touch-action: pan-y
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden
}

.carousel-inner:after {
  display: block;
  clear: both;
  content: ""
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none
  }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
  display: block
}

.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
  transform: translateX(100%)
}

.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
  transform: translateX(-100%)
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
  z-index: 1;
  opacity: 1
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s .6s
}

@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    transition: none
  }
}

.carousel-control-next, .carousel-control-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: .5;
  transition: opacity .15s ease
}

@media (prefers-reduced-motion: reduce) {
  .carousel-control-next, .carousel-control-prev {
    transition: none
  }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: .9
}

.carousel-control-prev {
  left: 0
}

.carousel-control-next {
  right: 0
}

.carousel-control-next-icon, .carousel-control-prev-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E")
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E")
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none
}

.carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: .5;
  transition: opacity .6s ease
}

@media (prefers-reduced-motion: reduce) {
  .carousel-indicators li {
    transition: none
  }
}

.carousel-indicators .active {
  opacity: 1
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center
}

@-webkit-keyframes spinner-border {
  to {
    transform: rotate(1turn)
  }
}

@keyframes spinner-border {
  to {
    transform: rotate(1turn)
  }
}

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: .25em solid;
  border-right: .25em solid transparent;
  border-radius: 50%;
  -webkit-animation: spinner-border .75s linear infinite;
  animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: .2em
}

@-webkit-keyframes spinner-grow {
  0% {
    transform: scale(0)
  }
  50% {
    opacity: 1;
    transform: none
  }
}

@keyframes spinner-grow {
  0% {
    transform: scale(0)
  }
  50% {
    opacity: 1;
    transform: none
  }
}

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow .75s linear infinite;
  animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem
}

.clearfix:after {
  display: block;
  clear: both;
  content: ""
}

.link-primary {
  color: #2c7be5
}

.link-primary:focus, .link-primary:hover {
  color: #1657af
}

.link-secondary {
  color: #6e84a3
}

.link-secondary:focus, .link-secondary:hover {
  color: #4c5f78
}

.link-success {
  color: #00d97e
}

.link-success:focus, .link-success:hover {
  color: #008d52
}

.link-info {
  color: #39afd1
}

.link-info:focus, .link-info:hover {
  color: #247f9a
}

.link-warning {
  color: #f6c343
}

.link-warning:focus, .link-warning:hover {
  color: #e2a40b
}

.link-danger {
  color: #e63757
}

.link-danger:focus, .link-danger:hover {
  color: #b91735
}

.link-light {
  color: #152e4d
}

.link-light:focus, .link-light:hover {
  color: #050a11
}

.link-dark {
  color: #12263f
}

.link-dark:focus, .link-dark:hover {
  color: #010204
}

.link-white {
  color: #fff
}

.link-white:focus, .link-white:hover {
  color: #d9d9d9
}

.embed-responsive {
  position: relative;
  width: 100%
}

.embed-responsive:before {
  display: block;
  content: ""
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.embed-responsive-21by9:before {
  padding-top: 42.85714%
}

.embed-responsive-16by9:before {
  padding-top: 56.25%
}

.embed-responsive-4by3:before {
  padding-top: 75%
}

.embed-responsive-1by1:before {
  padding-top: 100%
}

.fixed-top {
  top: 0
}

.fixed-bottom, .fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030
}

.fixed-bottom {
  bottom: 0
}

.sticky-top {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1020
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width: 768px) {
  .sticky-md-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width: 992px) {
  .sticky-lg-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width: 1200px) {
  .sticky-xl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020
  }
}

.sr-only, .sr-only-focusable:not(:focus) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important
}

.stretched-link:after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: ""
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.align-baseline {
  vertical-align: baseline !important
}

.align-top {
  vertical-align: top !important
}

.align-middle {
  vertical-align: middle !important
}

.align-bottom {
  vertical-align: bottom !important
}

.align-text-bottom {
  vertical-align: text-bottom !important
}

.align-text-top {
  vertical-align: text-top !important
}

.float-left {
  float: left !important
}

.float-right {
  float: right !important
}

.float-none {
  float: none !important
}

.overflow-auto {
  overflow: auto !important
}

.overflow-hidden {
  overflow: hidden !important
}

.d-none {
  display: none !important
}

.d-inline {
  display: inline !important
}

.d-inline-block {
  display: inline-block !important
}

.d-block {
  display: block !important
}

.d-table {
  display: table !important
}

.d-table-row {
  display: table-row !important
}

.d-table-cell {
  display: table-cell !important
}

.d-flex {
  display: flex !important
}

.d-inline-flex {
  display: inline-flex !important
}

.shadow {
  box-shadow: 0 .5rem 1rem rgba(18, 38, 63, .15) !important
}

.shadow-sm {
  box-shadow: 0 .125rem .25rem rgba(18, 38, 63, .075) !important
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(18, 38, 63, .175) !important
}

.shadow-none {
  box-shadow: none !important
}

.position-static {
  position: static !important
}

.position-relative {
  position: relative !important
}

.position-absolute {
  position: absolute !important
}

.position-fixed {
  position: fixed !important
}

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important
}

.border {
  border: 1px solid #1e3a5c !important
}

.border-0 {
  border: 0 !important
}

.border-top {
  border-top: 1px solid #1e3a5c !important
}

.border-top-0 {
  border-top: 0 !important
}

.border-right {
  border-right: 1px solid #1e3a5c !important
}

.border-right-0 {
  border-right: 0 !important
}

.border-bottom {
  border-bottom: 1px solid #1e3a5c !important
}

.border-bottom-0 {
  border-bottom: 0 !important
}

.border-left {
  border-left: 1px solid #1e3a5c !important
}

.border-left-0 {
  border-left: 0 !important
}

.border-primary {
  border-color: #2c7be5 !important
}

.border-secondary {
  border-color: #6e84a3 !important
}

.border-success {
  border-color: #00d97e !important
}

.border-info {
  border-color: #39afd1 !important
}

.border-warning {
  border-color: #f6c343 !important
}

.border-danger {
  border-color: #e63757 !important
}

.border-light {
  border-color: #152e4d !important
}

.border-dark {
  border-color: #12263f !important
}

.border-white {
  border-color: #fff !important
}

.border-body {
  border-color: #12263f !important
}

.border-card {
  border-color: #152e4d !important
}

.w-25 {
  width: 25% !important
}

.w-50 {
  width: 50% !important
}

.w-75 {
  width: 75% !important
}

.w-100 {
  width: 100% !important
}

.w-auto {
  width: auto !important
}

.mw-100 {
  max-width: 100% !important
}

.vw-100 {
  width: 100vw !important
}

.min-vw-100 {
  min-width: 100vw !important
}

.h-25 {
  height: 25% !important
}

.h-50 {
  height: 50% !important
}

.h-75 {
  height: 75% !important
}

.h-100 {
  height: 100% !important
}

.h-auto {
  height: auto !important
}

.mh-100 {
  max-height: 100% !important
}

.vh-100 {
  height: 100vh !important
}

.min-vh-100 {
  min-height: 100vh !important
}

.flex-fill {
  flex: 1 1 auto !important
}

.flex-row {
  flex-direction: row !important
}

.flex-column {
  flex-direction: column !important
}

.flex-row-reverse {
  flex-direction: row-reverse !important
}

.flex-column-reverse {
  flex-direction: column-reverse !important
}

.flex-grow-0 {
  flex-grow: 0 !important
}

.flex-grow-1 {
  flex-grow: 1 !important
}

.flex-shrink-0 {
  flex-shrink: 0 !important
}

.flex-shrink-1 {
  flex-shrink: 1 !important
}

.flex-wrap {
  flex-wrap: wrap !important
}

.flex-nowrap {
  flex-wrap: nowrap !important
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important
}

.justify-content-start {
  justify-content: flex-start !important
}

.justify-content-end {
  justify-content: flex-end !important
}

.justify-content-center {
  justify-content: center !important
}

.justify-content-between {
  justify-content: space-between !important
}

.justify-content-around {
  justify-content: space-around !important
}

.justify-content-evenly {
  justify-content: space-evenly !important
}

.align-items-start {
  align-items: flex-start !important
}

.align-items-end {
  align-items: flex-end !important
}

.align-items-center {
  align-items: center !important
}

.align-items-baseline {
  align-items: baseline !important
}

.align-items-stretch {
  align-items: stretch !important
}

.align-content-start {
  align-content: flex-start !important
}

.align-content-end {
  align-content: flex-end !important
}

.align-content-center {
  align-content: center !important
}

.align-content-between {
  align-content: space-between !important
}

.align-content-around {
  align-content: space-around !important
}

.align-content-stretch {
  align-content: stretch !important
}

.align-self-auto {
  align-self: auto !important
}

.align-self-start {
  align-self: flex-start !important
}

.align-self-end {
  align-self: flex-end !important
}

.align-self-center {
  align-self: center !important
}

.align-self-baseline {
  align-self: baseline !important
}

.align-self-stretch {
  align-self: stretch !important
}

.order-first {
  order: -1 !important
}

.order-0 {
  order: 0 !important
}

.order-1 {
  order: 1 !important
}

.order-2 {
  order: 2 !important
}

.order-3 {
  order: 3 !important
}

.order-4 {
  order: 4 !important
}

.order-5 {
  order: 5 !important
}

.order-last {
  order: 6 !important
}

.m-0 {
  margin: 0 !important
}

.m-1 {
  margin: .1875rem !important
}

.m-2 {
  margin: .375rem !important
}

.m-3 {
  margin: .75rem !important
}

.m-4 {
  margin: 1.5rem !important
}

.m-5 {
  margin: 2.25rem !important
}

.m-6 {
  margin: 4.5rem !important
}

.m-7 {
  margin: 6.75rem !important
}

.m-8 {
  margin: 13.5rem !important
}

.m-auto {
  margin: auto !important
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important
}

.mx-1 {
  margin-right: .1875rem !important;
  margin-left: .1875rem !important
}

.mx-2 {
  margin-right: .375rem !important;
  margin-left: .375rem !important
}

.mx-3 {
  margin-right: .75rem !important;
  margin-left: .75rem !important
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important
}

.mx-5 {
  margin-right: 2.25rem !important;
  margin-left: 2.25rem !important
}

.mx-6 {
  margin-right: 4.5rem !important;
  margin-left: 4.5rem !important
}

.mx-7 {
  margin-right: 6.75rem !important;
  margin-left: 6.75rem !important
}

.mx-8 {
  margin-right: 13.5rem !important;
  margin-left: 13.5rem !important
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important
}

.my-1 {
  margin-top: .1875rem !important;
  margin-bottom: .1875rem !important
}

.my-2 {
  margin-top: .375rem !important;
  margin-bottom: .375rem !important
}

.my-3 {
  margin-top: .75rem !important;
  margin-bottom: .75rem !important
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important
}

.my-5 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important
}

.my-6 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important
}

.my-7 {
  margin-top: 6.75rem !important;
  margin-bottom: 6.75rem !important
}

.my-8 {
  margin-top: 13.5rem !important;
  margin-bottom: 13.5rem !important
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important
}

.mt-0 {
  margin-top: 0 !important
}

.mt-1 {
  margin-top: .1875rem !important
}

.mt-2 {
  margin-top: .375rem !important
}

.mt-3 {
  margin-top: .75rem !important
}

.mt-4 {
  margin-top: 1.5rem !important
}

.mt-5 {
  margin-top: 2.25rem !important
}

.mt-6 {
  margin-top: 4.5rem !important
}

.mt-7 {
  margin-top: 6.75rem !important
}

.mt-8 {
  margin-top: 13.5rem !important
}

.mt-auto {
  margin-top: auto !important
}

.mr-0 {
  margin-right: 0 !important
}

.mr-1 {
  margin-right: .1875rem !important
}

.mr-2 {
  margin-right: .375rem !important
}

.mr-3 {
  margin-right: .75rem !important
}

.mr-4 {
  margin-right: 1.5rem !important
}

.mr-5 {
  margin-right: 2.25rem !important
}

.mr-6 {
  margin-right: 4.5rem !important
}

.mr-7 {
  margin-right: 6.75rem !important
}

.mr-8 {
  margin-right: 13.5rem !important
}

.mr-auto {
  margin-right: auto !important
}

.mb-0 {
  margin-bottom: 0 !important
}

.mb-1 {
  margin-bottom: .1875rem !important
}

.mb-2 {
  margin-bottom: .375rem !important
}

.mb-3 {
  margin-bottom: .75rem !important
}

.mb-4 {
  margin-bottom: 1.5rem !important
}

.mb-5 {
  margin-bottom: 2.25rem !important
}

.mb-6 {
  margin-bottom: 4.5rem !important
}

.mb-7 {
  margin-bottom: 6.75rem !important
}

.mb-8 {
  margin-bottom: 13.5rem !important
}

.mb-auto {
  margin-bottom: auto !important
}

.ml-0 {
  margin-left: 0 !important
}

.ml-1 {
  margin-left: .1875rem !important
}

.ml-2 {
  margin-left: .375rem !important
}

.ml-3 {
  margin-left: .75rem !important
}

.ml-4 {
  margin-left: 1.5rem !important
}

.ml-5 {
  margin-left: 2.25rem !important
}

.ml-6 {
  margin-left: 4.5rem !important
}

.ml-7 {
  margin-left: 6.75rem !important
}

.ml-8 {
  margin-left: 13.5rem !important
}

.ml-auto {
  margin-left: auto !important
}

.m-n1 {
  margin: -.1875rem !important
}

.m-n2 {
  margin: -.375rem !important
}

.m-n3 {
  margin: -.75rem !important
}

.m-n4 {
  margin: -1.5rem !important
}

.m-n5 {
  margin: -2.25rem !important
}

.m-n6 {
  margin: -4.5rem !important
}

.m-n7 {
  margin: -6.75rem !important
}

.m-n8 {
  margin: -13.5rem !important
}

.mx-n1 {
  margin-right: -.1875rem !important;
  margin-left: -.1875rem !important
}

.mx-n2 {
  margin-right: -.375rem !important;
  margin-left: -.375rem !important
}

.mx-n3 {
  margin-right: -.75rem !important;
  margin-left: -.75rem !important
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important
}

.mx-n5 {
  margin-right: -2.25rem !important;
  margin-left: -2.25rem !important
}

.mx-n6 {
  margin-right: -4.5rem !important;
  margin-left: -4.5rem !important
}

.mx-n7 {
  margin-right: -6.75rem !important;
  margin-left: -6.75rem !important
}

.mx-n8 {
  margin-right: -13.5rem !important;
  margin-left: -13.5rem !important
}

.my-n1 {
  margin-top: -.1875rem !important;
  margin-bottom: -.1875rem !important
}

.my-n2 {
  margin-top: -.375rem !important;
  margin-bottom: -.375rem !important
}

.my-n3 {
  margin-top: -.75rem !important;
  margin-bottom: -.75rem !important
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important
}

.my-n5 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important
}

.my-n6 {
  margin-top: -4.5rem !important;
  margin-bottom: -4.5rem !important
}

.my-n7 {
  margin-top: -6.75rem !important;
  margin-bottom: -6.75rem !important
}

.my-n8 {
  margin-top: -13.5rem !important;
  margin-bottom: -13.5rem !important
}

.mt-n1 {
  margin-top: -.1875rem !important
}

.mt-n2 {
  margin-top: -.375rem !important
}

.mt-n3 {
  margin-top: -.75rem !important
}

.mt-n4 {
  margin-top: -1.5rem !important
}

.mt-n5 {
  margin-top: -2.25rem !important
}

.mt-n6 {
  margin-top: -4.5rem !important
}

.mt-n7 {
  margin-top: -6.75rem !important
}

.mt-n8 {
  margin-top: -13.5rem !important
}

.mr-n1 {
  margin-right: -.1875rem !important
}

.mr-n2 {
  margin-right: -.375rem !important
}

.mr-n3 {
  margin-right: -.75rem !important
}

.mr-n4 {
  margin-right: -1.5rem !important
}

.mr-n5 {
  margin-right: -2.25rem !important
}

.mr-n6 {
  margin-right: -4.5rem !important
}

.mr-n7 {
  margin-right: -6.75rem !important
}

.mr-n8 {
  margin-right: -13.5rem !important
}

.mb-n1 {
  margin-bottom: -.1875rem !important
}

.mb-n2 {
  margin-bottom: -.375rem !important
}

.mb-n3 {
  margin-bottom: -.75rem !important
}

.mb-n4 {
  margin-bottom: -1.5rem !important
}

.mb-n5 {
  margin-bottom: -2.25rem !important
}

.mb-n6 {
  margin-bottom: -4.5rem !important
}

.mb-n7 {
  margin-bottom: -6.75rem !important
}

.mb-n8 {
  margin-bottom: -13.5rem !important
}

.ml-n1 {
  margin-left: -.1875rem !important
}

.ml-n2 {
  margin-left: -.375rem !important
}

.ml-n3 {
  margin-left: -.75rem !important
}

.ml-n4 {
  margin-left: -1.5rem !important
}

.ml-n5 {
  margin-left: -2.25rem !important
}

.ml-n6 {
  margin-left: -4.5rem !important
}

.ml-n7 {
  margin-left: -6.75rem !important
}

.ml-n8 {
  margin-left: -13.5rem !important
}

.p-0 {
  padding: 0 !important
}

.p-1 {
  padding: .1875rem !important
}

.p-2 {
  padding: .375rem !important
}

.p-3 {
  padding: .75rem !important
}

.p-4 {
  padding: 1.5rem !important
}

.p-5 {
  padding: 2.25rem !important
}

.p-6 {
  padding: 4.5rem !important
}

.p-7 {
  padding: 6.75rem !important
}

.p-8 {
  padding: 13.5rem !important
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important
}

.px-1 {
  padding-right: .1875rem !important;
  padding-left: .1875rem !important
}

.px-2 {
  padding-right: .375rem !important;
  padding-left: .375rem !important
}

.px-3 {
  padding-right: .75rem !important;
  padding-left: .75rem !important
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important
}

.px-5 {
  padding-right: 2.25rem !important;
  padding-left: 2.25rem !important
}

.px-6 {
  padding-right: 4.5rem !important;
  padding-left: 4.5rem !important
}

.px-7 {
  padding-right: 6.75rem !important;
  padding-left: 6.75rem !important
}

.px-8 {
  padding-right: 13.5rem !important;
  padding-left: 13.5rem !important
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important
}

.py-1 {
  padding-top: .1875rem !important;
  padding-bottom: .1875rem !important
}

.py-2 {
  padding-top: .375rem !important;
  padding-bottom: .375rem !important
}

.py-3 {
  padding-top: .75rem !important;
  padding-bottom: .75rem !important
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important
}

.py-5 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important
}

.py-6 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important
}

.py-7 {
  padding-top: 6.75rem !important;
  padding-bottom: 6.75rem !important
}

.py-8 {
  padding-top: 13.5rem !important;
  padding-bottom: 13.5rem !important
}

.pt-0 {
  padding-top: 0 !important
}

.pt-1 {
  padding-top: .1875rem !important
}

.pt-2 {
  padding-top: .375rem !important
}

.pt-3 {
  padding-top: .75rem !important
}

.pt-4 {
  padding-top: 1.5rem !important
}

.pt-5 {
  padding-top: 2.25rem !important
}

.pt-6 {
  padding-top: 4.5rem !important
}

.pt-7 {
  padding-top: 6.75rem !important
}

.pt-8 {
  padding-top: 13.5rem !important
}

.pr-0 {
  padding-right: 0 !important
}

.pr-1 {
  padding-right: .1875rem !important
}

.pr-2 {
  padding-right: .375rem !important
}

.pr-3 {
  padding-right: .75rem !important
}

.pr-4 {
  padding-right: 1.5rem !important
}

.pr-5 {
  padding-right: 2.25rem !important
}

.pr-6 {
  padding-right: 4.5rem !important
}

.pr-7 {
  padding-right: 6.75rem !important
}

.pr-8 {
  padding-right: 13.5rem !important
}

.pb-0 {
  padding-bottom: 0 !important
}

.pb-1 {
  padding-bottom: .1875rem !important
}

.pb-2 {
  padding-bottom: .375rem !important
}

.pb-3 {
  padding-bottom: .75rem !important
}

.pb-4 {
  padding-bottom: 1.5rem !important
}

.pb-5 {
  padding-bottom: 2.25rem !important
}

.pb-6 {
  padding-bottom: 4.5rem !important
}

.pb-7 {
  padding-bottom: 6.75rem !important
}

.pb-8 {
  padding-bottom: 13.5rem !important
}

.pl-0 {
  padding-left: 0 !important
}

.pl-1 {
  padding-left: .1875rem !important
}

.pl-2 {
  padding-left: .375rem !important
}

.pl-3 {
  padding-left: .75rem !important
}

.pl-4 {
  padding-left: 1.5rem !important
}

.pl-5 {
  padding-left: 2.25rem !important
}

.pl-6 {
  padding-left: 4.5rem !important
}

.pl-7 {
  padding-left: 6.75rem !important
}

.pl-8 {
  padding-left: 13.5rem !important
}

.font-weight-light {
  font-weight: 400 !important
}

.font-weight-lighter {
  font-weight: lighter !important
}

.font-weight-normal {
  font-weight: 400 !important
}

.font-weight-bold {
  font-weight: 600 !important
}

.font-weight-bolder {
  font-weight: bolder !important
}

.text-lowercase {
  text-transform: lowercase !important
}

.text-uppercase {
  text-transform: uppercase !important
}

.text-capitalize {
  text-transform: capitalize !important
}

.text-left {
  text-align: left !important
}

.text-right {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

.text-primary {
  color: #2c7be5 !important
}

.text-secondary {
  color: #6e84a3 !important
}

.text-success {
  color: #00d97e !important
}

.text-info {
  color: #39afd1 !important
}

.text-warning {
  color: #f6c343 !important
}

.text-danger {
  color: #e63757 !important
}

.text-light {
  color: #152e4d !important
}

.text-dark {
  color: #12263f !important
}

.text-body, .text-white {
  color: #fff !important
}

.text-muted {
  color: #6e84a3 !important
}

.text-black-50 {
  color: rgba(18, 38, 63, .5) !important
}

.text-white-50 {
  color: hsla(0, 0%, 100%, .5) !important
}

.text-reset {
  color: inherit !important
}

.lh-1 {
  line-height: 1 !important
}

.lh-sm {
  line-height: 1.75 !important
}

.lh-base, .lh-lg {
  line-height: 1.5 !important
}

.bg-primary {
  background-color: #2c7be5 !important
}

.bg-secondary {
  background-color: #6e84a3 !important
}

.bg-success {
  background-color: #00d97e !important
}

.bg-info {
  background-color: #39afd1 !important
}

.bg-warning {
  background-color: #f6c343 !important
}

.bg-danger {
  background-color: #e63757 !important
}

.bg-light {
  background-color: #152e4d !important
}

.bg-dark {
  background-color: #12263f !important
}

.bg-white {
  background-color: #fff !important
}

.bg-body {
  background-color: #12263f !important
}

.bg-transparent {
  background-color: transparent !important
}

.bg-auth {
  background-color: #12263f
}

.bg-lighter {
  background-color: #132a46 !important
}

.bg-gradient {
  background-image: var(--bs-gradient) !important
}

.text-wrap {
  white-space: normal !important
}

.text-nowrap {
  white-space: nowrap !important
}

.text-decoration-none {
  text-decoration: none !important
}

.text-decoration-underline {
  text-decoration: underline !important
}

.text-decoration-line-through {
  text-decoration: line-through !important
}

.font-italic {
  font-style: italic !important
}

.font-normal {
  font-style: normal !important
}

.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important
}

.user-select-all {
  -webkit-user-select: all !important;
  -moz-user-select: all !important;
  -ms-user-select: all !important;
  user-select: all !important
}

.user-select-auto {
  -webkit-user-select: auto !important;
  -moz-user-select: auto !important;
  -ms-user-select: auto !important;
  user-select: auto !important
}

.user-select-none {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important
}

.pe-none {
  pointer-events: none !important
}

.pe-auto {
  pointer-events: auto !important
}

.rounded {
  border-radius: .375rem !important
}

.rounded-sm {
  border-radius: .25rem !important
}

.rounded-lg {
  border-radius: .5rem !important
}

.rounded-circle {
  border-radius: 50% !important
}

.rounded-pill {
  border-radius: 50rem !important
}

.rounded-0 {
  border-radius: 0 !important
}

.rounded-top {
  border-top-left-radius: .375rem !important
}

.rounded-right, .rounded-top {
  border-top-right-radius: .375rem !important
}

.rounded-bottom, .rounded-right {
  border-bottom-right-radius: .375rem !important
}

.rounded-bottom, .rounded-left {
  border-bottom-left-radius: .375rem !important
}

.rounded-left {
  border-top-left-radius: .375rem !important
}

.visible {
  visibility: visible !important
}

.invisible {
  visibility: hidden !important
}

.border-2 {
  border-width: 2px !important
}

.border-3 {
  border-width: 3px !important
}

.border-4 {
  border-width: 4px !important
}

.border-5 {
  border-width: 5px !important
}

.border-top-2 {
  border-top-width: 2px !important
}

.border-top-3 {
  border-top-width: 3px !important
}

.border-top-4 {
  border-top-width: 4px !important
}

.border-top-5 {
  border-top-width: 5px !important
}

.border-right-2 {
  border-right-width: 2px !important
}

.border-right-3 {
  border-right-width: 3px !important
}

.border-right-4 {
  border-right-width: 4px !important
}

.border-right-5 {
  border-right-width: 5px !important
}

.border-bottom-2 {
  border-bottom-width: 2px !important
}

.border-bottom-3 {
  border-bottom-width: 3px !important
}

.border-bottom-4 {
  border-bottom-width: 4px !important
}

.border-bottom-5 {
  border-bottom-width: 5px !important
}

.border-left-2 {
  border-left-width: 2px !important
}

.border-left-3 {
  border-left-width: 3px !important
}

.border-left-4 {
  border-left-width: 4px !important
}

.border-left-5 {
  border-left-width: 5px !important
}

.font-size-base {
  font-size: .9375rem !important
}

.font-size-lg {
  font-size: 1.0625rem !important
}

.font-size-sm {
  font-size: .8125rem !important
}

.text-gray-100 {
  color: #f9fbfd !important
}

.text-gray-200 {
  color: #edf2f9 !important
}

.text-gray-300 {
  color: #e3ebf6 !important
}

.text-gray-400 {
  color: #d2ddec !important
}

.text-gray-500 {
  color: #b1c2d9 !important
}

.text-gray-600 {
  color: #95aac9 !important
}

.text-gray-700 {
  color: #6e84a3 !important
}

.text-gray-800 {
  color: #3b506c !important
}

.text-gray-900 {
  color: #283e59 !important
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important
  }
  .float-sm-right {
    float: right !important
  }
  .float-sm-none {
    float: none !important
  }
  .d-sm-none {
    display: none !important
  }
  .d-sm-inline {
    display: inline !important
  }
  .d-sm-inline-block {
    display: inline-block !important
  }
  .d-sm-block {
    display: block !important
  }
  .d-sm-table {
    display: table !important
  }
  .d-sm-table-row {
    display: table-row !important
  }
  .d-sm-table-cell {
    display: table-cell !important
  }
  .d-sm-flex {
    display: flex !important
  }
  .d-sm-inline-flex {
    display: inline-flex !important
  }
  .flex-sm-fill {
    flex: 1 1 auto !important
  }
  .flex-sm-row {
    flex-direction: row !important
  }
  .flex-sm-column {
    flex-direction: column !important
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }
  .justify-content-sm-start {
    justify-content: flex-start !important
  }
  .justify-content-sm-end {
    justify-content: flex-end !important
  }
  .justify-content-sm-center {
    justify-content: center !important
  }
  .justify-content-sm-between {
    justify-content: space-between !important
  }
  .justify-content-sm-around {
    justify-content: space-around !important
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important
  }
  .align-items-sm-start {
    align-items: flex-start !important
  }
  .align-items-sm-end {
    align-items: flex-end !important
  }
  .align-items-sm-center {
    align-items: center !important
  }
  .align-items-sm-baseline {
    align-items: baseline !important
  }
  .align-items-sm-stretch {
    align-items: stretch !important
  }
  .align-content-sm-start {
    align-content: flex-start !important
  }
  .align-content-sm-end {
    align-content: flex-end !important
  }
  .align-content-sm-center {
    align-content: center !important
  }
  .align-content-sm-between {
    align-content: space-between !important
  }
  .align-content-sm-around {
    align-content: space-around !important
  }
  .align-content-sm-stretch {
    align-content: stretch !important
  }
  .align-self-sm-auto {
    align-self: auto !important
  }
  .align-self-sm-start {
    align-self: flex-start !important
  }
  .align-self-sm-end {
    align-self: flex-end !important
  }
  .align-self-sm-center {
    align-self: center !important
  }
  .align-self-sm-baseline {
    align-self: baseline !important
  }
  .align-self-sm-stretch {
    align-self: stretch !important
  }
  .order-sm-first {
    order: -1 !important
  }
  .order-sm-0 {
    order: 0 !important
  }
  .order-sm-1 {
    order: 1 !important
  }
  .order-sm-2 {
    order: 2 !important
  }
  .order-sm-3 {
    order: 3 !important
  }
  .order-sm-4 {
    order: 4 !important
  }
  .order-sm-5 {
    order: 5 !important
  }
  .order-sm-last {
    order: 6 !important
  }
  .m-sm-0 {
    margin: 0 !important
  }
  .m-sm-1 {
    margin: .1875rem !important
  }
  .m-sm-2 {
    margin: .375rem !important
  }
  .m-sm-3 {
    margin: .75rem !important
  }
  .m-sm-4 {
    margin: 1.5rem !important
  }
  .m-sm-5 {
    margin: 2.25rem !important
  }
  .m-sm-6 {
    margin: 4.5rem !important
  }
  .m-sm-7 {
    margin: 6.75rem !important
  }
  .m-sm-8 {
    margin: 13.5rem !important
  }
  .m-sm-auto {
    margin: auto !important
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }
  .mx-sm-1 {
    margin-right: .1875rem !important;
    margin-left: .1875rem !important
  }
  .mx-sm-2 {
    margin-right: .375rem !important;
    margin-left: .375rem !important
  }
  .mx-sm-3 {
    margin-right: .75rem !important;
    margin-left: .75rem !important
  }
  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }
  .mx-sm-5 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important
  }
  .mx-sm-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important
  }
  .mx-sm-7 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important
  }
  .mx-sm-8 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }
  .my-sm-1 {
    margin-top: .1875rem !important;
    margin-bottom: .1875rem !important
  }
  .my-sm-2 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important
  }
  .my-sm-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important
  }
  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }
  .my-sm-5 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important
  }
  .my-sm-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important
  }
  .my-sm-7 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important
  }
  .my-sm-8 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }
  .mt-sm-0 {
    margin-top: 0 !important
  }
  .mt-sm-1 {
    margin-top: .1875rem !important
  }
  .mt-sm-2 {
    margin-top: .375rem !important
  }
  .mt-sm-3 {
    margin-top: .75rem !important
  }
  .mt-sm-4 {
    margin-top: 1.5rem !important
  }
  .mt-sm-5 {
    margin-top: 2.25rem !important
  }
  .mt-sm-6 {
    margin-top: 4.5rem !important
  }
  .mt-sm-7 {
    margin-top: 6.75rem !important
  }
  .mt-sm-8 {
    margin-top: 13.5rem !important
  }
  .mt-sm-auto {
    margin-top: auto !important
  }
  .mr-sm-0 {
    margin-right: 0 !important
  }
  .mr-sm-1 {
    margin-right: .1875rem !important
  }
  .mr-sm-2 {
    margin-right: .375rem !important
  }
  .mr-sm-3 {
    margin-right: .75rem !important
  }
  .mr-sm-4 {
    margin-right: 1.5rem !important
  }
  .mr-sm-5 {
    margin-right: 2.25rem !important
  }
  .mr-sm-6 {
    margin-right: 4.5rem !important
  }
  .mr-sm-7 {
    margin-right: 6.75rem !important
  }
  .mr-sm-8 {
    margin-right: 13.5rem !important
  }
  .mr-sm-auto {
    margin-right: auto !important
  }
  .mb-sm-0 {
    margin-bottom: 0 !important
  }
  .mb-sm-1 {
    margin-bottom: .1875rem !important
  }
  .mb-sm-2 {
    margin-bottom: .375rem !important
  }
  .mb-sm-3 {
    margin-bottom: .75rem !important
  }
  .mb-sm-4 {
    margin-bottom: 1.5rem !important
  }
  .mb-sm-5 {
    margin-bottom: 2.25rem !important
  }
  .mb-sm-6 {
    margin-bottom: 4.5rem !important
  }
  .mb-sm-7 {
    margin-bottom: 6.75rem !important
  }
  .mb-sm-8 {
    margin-bottom: 13.5rem !important
  }
  .mb-sm-auto {
    margin-bottom: auto !important
  }
  .ml-sm-0 {
    margin-left: 0 !important
  }
  .ml-sm-1 {
    margin-left: .1875rem !important
  }
  .ml-sm-2 {
    margin-left: .375rem !important
  }
  .ml-sm-3 {
    margin-left: .75rem !important
  }
  .ml-sm-4 {
    margin-left: 1.5rem !important
  }
  .ml-sm-5 {
    margin-left: 2.25rem !important
  }
  .ml-sm-6 {
    margin-left: 4.5rem !important
  }
  .ml-sm-7 {
    margin-left: 6.75rem !important
  }
  .ml-sm-8 {
    margin-left: 13.5rem !important
  }
  .ml-sm-auto {
    margin-left: auto !important
  }
  .m-sm-n1 {
    margin: -.1875rem !important
  }
  .m-sm-n2 {
    margin: -.375rem !important
  }
  .m-sm-n3 {
    margin: -.75rem !important
  }
  .m-sm-n4 {
    margin: -1.5rem !important
  }
  .m-sm-n5 {
    margin: -2.25rem !important
  }
  .m-sm-n6 {
    margin: -4.5rem !important
  }
  .m-sm-n7 {
    margin: -6.75rem !important
  }
  .m-sm-n8 {
    margin: -13.5rem !important
  }
  .mx-sm-n1 {
    margin-right: -.1875rem !important;
    margin-left: -.1875rem !important
  }
  .mx-sm-n2 {
    margin-right: -.375rem !important;
    margin-left: -.375rem !important
  }
  .mx-sm-n3 {
    margin-right: -.75rem !important;
    margin-left: -.75rem !important
  }
  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important
  }
  .mx-sm-n5 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important
  }
  .mx-sm-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important
  }
  .mx-sm-n7 {
    margin-right: -6.75rem !important;
    margin-left: -6.75rem !important
  }
  .mx-sm-n8 {
    margin-right: -13.5rem !important;
    margin-left: -13.5rem !important
  }
  .my-sm-n1 {
    margin-top: -.1875rem !important;
    margin-bottom: -.1875rem !important
  }
  .my-sm-n2 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important
  }
  .my-sm-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important
  }
  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important
  }
  .my-sm-n5 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important
  }
  .my-sm-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important
  }
  .my-sm-n7 {
    margin-top: -6.75rem !important;
    margin-bottom: -6.75rem !important
  }
  .my-sm-n8 {
    margin-top: -13.5rem !important;
    margin-bottom: -13.5rem !important
  }
  .mt-sm-n1 {
    margin-top: -.1875rem !important
  }
  .mt-sm-n2 {
    margin-top: -.375rem !important
  }
  .mt-sm-n3 {
    margin-top: -.75rem !important
  }
  .mt-sm-n4 {
    margin-top: -1.5rem !important
  }
  .mt-sm-n5 {
    margin-top: -2.25rem !important
  }
  .mt-sm-n6 {
    margin-top: -4.5rem !important
  }
  .mt-sm-n7 {
    margin-top: -6.75rem !important
  }
  .mt-sm-n8 {
    margin-top: -13.5rem !important
  }
  .mr-sm-n1 {
    margin-right: -.1875rem !important
  }
  .mr-sm-n2 {
    margin-right: -.375rem !important
  }
  .mr-sm-n3 {
    margin-right: -.75rem !important
  }
  .mr-sm-n4 {
    margin-right: -1.5rem !important
  }
  .mr-sm-n5 {
    margin-right: -2.25rem !important
  }
  .mr-sm-n6 {
    margin-right: -4.5rem !important
  }
  .mr-sm-n7 {
    margin-right: -6.75rem !important
  }
  .mr-sm-n8 {
    margin-right: -13.5rem !important
  }
  .mb-sm-n1 {
    margin-bottom: -.1875rem !important
  }
  .mb-sm-n2 {
    margin-bottom: -.375rem !important
  }
  .mb-sm-n3 {
    margin-bottom: -.75rem !important
  }
  .mb-sm-n4 {
    margin-bottom: -1.5rem !important
  }
  .mb-sm-n5 {
    margin-bottom: -2.25rem !important
  }
  .mb-sm-n6 {
    margin-bottom: -4.5rem !important
  }
  .mb-sm-n7 {
    margin-bottom: -6.75rem !important
  }
  .mb-sm-n8 {
    margin-bottom: -13.5rem !important
  }
  .ml-sm-n1 {
    margin-left: -.1875rem !important
  }
  .ml-sm-n2 {
    margin-left: -.375rem !important
  }
  .ml-sm-n3 {
    margin-left: -.75rem !important
  }
  .ml-sm-n4 {
    margin-left: -1.5rem !important
  }
  .ml-sm-n5 {
    margin-left: -2.25rem !important
  }
  .ml-sm-n6 {
    margin-left: -4.5rem !important
  }
  .ml-sm-n7 {
    margin-left: -6.75rem !important
  }
  .ml-sm-n8 {
    margin-left: -13.5rem !important
  }
  .p-sm-0 {
    padding: 0 !important
  }
  .p-sm-1 {
    padding: .1875rem !important
  }
  .p-sm-2 {
    padding: .375rem !important
  }
  .p-sm-3 {
    padding: .75rem !important
  }
  .p-sm-4 {
    padding: 1.5rem !important
  }
  .p-sm-5 {
    padding: 2.25rem !important
  }
  .p-sm-6 {
    padding: 4.5rem !important
  }
  .p-sm-7 {
    padding: 6.75rem !important
  }
  .p-sm-8 {
    padding: 13.5rem !important
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }
  .px-sm-1 {
    padding-right: .1875rem !important;
    padding-left: .1875rem !important
  }
  .px-sm-2 {
    padding-right: .375rem !important;
    padding-left: .375rem !important
  }
  .px-sm-3 {
    padding-right: .75rem !important;
    padding-left: .75rem !important
  }
  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }
  .px-sm-5 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important
  }
  .px-sm-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important
  }
  .px-sm-7 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important
  }
  .px-sm-8 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }
  .py-sm-1 {
    padding-top: .1875rem !important;
    padding-bottom: .1875rem !important
  }
  .py-sm-2 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important
  }
  .py-sm-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important
  }
  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }
  .py-sm-5 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important
  }
  .py-sm-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important
  }
  .py-sm-7 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important
  }
  .py-sm-8 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important
  }
  .pt-sm-0 {
    padding-top: 0 !important
  }
  .pt-sm-1 {
    padding-top: .1875rem !important
  }
  .pt-sm-2 {
    padding-top: .375rem !important
  }
  .pt-sm-3 {
    padding-top: .75rem !important
  }
  .pt-sm-4 {
    padding-top: 1.5rem !important
  }
  .pt-sm-5 {
    padding-top: 2.25rem !important
  }
  .pt-sm-6 {
    padding-top: 4.5rem !important
  }
  .pt-sm-7 {
    padding-top: 6.75rem !important
  }
  .pt-sm-8 {
    padding-top: 13.5rem !important
  }
  .pr-sm-0 {
    padding-right: 0 !important
  }
  .pr-sm-1 {
    padding-right: .1875rem !important
  }
  .pr-sm-2 {
    padding-right: .375rem !important
  }
  .pr-sm-3 {
    padding-right: .75rem !important
  }
  .pr-sm-4 {
    padding-right: 1.5rem !important
  }
  .pr-sm-5 {
    padding-right: 2.25rem !important
  }
  .pr-sm-6 {
    padding-right: 4.5rem !important
  }
  .pr-sm-7 {
    padding-right: 6.75rem !important
  }
  .pr-sm-8 {
    padding-right: 13.5rem !important
  }
  .pb-sm-0 {
    padding-bottom: 0 !important
  }
  .pb-sm-1 {
    padding-bottom: .1875rem !important
  }
  .pb-sm-2 {
    padding-bottom: .375rem !important
  }
  .pb-sm-3 {
    padding-bottom: .75rem !important
  }
  .pb-sm-4 {
    padding-bottom: 1.5rem !important
  }
  .pb-sm-5 {
    padding-bottom: 2.25rem !important
  }
  .pb-sm-6 {
    padding-bottom: 4.5rem !important
  }
  .pb-sm-7 {
    padding-bottom: 6.75rem !important
  }
  .pb-sm-8 {
    padding-bottom: 13.5rem !important
  }
  .pl-sm-0 {
    padding-left: 0 !important
  }
  .pl-sm-1 {
    padding-left: .1875rem !important
  }
  .pl-sm-2 {
    padding-left: .375rem !important
  }
  .pl-sm-3 {
    padding-left: .75rem !important
  }
  .pl-sm-4 {
    padding-left: 1.5rem !important
  }
  .pl-sm-5 {
    padding-left: 2.25rem !important
  }
  .pl-sm-6 {
    padding-left: 4.5rem !important
  }
  .pl-sm-7 {
    padding-left: 6.75rem !important
  }
  .pl-sm-8 {
    padding-left: 13.5rem !important
  }
  .text-sm-left {
    text-align: left !important
  }
  .text-sm-right {
    text-align: right !important
  }
  .text-sm-center {
    text-align: center !important
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important
  }
  .float-md-right {
    float: right !important
  }
  .float-md-none {
    float: none !important
  }
  .d-md-none {
    display: none !important
  }
  .d-md-inline {
    display: inline !important
  }
  .d-md-inline-block {
    display: inline-block !important
  }
  .d-md-block {
    display: block !important
  }
  .d-md-table {
    display: table !important
  }
  .d-md-table-row {
    display: table-row !important
  }
  .d-md-table-cell {
    display: table-cell !important
  }
  .d-md-flex {
    display: flex !important
  }
  .d-md-inline-flex {
    display: inline-flex !important
  }
  .flex-md-fill {
    flex: 1 1 auto !important
  }
  .flex-md-row {
    flex-direction: row !important
  }
  .flex-md-column {
    flex-direction: column !important
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important
  }
  .flex-md-wrap {
    flex-wrap: wrap !important
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }
  .justify-content-md-start {
    justify-content: flex-start !important
  }
  .justify-content-md-end {
    justify-content: flex-end !important
  }
  .justify-content-md-center {
    justify-content: center !important
  }
  .justify-content-md-between {
    justify-content: space-between !important
  }
  .justify-content-md-around {
    justify-content: space-around !important
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important
  }
  .align-items-md-start {
    align-items: flex-start !important
  }
  .align-items-md-end {
    align-items: flex-end !important
  }
  .align-items-md-center {
    align-items: center !important
  }
  .align-items-md-baseline {
    align-items: baseline !important
  }
  .align-items-md-stretch {
    align-items: stretch !important
  }
  .align-content-md-start {
    align-content: flex-start !important
  }
  .align-content-md-end {
    align-content: flex-end !important
  }
  .align-content-md-center {
    align-content: center !important
  }
  .align-content-md-between {
    align-content: space-between !important
  }
  .align-content-md-around {
    align-content: space-around !important
  }
  .align-content-md-stretch {
    align-content: stretch !important
  }
  .align-self-md-auto {
    align-self: auto !important
  }
  .align-self-md-start {
    align-self: flex-start !important
  }
  .align-self-md-end {
    align-self: flex-end !important
  }
  .align-self-md-center {
    align-self: center !important
  }
  .align-self-md-baseline {
    align-self: baseline !important
  }
  .align-self-md-stretch {
    align-self: stretch !important
  }
  .order-md-first {
    order: -1 !important
  }
  .order-md-0 {
    order: 0 !important
  }
  .order-md-1 {
    order: 1 !important
  }
  .order-md-2 {
    order: 2 !important
  }
  .order-md-3 {
    order: 3 !important
  }
  .order-md-4 {
    order: 4 !important
  }
  .order-md-5 {
    order: 5 !important
  }
  .order-md-last {
    order: 6 !important
  }
  .m-md-0 {
    margin: 0 !important
  }
  .m-md-1 {
    margin: .1875rem !important
  }
  .m-md-2 {
    margin: .375rem !important
  }
  .m-md-3 {
    margin: .75rem !important
  }
  .m-md-4 {
    margin: 1.5rem !important
  }
  .m-md-5 {
    margin: 2.25rem !important
  }
  .m-md-6 {
    margin: 4.5rem !important
  }
  .m-md-7 {
    margin: 6.75rem !important
  }
  .m-md-8 {
    margin: 13.5rem !important
  }
  .m-md-auto {
    margin: auto !important
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }
  .mx-md-1 {
    margin-right: .1875rem !important;
    margin-left: .1875rem !important
  }
  .mx-md-2 {
    margin-right: .375rem !important;
    margin-left: .375rem !important
  }
  .mx-md-3 {
    margin-right: .75rem !important;
    margin-left: .75rem !important
  }
  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }
  .mx-md-5 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important
  }
  .mx-md-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important
  }
  .mx-md-7 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important
  }
  .mx-md-8 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }
  .my-md-1 {
    margin-top: .1875rem !important;
    margin-bottom: .1875rem !important
  }
  .my-md-2 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important
  }
  .my-md-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important
  }
  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }
  .my-md-5 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important
  }
  .my-md-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important
  }
  .my-md-7 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important
  }
  .my-md-8 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }
  .mt-md-0 {
    margin-top: 0 !important
  }
  .mt-md-1 {
    margin-top: .1875rem !important
  }
  .mt-md-2 {
    margin-top: .375rem !important
  }
  .mt-md-3 {
    margin-top: .75rem !important
  }
  .mt-md-4 {
    margin-top: 1.5rem !important
  }
  .mt-md-5 {
    margin-top: 2.25rem !important
  }
  .mt-md-6 {
    margin-top: 4.5rem !important
  }
  .mt-md-7 {
    margin-top: 6.75rem !important
  }
  .mt-md-8 {
    margin-top: 13.5rem !important
  }
  .mt-md-auto {
    margin-top: auto !important
  }
  .mr-md-0 {
    margin-right: 0 !important
  }
  .mr-md-1 {
    margin-right: .1875rem !important
  }
  .mr-md-2 {
    margin-right: .375rem !important
  }
  .mr-md-3 {
    margin-right: .75rem !important
  }
  .mr-md-4 {
    margin-right: 1.5rem !important
  }
  .mr-md-5 {
    margin-right: 2.25rem !important
  }
  .mr-md-6 {
    margin-right: 4.5rem !important
  }
  .mr-md-7 {
    margin-right: 6.75rem !important
  }
  .mr-md-8 {
    margin-right: 13.5rem !important
  }
  .mr-md-auto {
    margin-right: auto !important
  }
  .mb-md-0 {
    margin-bottom: 0 !important
  }
  .mb-md-1 {
    margin-bottom: .1875rem !important
  }
  .mb-md-2 {
    margin-bottom: .375rem !important
  }
  .mb-md-3 {
    margin-bottom: .75rem !important
  }
  .mb-md-4 {
    margin-bottom: 1.5rem !important
  }
  .mb-md-5 {
    margin-bottom: 2.25rem !important
  }
  .mb-md-6 {
    margin-bottom: 4.5rem !important
  }
  .mb-md-7 {
    margin-bottom: 6.75rem !important
  }
  .mb-md-8 {
    margin-bottom: 13.5rem !important
  }
  .mb-md-auto {
    margin-bottom: auto !important
  }
  .ml-md-0 {
    margin-left: 0 !important
  }
  .ml-md-1 {
    margin-left: .1875rem !important
  }
  .ml-md-2 {
    margin-left: .375rem !important
  }
  .ml-md-3 {
    margin-left: .75rem !important
  }
  .ml-md-4 {
    margin-left: 1.5rem !important
  }
  .ml-md-5 {
    margin-left: 2.25rem !important
  }
  .ml-md-6 {
    margin-left: 4.5rem !important
  }
  .ml-md-7 {
    margin-left: 6.75rem !important
  }
  .ml-md-8 {
    margin-left: 13.5rem !important
  }
  .ml-md-auto {
    margin-left: auto !important
  }
  .m-md-n1 {
    margin: -.1875rem !important
  }
  .m-md-n2 {
    margin: -.375rem !important
  }
  .m-md-n3 {
    margin: -.75rem !important
  }
  .m-md-n4 {
    margin: -1.5rem !important
  }
  .m-md-n5 {
    margin: -2.25rem !important
  }
  .m-md-n6 {
    margin: -4.5rem !important
  }
  .m-md-n7 {
    margin: -6.75rem !important
  }
  .m-md-n8 {
    margin: -13.5rem !important
  }
  .mx-md-n1 {
    margin-right: -.1875rem !important;
    margin-left: -.1875rem !important
  }
  .mx-md-n2 {
    margin-right: -.375rem !important;
    margin-left: -.375rem !important
  }
  .mx-md-n3 {
    margin-right: -.75rem !important;
    margin-left: -.75rem !important
  }
  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important
  }
  .mx-md-n5 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important
  }
  .mx-md-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important
  }
  .mx-md-n7 {
    margin-right: -6.75rem !important;
    margin-left: -6.75rem !important
  }
  .mx-md-n8 {
    margin-right: -13.5rem !important;
    margin-left: -13.5rem !important
  }
  .my-md-n1 {
    margin-top: -.1875rem !important;
    margin-bottom: -.1875rem !important
  }
  .my-md-n2 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important
  }
  .my-md-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important
  }
  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important
  }
  .my-md-n5 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important
  }
  .my-md-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important
  }
  .my-md-n7 {
    margin-top: -6.75rem !important;
    margin-bottom: -6.75rem !important
  }
  .my-md-n8 {
    margin-top: -13.5rem !important;
    margin-bottom: -13.5rem !important
  }
  .mt-md-n1 {
    margin-top: -.1875rem !important
  }
  .mt-md-n2 {
    margin-top: -.375rem !important
  }
  .mt-md-n3 {
    margin-top: -.75rem !important
  }
  .mt-md-n4 {
    margin-top: -1.5rem !important
  }
  .mt-md-n5 {
    margin-top: -2.25rem !important
  }
  .mt-md-n6 {
    margin-top: -4.5rem !important
  }
  .mt-md-n7 {
    margin-top: -6.75rem !important
  }
  .mt-md-n8 {
    margin-top: -13.5rem !important
  }
  .mr-md-n1 {
    margin-right: -.1875rem !important
  }
  .mr-md-n2 {
    margin-right: -.375rem !important
  }
  .mr-md-n3 {
    margin-right: -.75rem !important
  }
  .mr-md-n4 {
    margin-right: -1.5rem !important
  }
  .mr-md-n5 {
    margin-right: -2.25rem !important
  }
  .mr-md-n6 {
    margin-right: -4.5rem !important
  }
  .mr-md-n7 {
    margin-right: -6.75rem !important
  }
  .mr-md-n8 {
    margin-right: -13.5rem !important
  }
  .mb-md-n1 {
    margin-bottom: -.1875rem !important
  }
  .mb-md-n2 {
    margin-bottom: -.375rem !important
  }
  .mb-md-n3 {
    margin-bottom: -.75rem !important
  }
  .mb-md-n4 {
    margin-bottom: -1.5rem !important
  }
  .mb-md-n5 {
    margin-bottom: -2.25rem !important
  }
  .mb-md-n6 {
    margin-bottom: -4.5rem !important
  }
  .mb-md-n7 {
    margin-bottom: -6.75rem !important
  }
  .mb-md-n8 {
    margin-bottom: -13.5rem !important
  }
  .ml-md-n1 {
    margin-left: -.1875rem !important
  }
  .ml-md-n2 {
    margin-left: -.375rem !important
  }
  .ml-md-n3 {
    margin-left: -.75rem !important
  }
  .ml-md-n4 {
    margin-left: -1.5rem !important
  }
  .ml-md-n5 {
    margin-left: -2.25rem !important
  }
  .ml-md-n6 {
    margin-left: -4.5rem !important
  }
  .ml-md-n7 {
    margin-left: -6.75rem !important
  }
  .ml-md-n8 {
    margin-left: -13.5rem !important
  }
  .p-md-0 {
    padding: 0 !important
  }
  .p-md-1 {
    padding: .1875rem !important
  }
  .p-md-2 {
    padding: .375rem !important
  }
  .p-md-3 {
    padding: .75rem !important
  }
  .p-md-4 {
    padding: 1.5rem !important
  }
  .p-md-5 {
    padding: 2.25rem !important
  }
  .p-md-6 {
    padding: 4.5rem !important
  }
  .p-md-7 {
    padding: 6.75rem !important
  }
  .p-md-8 {
    padding: 13.5rem !important
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }
  .px-md-1 {
    padding-right: .1875rem !important;
    padding-left: .1875rem !important
  }
  .px-md-2 {
    padding-right: .375rem !important;
    padding-left: .375rem !important
  }
  .px-md-3 {
    padding-right: .75rem !important;
    padding-left: .75rem !important
  }
  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }
  .px-md-5 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important
  }
  .px-md-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important
  }
  .px-md-7 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important
  }
  .px-md-8 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }
  .py-md-1 {
    padding-top: .1875rem !important;
    padding-bottom: .1875rem !important
  }
  .py-md-2 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important
  }
  .py-md-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important
  }
  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }
  .py-md-5 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important
  }
  .py-md-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important
  }
  .py-md-7 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important
  }
  .py-md-8 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important
  }
  .pt-md-0 {
    padding-top: 0 !important
  }
  .pt-md-1 {
    padding-top: .1875rem !important
  }
  .pt-md-2 {
    padding-top: .375rem !important
  }
  .pt-md-3 {
    padding-top: .75rem !important
  }
  .pt-md-4 {
    padding-top: 1.5rem !important
  }
  .pt-md-5 {
    padding-top: 2.25rem !important
  }
  .pt-md-6 {
    padding-top: 4.5rem !important
  }
  .pt-md-7 {
    padding-top: 6.75rem !important
  }
  .pt-md-8 {
    padding-top: 13.5rem !important
  }
  .pr-md-0 {
    padding-right: 0 !important
  }
  .pr-md-1 {
    padding-right: .1875rem !important
  }
  .pr-md-2 {
    padding-right: .375rem !important
  }
  .pr-md-3 {
    padding-right: .75rem !important
  }
  .pr-md-4 {
    padding-right: 1.5rem !important
  }
  .pr-md-5 {
    padding-right: 2.25rem !important
  }
  .pr-md-6 {
    padding-right: 4.5rem !important
  }
  .pr-md-7 {
    padding-right: 6.75rem !important
  }
  .pr-md-8 {
    padding-right: 13.5rem !important
  }
  .pb-md-0 {
    padding-bottom: 0 !important
  }
  .pb-md-1 {
    padding-bottom: .1875rem !important
  }
  .pb-md-2 {
    padding-bottom: .375rem !important
  }
  .pb-md-3 {
    padding-bottom: .75rem !important
  }
  .pb-md-4 {
    padding-bottom: 1.5rem !important
  }
  .pb-md-5 {
    padding-bottom: 2.25rem !important
  }
  .pb-md-6 {
    padding-bottom: 4.5rem !important
  }
  .pb-md-7 {
    padding-bottom: 6.75rem !important
  }
  .pb-md-8 {
    padding-bottom: 13.5rem !important
  }
  .pl-md-0 {
    padding-left: 0 !important
  }
  .pl-md-1 {
    padding-left: .1875rem !important
  }
  .pl-md-2 {
    padding-left: .375rem !important
  }
  .pl-md-3 {
    padding-left: .75rem !important
  }
  .pl-md-4 {
    padding-left: 1.5rem !important
  }
  .pl-md-5 {
    padding-left: 2.25rem !important
  }
  .pl-md-6 {
    padding-left: 4.5rem !important
  }
  .pl-md-7 {
    padding-left: 6.75rem !important
  }
  .pl-md-8 {
    padding-left: 13.5rem !important
  }
  .text-md-left {
    text-align: left !important
  }
  .text-md-right {
    text-align: right !important
  }
  .text-md-center {
    text-align: center !important
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important
  }
  .float-lg-right {
    float: right !important
  }
  .float-lg-none {
    float: none !important
  }
  .d-lg-none {
    display: none !important
  }
  .d-lg-inline {
    display: inline !important
  }
  .d-lg-inline-block {
    display: inline-block !important
  }
  .d-lg-block {
    display: block !important
  }
  .d-lg-table {
    display: table !important
  }
  .d-lg-table-row {
    display: table-row !important
  }
  .d-lg-table-cell {
    display: table-cell !important
  }
  .d-lg-flex {
    display: flex !important
  }
  .d-lg-inline-flex {
    display: inline-flex !important
  }
  .flex-lg-fill {
    flex: 1 1 auto !important
  }
  .flex-lg-row {
    flex-direction: row !important
  }
  .flex-lg-column {
    flex-direction: column !important
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }
  .justify-content-lg-start {
    justify-content: flex-start !important
  }
  .justify-content-lg-end {
    justify-content: flex-end !important
  }
  .justify-content-lg-center {
    justify-content: center !important
  }
  .justify-content-lg-between {
    justify-content: space-between !important
  }
  .justify-content-lg-around {
    justify-content: space-around !important
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important
  }
  .align-items-lg-start {
    align-items: flex-start !important
  }
  .align-items-lg-end {
    align-items: flex-end !important
  }
  .align-items-lg-center {
    align-items: center !important
  }
  .align-items-lg-baseline {
    align-items: baseline !important
  }
  .align-items-lg-stretch {
    align-items: stretch !important
  }
  .align-content-lg-start {
    align-content: flex-start !important
  }
  .align-content-lg-end {
    align-content: flex-end !important
  }
  .align-content-lg-center {
    align-content: center !important
  }
  .align-content-lg-between {
    align-content: space-between !important
  }
  .align-content-lg-around {
    align-content: space-around !important
  }
  .align-content-lg-stretch {
    align-content: stretch !important
  }
  .align-self-lg-auto {
    align-self: auto !important
  }
  .align-self-lg-start {
    align-self: flex-start !important
  }
  .align-self-lg-end {
    align-self: flex-end !important
  }
  .align-self-lg-center {
    align-self: center !important
  }
  .align-self-lg-baseline {
    align-self: baseline !important
  }
  .align-self-lg-stretch {
    align-self: stretch !important
  }
  .order-lg-first {
    order: -1 !important
  }
  .order-lg-0 {
    order: 0 !important
  }
  .order-lg-1 {
    order: 1 !important
  }
  .order-lg-2 {
    order: 2 !important
  }
  .order-lg-3 {
    order: 3 !important
  }
  .order-lg-4 {
    order: 4 !important
  }
  .order-lg-5 {
    order: 5 !important
  }
  .order-lg-last {
    order: 6 !important
  }
  .m-lg-0 {
    margin: 0 !important
  }
  .m-lg-1 {
    margin: .1875rem !important
  }
  .m-lg-2 {
    margin: .375rem !important
  }
  .m-lg-3 {
    margin: .75rem !important
  }
  .m-lg-4 {
    margin: 1.5rem !important
  }
  .m-lg-5 {
    margin: 2.25rem !important
  }
  .m-lg-6 {
    margin: 4.5rem !important
  }
  .m-lg-7 {
    margin: 6.75rem !important
  }
  .m-lg-8 {
    margin: 13.5rem !important
  }
  .m-lg-auto {
    margin: auto !important
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }
  .mx-lg-1 {
    margin-right: .1875rem !important;
    margin-left: .1875rem !important
  }
  .mx-lg-2 {
    margin-right: .375rem !important;
    margin-left: .375rem !important
  }
  .mx-lg-3 {
    margin-right: .75rem !important;
    margin-left: .75rem !important
  }
  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }
  .mx-lg-5 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important
  }
  .mx-lg-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important
  }
  .mx-lg-7 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important
  }
  .mx-lg-8 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }
  .my-lg-1 {
    margin-top: .1875rem !important;
    margin-bottom: .1875rem !important
  }
  .my-lg-2 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important
  }
  .my-lg-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important
  }
  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }
  .my-lg-5 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important
  }
  .my-lg-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important
  }
  .my-lg-7 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important
  }
  .my-lg-8 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }
  .mt-lg-0 {
    margin-top: 0 !important
  }
  .mt-lg-1 {
    margin-top: .1875rem !important
  }
  .mt-lg-2 {
    margin-top: .375rem !important
  }
  .mt-lg-3 {
    margin-top: .75rem !important
  }
  .mt-lg-4 {
    margin-top: 1.5rem !important
  }
  .mt-lg-5 {
    margin-top: 2.25rem !important
  }
  .mt-lg-6 {
    margin-top: 4.5rem !important
  }
  .mt-lg-7 {
    margin-top: 6.75rem !important
  }
  .mt-lg-8 {
    margin-top: 13.5rem !important
  }
  .mt-lg-auto {
    margin-top: auto !important
  }
  .mr-lg-0 {
    margin-right: 0 !important
  }
  .mr-lg-1 {
    margin-right: .1875rem !important
  }
  .mr-lg-2 {
    margin-right: .375rem !important
  }
  .mr-lg-3 {
    margin-right: .75rem !important
  }
  .mr-lg-4 {
    margin-right: 1.5rem !important
  }
  .mr-lg-5 {
    margin-right: 2.25rem !important
  }
  .mr-lg-6 {
    margin-right: 4.5rem !important
  }
  .mr-lg-7 {
    margin-right: 6.75rem !important
  }
  .mr-lg-8 {
    margin-right: 13.5rem !important
  }
  .mr-lg-auto {
    margin-right: auto !important
  }
  .mb-lg-0 {
    margin-bottom: 0 !important
  }
  .mb-lg-1 {
    margin-bottom: .1875rem !important
  }
  .mb-lg-2 {
    margin-bottom: .375rem !important
  }
  .mb-lg-3 {
    margin-bottom: .75rem !important
  }
  .mb-lg-4 {
    margin-bottom: 1.5rem !important
  }
  .mb-lg-5 {
    margin-bottom: 2.25rem !important
  }
  .mb-lg-6 {
    margin-bottom: 4.5rem !important
  }
  .mb-lg-7 {
    margin-bottom: 6.75rem !important
  }
  .mb-lg-8 {
    margin-bottom: 13.5rem !important
  }
  .mb-lg-auto {
    margin-bottom: auto !important
  }
  .ml-lg-0 {
    margin-left: 0 !important
  }
  .ml-lg-1 {
    margin-left: .1875rem !important
  }
  .ml-lg-2 {
    margin-left: .375rem !important
  }
  .ml-lg-3 {
    margin-left: .75rem !important
  }
  .ml-lg-4 {
    margin-left: 1.5rem !important
  }
  .ml-lg-5 {
    margin-left: 2.25rem !important
  }
  .ml-lg-6 {
    margin-left: 4.5rem !important
  }
  .ml-lg-7 {
    margin-left: 6.75rem !important
  }
  .ml-lg-8 {
    margin-left: 13.5rem !important
  }
  .ml-lg-auto {
    margin-left: auto !important
  }
  .m-lg-n1 {
    margin: -.1875rem !important
  }
  .m-lg-n2 {
    margin: -.375rem !important
  }
  .m-lg-n3 {
    margin: -.75rem !important
  }
  .m-lg-n4 {
    margin: -1.5rem !important
  }
  .m-lg-n5 {
    margin: -2.25rem !important
  }
  .m-lg-n6 {
    margin: -4.5rem !important
  }
  .m-lg-n7 {
    margin: -6.75rem !important
  }
  .m-lg-n8 {
    margin: -13.5rem !important
  }
  .mx-lg-n1 {
    margin-right: -.1875rem !important;
    margin-left: -.1875rem !important
  }
  .mx-lg-n2 {
    margin-right: -.375rem !important;
    margin-left: -.375rem !important
  }
  .mx-lg-n3 {
    margin-right: -.75rem !important;
    margin-left: -.75rem !important
  }
  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important
  }
  .mx-lg-n5 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important
  }
  .mx-lg-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important
  }
  .mx-lg-n7 {
    margin-right: -6.75rem !important;
    margin-left: -6.75rem !important
  }
  .mx-lg-n8 {
    margin-right: -13.5rem !important;
    margin-left: -13.5rem !important
  }
  .my-lg-n1 {
    margin-top: -.1875rem !important;
    margin-bottom: -.1875rem !important
  }
  .my-lg-n2 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important
  }
  .my-lg-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important
  }
  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important
  }
  .my-lg-n5 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important
  }
  .my-lg-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important
  }
  .my-lg-n7 {
    margin-top: -6.75rem !important;
    margin-bottom: -6.75rem !important
  }
  .my-lg-n8 {
    margin-top: -13.5rem !important;
    margin-bottom: -13.5rem !important
  }
  .mt-lg-n1 {
    margin-top: -.1875rem !important
  }
  .mt-lg-n2 {
    margin-top: -.375rem !important
  }
  .mt-lg-n3 {
    margin-top: -.75rem !important
  }
  .mt-lg-n4 {
    margin-top: -1.5rem !important
  }
  .mt-lg-n5 {
    margin-top: -2.25rem !important
  }
  .mt-lg-n6 {
    margin-top: -4.5rem !important
  }
  .mt-lg-n7 {
    margin-top: -6.75rem !important
  }
  .mt-lg-n8 {
    margin-top: -13.5rem !important
  }
  .mr-lg-n1 {
    margin-right: -.1875rem !important
  }
  .mr-lg-n2 {
    margin-right: -.375rem !important
  }
  .mr-lg-n3 {
    margin-right: -.75rem !important
  }
  .mr-lg-n4 {
    margin-right: -1.5rem !important
  }
  .mr-lg-n5 {
    margin-right: -2.25rem !important
  }
  .mr-lg-n6 {
    margin-right: -4.5rem !important
  }
  .mr-lg-n7 {
    margin-right: -6.75rem !important
  }
  .mr-lg-n8 {
    margin-right: -13.5rem !important
  }
  .mb-lg-n1 {
    margin-bottom: -.1875rem !important
  }
  .mb-lg-n2 {
    margin-bottom: -.375rem !important
  }
  .mb-lg-n3 {
    margin-bottom: -.75rem !important
  }
  .mb-lg-n4 {
    margin-bottom: -1.5rem !important
  }
  .mb-lg-n5 {
    margin-bottom: -2.25rem !important
  }
  .mb-lg-n6 {
    margin-bottom: -4.5rem !important
  }
  .mb-lg-n7 {
    margin-bottom: -6.75rem !important
  }
  .mb-lg-n8 {
    margin-bottom: -13.5rem !important
  }
  .ml-lg-n1 {
    margin-left: -.1875rem !important
  }
  .ml-lg-n2 {
    margin-left: -.375rem !important
  }
  .ml-lg-n3 {
    margin-left: -.75rem !important
  }
  .ml-lg-n4 {
    margin-left: -1.5rem !important
  }
  .ml-lg-n5 {
    margin-left: -2.25rem !important
  }
  .ml-lg-n6 {
    margin-left: -4.5rem !important
  }
  .ml-lg-n7 {
    margin-left: -6.75rem !important
  }
  .ml-lg-n8 {
    margin-left: -13.5rem !important
  }
  .p-lg-0 {
    padding: 0 !important
  }
  .p-lg-1 {
    padding: .1875rem !important
  }
  .p-lg-2 {
    padding: .375rem !important
  }
  .p-lg-3 {
    padding: .75rem !important
  }
  .p-lg-4 {
    padding: 1.5rem !important
  }
  .p-lg-5 {
    padding: 2.25rem !important
  }
  .p-lg-6 {
    padding: 4.5rem !important
  }
  .p-lg-7 {
    padding: 6.75rem !important
  }
  .p-lg-8 {
    padding: 13.5rem !important
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }
  .px-lg-1 {
    padding-right: .1875rem !important;
    padding-left: .1875rem !important
  }
  .px-lg-2 {
    padding-right: .375rem !important;
    padding-left: .375rem !important
  }
  .px-lg-3 {
    padding-right: .75rem !important;
    padding-left: .75rem !important
  }
  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }
  .px-lg-5 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important
  }
  .px-lg-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important
  }
  .px-lg-7 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important
  }
  .px-lg-8 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }
  .py-lg-1 {
    padding-top: .1875rem !important;
    padding-bottom: .1875rem !important
  }
  .py-lg-2 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important
  }
  .py-lg-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important
  }
  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }
  .py-lg-5 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important
  }
  .py-lg-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important
  }
  .py-lg-7 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important
  }
  .py-lg-8 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important
  }
  .pt-lg-0 {
    padding-top: 0 !important
  }
  .pt-lg-1 {
    padding-top: .1875rem !important
  }
  .pt-lg-2 {
    padding-top: .375rem !important
  }
  .pt-lg-3 {
    padding-top: .75rem !important
  }
  .pt-lg-4 {
    padding-top: 1.5rem !important
  }
  .pt-lg-5 {
    padding-top: 2.25rem !important
  }
  .pt-lg-6 {
    padding-top: 4.5rem !important
  }
  .pt-lg-7 {
    padding-top: 6.75rem !important
  }
  .pt-lg-8 {
    padding-top: 13.5rem !important
  }
  .pr-lg-0 {
    padding-right: 0 !important
  }
  .pr-lg-1 {
    padding-right: .1875rem !important
  }
  .pr-lg-2 {
    padding-right: .375rem !important
  }
  .pr-lg-3 {
    padding-right: .75rem !important
  }
  .pr-lg-4 {
    padding-right: 1.5rem !important
  }
  .pr-lg-5 {
    padding-right: 2.25rem !important
  }
  .pr-lg-6 {
    padding-right: 4.5rem !important
  }
  .pr-lg-7 {
    padding-right: 6.75rem !important
  }
  .pr-lg-8 {
    padding-right: 13.5rem !important
  }
  .pb-lg-0 {
    padding-bottom: 0 !important
  }
  .pb-lg-1 {
    padding-bottom: .1875rem !important
  }
  .pb-lg-2 {
    padding-bottom: .375rem !important
  }
  .pb-lg-3 {
    padding-bottom: .75rem !important
  }
  .pb-lg-4 {
    padding-bottom: 1.5rem !important
  }
  .pb-lg-5 {
    padding-bottom: 2.25rem !important
  }
  .pb-lg-6 {
    padding-bottom: 4.5rem !important
  }
  .pb-lg-7 {
    padding-bottom: 6.75rem !important
  }
  .pb-lg-8 {
    padding-bottom: 13.5rem !important
  }
  .pl-lg-0 {
    padding-left: 0 !important
  }
  .pl-lg-1 {
    padding-left: .1875rem !important
  }
  .pl-lg-2 {
    padding-left: .375rem !important
  }
  .pl-lg-3 {
    padding-left: .75rem !important
  }
  .pl-lg-4 {
    padding-left: 1.5rem !important
  }
  .pl-lg-5 {
    padding-left: 2.25rem !important
  }
  .pl-lg-6 {
    padding-left: 4.5rem !important
  }
  .pl-lg-7 {
    padding-left: 6.75rem !important
  }
  .pl-lg-8 {
    padding-left: 13.5rem !important
  }
  .text-lg-left {
    text-align: left !important
  }
  .text-lg-right {
    text-align: right !important
  }
  .text-lg-center {
    text-align: center !important
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important
  }
  .float-xl-right {
    float: right !important
  }
  .float-xl-none {
    float: none !important
  }
  .d-xl-none {
    display: none !important
  }
  .d-xl-inline {
    display: inline !important
  }
  .d-xl-inline-block {
    display: inline-block !important
  }
  .d-xl-block {
    display: block !important
  }
  .d-xl-table {
    display: table !important
  }
  .d-xl-table-row {
    display: table-row !important
  }
  .d-xl-table-cell {
    display: table-cell !important
  }
  .d-xl-flex {
    display: flex !important
  }
  .d-xl-inline-flex {
    display: inline-flex !important
  }
  .flex-xl-fill {
    flex: 1 1 auto !important
  }
  .flex-xl-row {
    flex-direction: row !important
  }
  .flex-xl-column {
    flex-direction: column !important
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }
  .justify-content-xl-start {
    justify-content: flex-start !important
  }
  .justify-content-xl-end {
    justify-content: flex-end !important
  }
  .justify-content-xl-center {
    justify-content: center !important
  }
  .justify-content-xl-between {
    justify-content: space-between !important
  }
  .justify-content-xl-around {
    justify-content: space-around !important
  }
  .justify-content-xl-evenly {
    justify-content: space-evenly !important
  }
  .align-items-xl-start {
    align-items: flex-start !important
  }
  .align-items-xl-end {
    align-items: flex-end !important
  }
  .align-items-xl-center {
    align-items: center !important
  }
  .align-items-xl-baseline {
    align-items: baseline !important
  }
  .align-items-xl-stretch {
    align-items: stretch !important
  }
  .align-content-xl-start {
    align-content: flex-start !important
  }
  .align-content-xl-end {
    align-content: flex-end !important
  }
  .align-content-xl-center {
    align-content: center !important
  }
  .align-content-xl-between {
    align-content: space-between !important
  }
  .align-content-xl-around {
    align-content: space-around !important
  }
  .align-content-xl-stretch {
    align-content: stretch !important
  }
  .align-self-xl-auto {
    align-self: auto !important
  }
  .align-self-xl-start {
    align-self: flex-start !important
  }
  .align-self-xl-end {
    align-self: flex-end !important
  }
  .align-self-xl-center {
    align-self: center !important
  }
  .align-self-xl-baseline {
    align-self: baseline !important
  }
  .align-self-xl-stretch {
    align-self: stretch !important
  }
  .order-xl-first {
    order: -1 !important
  }
  .order-xl-0 {
    order: 0 !important
  }
  .order-xl-1 {
    order: 1 !important
  }
  .order-xl-2 {
    order: 2 !important
  }
  .order-xl-3 {
    order: 3 !important
  }
  .order-xl-4 {
    order: 4 !important
  }
  .order-xl-5 {
    order: 5 !important
  }
  .order-xl-last {
    order: 6 !important
  }
  .m-xl-0 {
    margin: 0 !important
  }
  .m-xl-1 {
    margin: .1875rem !important
  }
  .m-xl-2 {
    margin: .375rem !important
  }
  .m-xl-3 {
    margin: .75rem !important
  }
  .m-xl-4 {
    margin: 1.5rem !important
  }
  .m-xl-5 {
    margin: 2.25rem !important
  }
  .m-xl-6 {
    margin: 4.5rem !important
  }
  .m-xl-7 {
    margin: 6.75rem !important
  }
  .m-xl-8 {
    margin: 13.5rem !important
  }
  .m-xl-auto {
    margin: auto !important
  }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }
  .mx-xl-1 {
    margin-right: .1875rem !important;
    margin-left: .1875rem !important
  }
  .mx-xl-2 {
    margin-right: .375rem !important;
    margin-left: .375rem !important
  }
  .mx-xl-3 {
    margin-right: .75rem !important;
    margin-left: .75rem !important
  }
  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }
  .mx-xl-5 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important
  }
  .mx-xl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important
  }
  .mx-xl-7 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important
  }
  .mx-xl-8 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important
  }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }
  .my-xl-1 {
    margin-top: .1875rem !important;
    margin-bottom: .1875rem !important
  }
  .my-xl-2 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important
  }
  .my-xl-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important
  }
  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }
  .my-xl-5 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important
  }
  .my-xl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important
  }
  .my-xl-7 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important
  }
  .my-xl-8 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important
  }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }
  .mt-xl-0 {
    margin-top: 0 !important
  }
  .mt-xl-1 {
    margin-top: .1875rem !important
  }
  .mt-xl-2 {
    margin-top: .375rem !important
  }
  .mt-xl-3 {
    margin-top: .75rem !important
  }
  .mt-xl-4 {
    margin-top: 1.5rem !important
  }
  .mt-xl-5 {
    margin-top: 2.25rem !important
  }
  .mt-xl-6 {
    margin-top: 4.5rem !important
  }
  .mt-xl-7 {
    margin-top: 6.75rem !important
  }
  .mt-xl-8 {
    margin-top: 13.5rem !important
  }
  .mt-xl-auto {
    margin-top: auto !important
  }
  .mr-xl-0 {
    margin-right: 0 !important
  }
  .mr-xl-1 {
    margin-right: .1875rem !important
  }
  .mr-xl-2 {
    margin-right: .375rem !important
  }
  .mr-xl-3 {
    margin-right: .75rem !important
  }
  .mr-xl-4 {
    margin-right: 1.5rem !important
  }
  .mr-xl-5 {
    margin-right: 2.25rem !important
  }
  .mr-xl-6 {
    margin-right: 4.5rem !important
  }
  .mr-xl-7 {
    margin-right: 6.75rem !important
  }
  .mr-xl-8 {
    margin-right: 13.5rem !important
  }
  .mr-xl-auto {
    margin-right: auto !important
  }
  .mb-xl-0 {
    margin-bottom: 0 !important
  }
  .mb-xl-1 {
    margin-bottom: .1875rem !important
  }
  .mb-xl-2 {
    margin-bottom: .375rem !important
  }
  .mb-xl-3 {
    margin-bottom: .75rem !important
  }
  .mb-xl-4 {
    margin-bottom: 1.5rem !important
  }
  .mb-xl-5 {
    margin-bottom: 2.25rem !important
  }
  .mb-xl-6 {
    margin-bottom: 4.5rem !important
  }
  .mb-xl-7 {
    margin-bottom: 6.75rem !important
  }
  .mb-xl-8 {
    margin-bottom: 13.5rem !important
  }
  .mb-xl-auto {
    margin-bottom: auto !important
  }
  .ml-xl-0 {
    margin-left: 0 !important
  }
  .ml-xl-1 {
    margin-left: .1875rem !important
  }
  .ml-xl-2 {
    margin-left: .375rem !important
  }
  .ml-xl-3 {
    margin-left: .75rem !important
  }
  .ml-xl-4 {
    margin-left: 1.5rem !important
  }
  .ml-xl-5 {
    margin-left: 2.25rem !important
  }
  .ml-xl-6 {
    margin-left: 4.5rem !important
  }
  .ml-xl-7 {
    margin-left: 6.75rem !important
  }
  .ml-xl-8 {
    margin-left: 13.5rem !important
  }
  .ml-xl-auto {
    margin-left: auto !important
  }
  .m-xl-n1 {
    margin: -.1875rem !important
  }
  .m-xl-n2 {
    margin: -.375rem !important
  }
  .m-xl-n3 {
    margin: -.75rem !important
  }
  .m-xl-n4 {
    margin: -1.5rem !important
  }
  .m-xl-n5 {
    margin: -2.25rem !important
  }
  .m-xl-n6 {
    margin: -4.5rem !important
  }
  .m-xl-n7 {
    margin: -6.75rem !important
  }
  .m-xl-n8 {
    margin: -13.5rem !important
  }
  .mx-xl-n1 {
    margin-right: -.1875rem !important;
    margin-left: -.1875rem !important
  }
  .mx-xl-n2 {
    margin-right: -.375rem !important;
    margin-left: -.375rem !important
  }
  .mx-xl-n3 {
    margin-right: -.75rem !important;
    margin-left: -.75rem !important
  }
  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important
  }
  .mx-xl-n5 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important
  }
  .mx-xl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important
  }
  .mx-xl-n7 {
    margin-right: -6.75rem !important;
    margin-left: -6.75rem !important
  }
  .mx-xl-n8 {
    margin-right: -13.5rem !important;
    margin-left: -13.5rem !important
  }
  .my-xl-n1 {
    margin-top: -.1875rem !important;
    margin-bottom: -.1875rem !important
  }
  .my-xl-n2 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important
  }
  .my-xl-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important
  }
  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important
  }
  .my-xl-n5 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important
  }
  .my-xl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important
  }
  .my-xl-n7 {
    margin-top: -6.75rem !important;
    margin-bottom: -6.75rem !important
  }
  .my-xl-n8 {
    margin-top: -13.5rem !important;
    margin-bottom: -13.5rem !important
  }
  .mt-xl-n1 {
    margin-top: -.1875rem !important
  }
  .mt-xl-n2 {
    margin-top: -.375rem !important
  }
  .mt-xl-n3 {
    margin-top: -.75rem !important
  }
  .mt-xl-n4 {
    margin-top: -1.5rem !important
  }
  .mt-xl-n5 {
    margin-top: -2.25rem !important
  }
  .mt-xl-n6 {
    margin-top: -4.5rem !important
  }
  .mt-xl-n7 {
    margin-top: -6.75rem !important
  }
  .mt-xl-n8 {
    margin-top: -13.5rem !important
  }
  .mr-xl-n1 {
    margin-right: -.1875rem !important
  }
  .mr-xl-n2 {
    margin-right: -.375rem !important
  }
  .mr-xl-n3 {
    margin-right: -.75rem !important
  }
  .mr-xl-n4 {
    margin-right: -1.5rem !important
  }
  .mr-xl-n5 {
    margin-right: -2.25rem !important
  }
  .mr-xl-n6 {
    margin-right: -4.5rem !important
  }
  .mr-xl-n7 {
    margin-right: -6.75rem !important
  }
  .mr-xl-n8 {
    margin-right: -13.5rem !important
  }
  .mb-xl-n1 {
    margin-bottom: -.1875rem !important
  }
  .mb-xl-n2 {
    margin-bottom: -.375rem !important
  }
  .mb-xl-n3 {
    margin-bottom: -.75rem !important
  }
  .mb-xl-n4 {
    margin-bottom: -1.5rem !important
  }
  .mb-xl-n5 {
    margin-bottom: -2.25rem !important
  }
  .mb-xl-n6 {
    margin-bottom: -4.5rem !important
  }
  .mb-xl-n7 {
    margin-bottom: -6.75rem !important
  }
  .mb-xl-n8 {
    margin-bottom: -13.5rem !important
  }
  .ml-xl-n1 {
    margin-left: -.1875rem !important
  }
  .ml-xl-n2 {
    margin-left: -.375rem !important
  }
  .ml-xl-n3 {
    margin-left: -.75rem !important
  }
  .ml-xl-n4 {
    margin-left: -1.5rem !important
  }
  .ml-xl-n5 {
    margin-left: -2.25rem !important
  }
  .ml-xl-n6 {
    margin-left: -4.5rem !important
  }
  .ml-xl-n7 {
    margin-left: -6.75rem !important
  }
  .ml-xl-n8 {
    margin-left: -13.5rem !important
  }
  .p-xl-0 {
    padding: 0 !important
  }
  .p-xl-1 {
    padding: .1875rem !important
  }
  .p-xl-2 {
    padding: .375rem !important
  }
  .p-xl-3 {
    padding: .75rem !important
  }
  .p-xl-4 {
    padding: 1.5rem !important
  }
  .p-xl-5 {
    padding: 2.25rem !important
  }
  .p-xl-6 {
    padding: 4.5rem !important
  }
  .p-xl-7 {
    padding: 6.75rem !important
  }
  .p-xl-8 {
    padding: 13.5rem !important
  }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }
  .px-xl-1 {
    padding-right: .1875rem !important;
    padding-left: .1875rem !important
  }
  .px-xl-2 {
    padding-right: .375rem !important;
    padding-left: .375rem !important
  }
  .px-xl-3 {
    padding-right: .75rem !important;
    padding-left: .75rem !important
  }
  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }
  .px-xl-5 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important
  }
  .px-xl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important
  }
  .px-xl-7 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important
  }
  .px-xl-8 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important
  }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }
  .py-xl-1 {
    padding-top: .1875rem !important;
    padding-bottom: .1875rem !important
  }
  .py-xl-2 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important
  }
  .py-xl-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important
  }
  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }
  .py-xl-5 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important
  }
  .py-xl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important
  }
  .py-xl-7 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important
  }
  .py-xl-8 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important
  }
  .pt-xl-0 {
    padding-top: 0 !important
  }
  .pt-xl-1 {
    padding-top: .1875rem !important
  }
  .pt-xl-2 {
    padding-top: .375rem !important
  }
  .pt-xl-3 {
    padding-top: .75rem !important
  }
  .pt-xl-4 {
    padding-top: 1.5rem !important
  }
  .pt-xl-5 {
    padding-top: 2.25rem !important
  }
  .pt-xl-6 {
    padding-top: 4.5rem !important
  }
  .pt-xl-7 {
    padding-top: 6.75rem !important
  }
  .pt-xl-8 {
    padding-top: 13.5rem !important
  }
  .pr-xl-0 {
    padding-right: 0 !important
  }
  .pr-xl-1 {
    padding-right: .1875rem !important
  }
  .pr-xl-2 {
    padding-right: .375rem !important
  }
  .pr-xl-3 {
    padding-right: .75rem !important
  }
  .pr-xl-4 {
    padding-right: 1.5rem !important
  }
  .pr-xl-5 {
    padding-right: 2.25rem !important
  }
  .pr-xl-6 {
    padding-right: 4.5rem !important
  }
  .pr-xl-7 {
    padding-right: 6.75rem !important
  }
  .pr-xl-8 {
    padding-right: 13.5rem !important
  }
  .pb-xl-0 {
    padding-bottom: 0 !important
  }
  .pb-xl-1 {
    padding-bottom: .1875rem !important
  }
  .pb-xl-2 {
    padding-bottom: .375rem !important
  }
  .pb-xl-3 {
    padding-bottom: .75rem !important
  }
  .pb-xl-4 {
    padding-bottom: 1.5rem !important
  }
  .pb-xl-5 {
    padding-bottom: 2.25rem !important
  }
  .pb-xl-6 {
    padding-bottom: 4.5rem !important
  }
  .pb-xl-7 {
    padding-bottom: 6.75rem !important
  }
  .pb-xl-8 {
    padding-bottom: 13.5rem !important
  }
  .pl-xl-0 {
    padding-left: 0 !important
  }
  .pl-xl-1 {
    padding-left: .1875rem !important
  }
  .pl-xl-2 {
    padding-left: .375rem !important
  }
  .pl-xl-3 {
    padding-left: .75rem !important
  }
  .pl-xl-4 {
    padding-left: 1.5rem !important
  }
  .pl-xl-5 {
    padding-left: 2.25rem !important
  }
  .pl-xl-6 {
    padding-left: 4.5rem !important
  }
  .pl-xl-7 {
    padding-left: 6.75rem !important
  }
  .pl-xl-8 {
    padding-left: 13.5rem !important
  }
  .text-xl-left {
    text-align: left !important
  }
  .text-xl-right {
    text-align: right !important
  }
  .text-xl-center {
    text-align: center !important
  }
}

@media (min-width: 1400px) {
  .float-xxl-left {
    float: left !important
  }
  .float-xxl-right {
    float: right !important
  }
  .float-xxl-none {
    float: none !important
  }
  .d-xxl-none {
    display: none !important
  }
  .d-xxl-inline {
    display: inline !important
  }
  .d-xxl-inline-block {
    display: inline-block !important
  }
  .d-xxl-block {
    display: block !important
  }
  .d-xxl-table {
    display: table !important
  }
  .d-xxl-table-row {
    display: table-row !important
  }
  .d-xxl-table-cell {
    display: table-cell !important
  }
  .d-xxl-flex {
    display: flex !important
  }
  .d-xxl-inline-flex {
    display: inline-flex !important
  }
  .flex-xxl-fill {
    flex: 1 1 auto !important
  }
  .flex-xxl-row {
    flex-direction: row !important
  }
  .flex-xxl-column {
    flex-direction: column !important
  }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important
  }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important
  }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important
  }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important
  }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important
  }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important
  }
  .flex-xxl-wrap {
    flex-wrap: wrap !important
  }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important
  }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important
  }
  .justify-content-xxl-start {
    justify-content: flex-start !important
  }
  .justify-content-xxl-end {
    justify-content: flex-end !important
  }
  .justify-content-xxl-center {
    justify-content: center !important
  }
  .justify-content-xxl-between {
    justify-content: space-between !important
  }
  .justify-content-xxl-around {
    justify-content: space-around !important
  }
  .justify-content-xxl-evenly {
    justify-content: space-evenly !important
  }
  .align-items-xxl-start {
    align-items: flex-start !important
  }
  .align-items-xxl-end {
    align-items: flex-end !important
  }
  .align-items-xxl-center {
    align-items: center !important
  }
  .align-items-xxl-baseline {
    align-items: baseline !important
  }
  .align-items-xxl-stretch {
    align-items: stretch !important
  }
  .align-content-xxl-start {
    align-content: flex-start !important
  }
  .align-content-xxl-end {
    align-content: flex-end !important
  }
  .align-content-xxl-center {
    align-content: center !important
  }
  .align-content-xxl-between {
    align-content: space-between !important
  }
  .align-content-xxl-around {
    align-content: space-around !important
  }
  .align-content-xxl-stretch {
    align-content: stretch !important
  }
  .align-self-xxl-auto {
    align-self: auto !important
  }
  .align-self-xxl-start {
    align-self: flex-start !important
  }
  .align-self-xxl-end {
    align-self: flex-end !important
  }
  .align-self-xxl-center {
    align-self: center !important
  }
  .align-self-xxl-baseline {
    align-self: baseline !important
  }
  .align-self-xxl-stretch {
    align-self: stretch !important
  }
  .order-xxl-first {
    order: -1 !important
  }
  .order-xxl-0 {
    order: 0 !important
  }
  .order-xxl-1 {
    order: 1 !important
  }
  .order-xxl-2 {
    order: 2 !important
  }
  .order-xxl-3 {
    order: 3 !important
  }
  .order-xxl-4 {
    order: 4 !important
  }
  .order-xxl-5 {
    order: 5 !important
  }
  .order-xxl-last {
    order: 6 !important
  }
  .m-xxl-0 {
    margin: 0 !important
  }
  .m-xxl-1 {
    margin: .1875rem !important
  }
  .m-xxl-2 {
    margin: .375rem !important
  }
  .m-xxl-3 {
    margin: .75rem !important
  }
  .m-xxl-4 {
    margin: 1.5rem !important
  }
  .m-xxl-5 {
    margin: 2.25rem !important
  }
  .m-xxl-6 {
    margin: 4.5rem !important
  }
  .m-xxl-7 {
    margin: 6.75rem !important
  }
  .m-xxl-8 {
    margin: 13.5rem !important
  }
  .m-xxl-auto {
    margin: auto !important
  }
  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important
  }
  .mx-xxl-1 {
    margin-right: .1875rem !important;
    margin-left: .1875rem !important
  }
  .mx-xxl-2 {
    margin-right: .375rem !important;
    margin-left: .375rem !important
  }
  .mx-xxl-3 {
    margin-right: .75rem !important;
    margin-left: .75rem !important
  }
  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important
  }
  .mx-xxl-5 {
    margin-right: 2.25rem !important;
    margin-left: 2.25rem !important
  }
  .mx-xxl-6 {
    margin-right: 4.5rem !important;
    margin-left: 4.5rem !important
  }
  .mx-xxl-7 {
    margin-right: 6.75rem !important;
    margin-left: 6.75rem !important
  }
  .mx-xxl-8 {
    margin-right: 13.5rem !important;
    margin-left: 13.5rem !important
  }
  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important
  }
  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important
  }
  .my-xxl-1 {
    margin-top: .1875rem !important;
    margin-bottom: .1875rem !important
  }
  .my-xxl-2 {
    margin-top: .375rem !important;
    margin-bottom: .375rem !important
  }
  .my-xxl-3 {
    margin-top: .75rem !important;
    margin-bottom: .75rem !important
  }
  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important
  }
  .my-xxl-5 {
    margin-top: 2.25rem !important;
    margin-bottom: 2.25rem !important
  }
  .my-xxl-6 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important
  }
  .my-xxl-7 {
    margin-top: 6.75rem !important;
    margin-bottom: 6.75rem !important
  }
  .my-xxl-8 {
    margin-top: 13.5rem !important;
    margin-bottom: 13.5rem !important
  }
  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important
  }
  .mt-xxl-0 {
    margin-top: 0 !important
  }
  .mt-xxl-1 {
    margin-top: .1875rem !important
  }
  .mt-xxl-2 {
    margin-top: .375rem !important
  }
  .mt-xxl-3 {
    margin-top: .75rem !important
  }
  .mt-xxl-4 {
    margin-top: 1.5rem !important
  }
  .mt-xxl-5 {
    margin-top: 2.25rem !important
  }
  .mt-xxl-6 {
    margin-top: 4.5rem !important
  }
  .mt-xxl-7 {
    margin-top: 6.75rem !important
  }
  .mt-xxl-8 {
    margin-top: 13.5rem !important
  }
  .mt-xxl-auto {
    margin-top: auto !important
  }
  .mr-xxl-0 {
    margin-right: 0 !important
  }
  .mr-xxl-1 {
    margin-right: .1875rem !important
  }
  .mr-xxl-2 {
    margin-right: .375rem !important
  }
  .mr-xxl-3 {
    margin-right: .75rem !important
  }
  .mr-xxl-4 {
    margin-right: 1.5rem !important
  }
  .mr-xxl-5 {
    margin-right: 2.25rem !important
  }
  .mr-xxl-6 {
    margin-right: 4.5rem !important
  }
  .mr-xxl-7 {
    margin-right: 6.75rem !important
  }
  .mr-xxl-8 {
    margin-right: 13.5rem !important
  }
  .mr-xxl-auto {
    margin-right: auto !important
  }
  .mb-xxl-0 {
    margin-bottom: 0 !important
  }
  .mb-xxl-1 {
    margin-bottom: .1875rem !important
  }
  .mb-xxl-2 {
    margin-bottom: .375rem !important
  }
  .mb-xxl-3 {
    margin-bottom: .75rem !important
  }
  .mb-xxl-4 {
    margin-bottom: 1.5rem !important
  }
  .mb-xxl-5 {
    margin-bottom: 2.25rem !important
  }
  .mb-xxl-6 {
    margin-bottom: 4.5rem !important
  }
  .mb-xxl-7 {
    margin-bottom: 6.75rem !important
  }
  .mb-xxl-8 {
    margin-bottom: 13.5rem !important
  }
  .mb-xxl-auto {
    margin-bottom: auto !important
  }
  .ml-xxl-0 {
    margin-left: 0 !important
  }
  .ml-xxl-1 {
    margin-left: .1875rem !important
  }
  .ml-xxl-2 {
    margin-left: .375rem !important
  }
  .ml-xxl-3 {
    margin-left: .75rem !important
  }
  .ml-xxl-4 {
    margin-left: 1.5rem !important
  }
  .ml-xxl-5 {
    margin-left: 2.25rem !important
  }
  .ml-xxl-6 {
    margin-left: 4.5rem !important
  }
  .ml-xxl-7 {
    margin-left: 6.75rem !important
  }
  .ml-xxl-8 {
    margin-left: 13.5rem !important
  }
  .ml-xxl-auto {
    margin-left: auto !important
  }
  .m-xxl-n1 {
    margin: -.1875rem !important
  }
  .m-xxl-n2 {
    margin: -.375rem !important
  }
  .m-xxl-n3 {
    margin: -.75rem !important
  }
  .m-xxl-n4 {
    margin: -1.5rem !important
  }
  .m-xxl-n5 {
    margin: -2.25rem !important
  }
  .m-xxl-n6 {
    margin: -4.5rem !important
  }
  .m-xxl-n7 {
    margin: -6.75rem !important
  }
  .m-xxl-n8 {
    margin: -13.5rem !important
  }
  .mx-xxl-n1 {
    margin-right: -.1875rem !important;
    margin-left: -.1875rem !important
  }
  .mx-xxl-n2 {
    margin-right: -.375rem !important;
    margin-left: -.375rem !important
  }
  .mx-xxl-n3 {
    margin-right: -.75rem !important;
    margin-left: -.75rem !important
  }
  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important
  }
  .mx-xxl-n5 {
    margin-right: -2.25rem !important;
    margin-left: -2.25rem !important
  }
  .mx-xxl-n6 {
    margin-right: -4.5rem !important;
    margin-left: -4.5rem !important
  }
  .mx-xxl-n7 {
    margin-right: -6.75rem !important;
    margin-left: -6.75rem !important
  }
  .mx-xxl-n8 {
    margin-right: -13.5rem !important;
    margin-left: -13.5rem !important
  }
  .my-xxl-n1 {
    margin-top: -.1875rem !important;
    margin-bottom: -.1875rem !important
  }
  .my-xxl-n2 {
    margin-top: -.375rem !important;
    margin-bottom: -.375rem !important
  }
  .my-xxl-n3 {
    margin-top: -.75rem !important;
    margin-bottom: -.75rem !important
  }
  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important
  }
  .my-xxl-n5 {
    margin-top: -2.25rem !important;
    margin-bottom: -2.25rem !important
  }
  .my-xxl-n6 {
    margin-top: -4.5rem !important;
    margin-bottom: -4.5rem !important
  }
  .my-xxl-n7 {
    margin-top: -6.75rem !important;
    margin-bottom: -6.75rem !important
  }
  .my-xxl-n8 {
    margin-top: -13.5rem !important;
    margin-bottom: -13.5rem !important
  }
  .mt-xxl-n1 {
    margin-top: -.1875rem !important
  }
  .mt-xxl-n2 {
    margin-top: -.375rem !important
  }
  .mt-xxl-n3 {
    margin-top: -.75rem !important
  }
  .mt-xxl-n4 {
    margin-top: -1.5rem !important
  }
  .mt-xxl-n5 {
    margin-top: -2.25rem !important
  }
  .mt-xxl-n6 {
    margin-top: -4.5rem !important
  }
  .mt-xxl-n7 {
    margin-top: -6.75rem !important
  }
  .mt-xxl-n8 {
    margin-top: -13.5rem !important
  }
  .mr-xxl-n1 {
    margin-right: -.1875rem !important
  }
  .mr-xxl-n2 {
    margin-right: -.375rem !important
  }
  .mr-xxl-n3 {
    margin-right: -.75rem !important
  }
  .mr-xxl-n4 {
    margin-right: -1.5rem !important
  }
  .mr-xxl-n5 {
    margin-right: -2.25rem !important
  }
  .mr-xxl-n6 {
    margin-right: -4.5rem !important
  }
  .mr-xxl-n7 {
    margin-right: -6.75rem !important
  }
  .mr-xxl-n8 {
    margin-right: -13.5rem !important
  }
  .mb-xxl-n1 {
    margin-bottom: -.1875rem !important
  }
  .mb-xxl-n2 {
    margin-bottom: -.375rem !important
  }
  .mb-xxl-n3 {
    margin-bottom: -.75rem !important
  }
  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important
  }
  .mb-xxl-n5 {
    margin-bottom: -2.25rem !important
  }
  .mb-xxl-n6 {
    margin-bottom: -4.5rem !important
  }
  .mb-xxl-n7 {
    margin-bottom: -6.75rem !important
  }
  .mb-xxl-n8 {
    margin-bottom: -13.5rem !important
  }
  .ml-xxl-n1 {
    margin-left: -.1875rem !important
  }
  .ml-xxl-n2 {
    margin-left: -.375rem !important
  }
  .ml-xxl-n3 {
    margin-left: -.75rem !important
  }
  .ml-xxl-n4 {
    margin-left: -1.5rem !important
  }
  .ml-xxl-n5 {
    margin-left: -2.25rem !important
  }
  .ml-xxl-n6 {
    margin-left: -4.5rem !important
  }
  .ml-xxl-n7 {
    margin-left: -6.75rem !important
  }
  .ml-xxl-n8 {
    margin-left: -13.5rem !important
  }
  .p-xxl-0 {
    padding: 0 !important
  }
  .p-xxl-1 {
    padding: .1875rem !important
  }
  .p-xxl-2 {
    padding: .375rem !important
  }
  .p-xxl-3 {
    padding: .75rem !important
  }
  .p-xxl-4 {
    padding: 1.5rem !important
  }
  .p-xxl-5 {
    padding: 2.25rem !important
  }
  .p-xxl-6 {
    padding: 4.5rem !important
  }
  .p-xxl-7 {
    padding: 6.75rem !important
  }
  .p-xxl-8 {
    padding: 13.5rem !important
  }
  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important
  }
  .px-xxl-1 {
    padding-right: .1875rem !important;
    padding-left: .1875rem !important
  }
  .px-xxl-2 {
    padding-right: .375rem !important;
    padding-left: .375rem !important
  }
  .px-xxl-3 {
    padding-right: .75rem !important;
    padding-left: .75rem !important
  }
  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
  }
  .px-xxl-5 {
    padding-right: 2.25rem !important;
    padding-left: 2.25rem !important
  }
  .px-xxl-6 {
    padding-right: 4.5rem !important;
    padding-left: 4.5rem !important
  }
  .px-xxl-7 {
    padding-right: 6.75rem !important;
    padding-left: 6.75rem !important
  }
  .px-xxl-8 {
    padding-right: 13.5rem !important;
    padding-left: 13.5rem !important
  }
  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important
  }
  .py-xxl-1 {
    padding-top: .1875rem !important;
    padding-bottom: .1875rem !important
  }
  .py-xxl-2 {
    padding-top: .375rem !important;
    padding-bottom: .375rem !important
  }
  .py-xxl-3 {
    padding-top: .75rem !important;
    padding-bottom: .75rem !important
  }
  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important
  }
  .py-xxl-5 {
    padding-top: 2.25rem !important;
    padding-bottom: 2.25rem !important
  }
  .py-xxl-6 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important
  }
  .py-xxl-7 {
    padding-top: 6.75rem !important;
    padding-bottom: 6.75rem !important
  }
  .py-xxl-8 {
    padding-top: 13.5rem !important;
    padding-bottom: 13.5rem !important
  }
  .pt-xxl-0 {
    padding-top: 0 !important
  }
  .pt-xxl-1 {
    padding-top: .1875rem !important
  }
  .pt-xxl-2 {
    padding-top: .375rem !important
  }
  .pt-xxl-3 {
    padding-top: .75rem !important
  }
  .pt-xxl-4 {
    padding-top: 1.5rem !important
  }
  .pt-xxl-5 {
    padding-top: 2.25rem !important
  }
  .pt-xxl-6 {
    padding-top: 4.5rem !important
  }
  .pt-xxl-7 {
    padding-top: 6.75rem !important
  }
  .pt-xxl-8 {
    padding-top: 13.5rem !important
  }
  .pr-xxl-0 {
    padding-right: 0 !important
  }
  .pr-xxl-1 {
    padding-right: .1875rem !important
  }
  .pr-xxl-2 {
    padding-right: .375rem !important
  }
  .pr-xxl-3 {
    padding-right: .75rem !important
  }
  .pr-xxl-4 {
    padding-right: 1.5rem !important
  }
  .pr-xxl-5 {
    padding-right: 2.25rem !important
  }
  .pr-xxl-6 {
    padding-right: 4.5rem !important
  }
  .pr-xxl-7 {
    padding-right: 6.75rem !important
  }
  .pr-xxl-8 {
    padding-right: 13.5rem !important
  }
  .pb-xxl-0 {
    padding-bottom: 0 !important
  }
  .pb-xxl-1 {
    padding-bottom: .1875rem !important
  }
  .pb-xxl-2 {
    padding-bottom: .375rem !important
  }
  .pb-xxl-3 {
    padding-bottom: .75rem !important
  }
  .pb-xxl-4 {
    padding-bottom: 1.5rem !important
  }
  .pb-xxl-5 {
    padding-bottom: 2.25rem !important
  }
  .pb-xxl-6 {
    padding-bottom: 4.5rem !important
  }
  .pb-xxl-7 {
    padding-bottom: 6.75rem !important
  }
  .pb-xxl-8 {
    padding-bottom: 13.5rem !important
  }
  .pl-xxl-0 {
    padding-left: 0 !important
  }
  .pl-xxl-1 {
    padding-left: .1875rem !important
  }
  .pl-xxl-2 {
    padding-left: .375rem !important
  }
  .pl-xxl-3 {
    padding-left: .75rem !important
  }
  .pl-xxl-4 {
    padding-left: 1.5rem !important
  }
  .pl-xxl-5 {
    padding-left: 2.25rem !important
  }
  .pl-xxl-6 {
    padding-left: 4.5rem !important
  }
  .pl-xxl-7 {
    padding-left: 6.75rem !important
  }
  .pl-xxl-8 {
    padding-left: 13.5rem !important
  }
  .text-xxl-left {
    text-align: left !important
  }
  .text-xxl-right {
    text-align: right !important
  }
  .text-xxl-center {
    text-align: center !important
  }
}

@media print {
  .d-print-none {
    display: none !important
  }
  .d-print-inline {
    display: inline !important
  }
  .d-print-inline-block {
    display: inline-block !important
  }
  .d-print-block {
    display: block !important
  }
  .d-print-table {
    display: table !important
  }
  .d-print-table-row {
    display: table-row !important
  }
  .d-print-table-cell {
    display: table-cell !important
  }
  .d-print-flex {
    display: flex !important
  }
  .d-print-inline-flex {
    display: inline-flex !important
  }
}

.bg-fixed-bottom {
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 100% auto;
  background-attachment: fixed
}

.navbar-vertical ~ .main-content.bg-fixed-bottom {
  background-size: 100%
}

@media (min-width: 768px) {
  .navbar-vertical ~ .main-content.bg-fixed-bottom {
    background-size: calc(100% - 250px)
  }
}

.bg-cover {
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: cover
}

.bg-ellipses.bg-primary {
  background-image: radial-gradient(#2c7be5, #2c7be5 70%, transparent 70.1%)
}

.bg-ellipses.bg-primary, .bg-ellipses.bg-secondary {
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-size: 200% 150%;
  background-position: bottom
}

.bg-ellipses.bg-secondary {
  background-image: radial-gradient(#6e84a3, #6e84a3 70%, transparent 70.1%)
}

.bg-ellipses.bg-success {
  background-image: radial-gradient(#00d97e, #00d97e 70%, transparent 70.1%)
}

.bg-ellipses.bg-info, .bg-ellipses.bg-success {
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-size: 200% 150%;
  background-position: bottom
}

.bg-ellipses.bg-info {
  background-image: radial-gradient(#39afd1, #39afd1 70%, transparent 70.1%)
}

.bg-ellipses.bg-warning {
  background-image: radial-gradient(#f6c343, #f6c343 70%, transparent 70.1%)
}

.bg-ellipses.bg-danger, .bg-ellipses.bg-warning {
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-size: 200% 150%;
  background-position: bottom
}

.bg-ellipses.bg-danger {
  background-image: radial-gradient(#e63757, #e63757 70%, transparent 70.1%)
}

.bg-ellipses.bg-light {
  background-image: radial-gradient(#152e4d, #152e4d 70%, transparent 70.1%)
}

.bg-ellipses.bg-dark, .bg-ellipses.bg-light {
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-size: 200% 150%;
  background-position: bottom
}

.bg-ellipses.bg-dark {
  background-image: radial-gradient(#12263f, #12263f 70%, transparent 70.1%)
}

.bg-ellipses.bg-white {
  background-color: transparent !important;
  background-repeat: no-repeat;
  background-image: radial-gradient(#fff, #fff 70%, transparent 70.1%);
  background-size: 200% 150%;
  background-position: bottom
}

.bg-hero {
  background-image: linear-gradient(180deg, rgba(18, 38, 63, .85), rgba(18, 38, 63, .85)), url(../img/covers/header-cover.jpg);
  background-repeat: no-repeat, no-repeat;
  background-position: 50%, 50%;
  background-size: cover, cover
}

.bg-primary-soft {
  background-color: #173760 !important
}

.bg-secondary-soft {
  background-color: #243953 !important
}

.bg-success-soft {
  background-color: #0e4a4c !important
}

.bg-info-soft {
  background-color: #1a415c !important
}

.bg-warning-soft {
  background-color: #404540 !important
}

.bg-danger-soft {
  background-color: #3c2944 !important
}

.bg-light-soft {
  background-color: #132842 !important
}

.bg-dark-soft {
  background-color: #12263f !important
}

.bg-white-soft {
  background-color: #415165 !important
}

.lift {
  transition: box-shadow .25s ease, transform .25s ease
}

.lift:focus, .lift:hover {
  box-shadow: 0 1rem 2.5rem rgba(18, 38, 63, .1), 0 .5rem 1rem -.75rem rgba(18, 38, 63, .1) !important;
  transform: translate3d(0, -3px, 0)
}

.lift-lg:focus, .lift-lg:hover {
  box-shadow: 0 2rem 5rem rgba(18, 38, 63, .1), 0 .5rem 1rem -.75rem rgba(18, 38, 63, .05) !important;
  transform: translate3d(0, -5px, 0)
}

:root {
  --bs-chart-gray-300: #e3ebf6;
  --bs-chart-gray-600: #95aac9;
  --bs-chart-gray-700: #6e84a3;
  --bs-chart-gray-800: #3b506c;
  --bs-chart-gray-900: #283e59;
  --bs-chart-primary-100: #d2ddec;
  --bs-chart-primary-300: #a6c5f7;
  --bs-chart-default-color: #6e84a3;
  --bs-chart-default-font-color: #6e84a3;
  --bs-chart-arc-border-color: #152e4d;
  --bs-chart-arc-hover-border-color: #152e4d;
  --bs-chart-grid-line-color: #283e59;
  --bs-chart-grid-line-zero-line-color: #283e59
}

html {
  height: 100%
}

body {
  min-height: 100%
}

ol, ul {
  padding-left: 2.5rem
}

[type=search]::-webkit-search-cancel-button {
  -webkit-appearance: none
}

.h1, h1 {
  margin-bottom: 1.125rem;
  font-size: 1.5rem
}

@media (min-width: 768px) {
  .h1, h1 {
    font-size: 1.625rem
  }
}

.h2, h2 {
  margin-bottom: 1.125rem
}

.h3, h3 {
  margin-bottom: .84375rem
}

.h4, .h5, .h6, h4, h5, h6 {
  margin-bottom: .5625rem
}

.h1 > a, .h2 > a, .h3 > a, .h4 > a, .h5 > a, .h6 > a, h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
  color: inherit
}

.display-1, .display-2, .display-3, .display-4, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  letter-spacing: -.02em
}

.text-uppercase.h6, h6.text-uppercase {
  letter-spacing: .08em
}

b, strong {
  font-weight: 600
}

a:focus, button:focus {
  outline: none !important
}

@font-face {
  font-family: Cerebri Sans;
  src: url(../fonts/cerebrisans/cerebrisans-regular.eot);
  src: url(../fonts/cerebrisans/cerebrisans-regular.eot?#iefix) format("embedded-opentype"), url(../fonts/cerebrisans/cerebrisans-regular.woff) format("woff"), url(../fonts/cerebrisans/cerebrisans-regular.ttf) format("truetype");
  font-weight: 400;
  font-style: normal
}

@font-face {
  font-family: Cerebri Sans;
  src: url(../fonts/cerebrisans/cerebrisans-medium.eot);
  src: url(../fonts/cerebrisans/cerebrisans-medium.eot?#iefix) format("embedded-opentype"), url(../fonts/cerebrisans/cerebrisans-medium.woff) format("woff"), url(../fonts/cerebrisans/cerebrisans-medium.ttf) format("truetype");
  font-weight: 500;
  font-style: normal
}

@font-face {
  font-family: Cerebri Sans;
  src: url(../fonts/cerebrisans/cerebrisans-semibold.eot);
  src: url(../fonts/cerebrisans/cerebrisans-semibold.eot?#iefix) format("embedded-opentype"), url(../fonts/cerebrisans/cerebrisans-semibold.woff) format("woff"), url(../fonts/cerebrisans/cerebrisans-semibold.ttf) format("truetype");
  font-weight: 600;
  font-style: normal
}

@font-face {
  font-family: Feather;
  src: url(../fonts/feather/Feather.ttf?sdxovp) format("truetype"), url(../fonts/feather/Feather.woff?sdxovp) format("woff"), url(../fonts/feather/Feather.svg?sdxovp#Feather) format("svg");
  font-weight: 400;
  font-style: normal
}

.table thead th {
  background-color: #12263f;
  text-transform: uppercase;
  font-size: .8125rem;
  font-weight: 600;
  letter-spacing: .08em;
  color: #6e84a3
}

.table thead th, tbody td, tbody th {
  vertical-align: middle
}

.table td, .table th {
  border-top: 1px solid #1e3a5c;
  border-bottom: 0
}

.table-sm {
  font-size: .8125rem
}

.table-sm thead th {
  font-size: .625rem
}

.table-nowrap td, .table-nowrap th, .table [data-sort] {
  white-space: nowrap
}

.table [data-sort]:after {
  content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='%236E84A3'/></svg>");
  margin-left: .25rem
}

.table a[class*=text-reset]:hover {
  color: #2c7be5 !important
}

.form-text {
  display: block;
  margin-bottom: .5rem
}

.form-control-flush {
  padding-left: 0;
  border-color: transparent !important;
  background-color: transparent !important;
  resize: none
}

.form-control:not(.custom-select) {
  padding-right: 0
}

.form-control-auto {
  min-height: 0;
  padding-top: 0;
  padding-bottom: 0
}

.form-control-rounded {
  border-radius: 20rem
}

.form-control.h1, .form-control.h2, .form-control.h3, .form-control.h4, .form-control.h5 {
  margin-bottom: 0;
  font-weight: 500;
  letter-spacing: -.02em
}

.form-control.h1 {
  font-size: 1.625rem
}

.form-control.h2 {
  font-size: 1.25rem
}

.form-control.h3 {
  font-size: 1.0625rem
}

.form-control.h4 {
  font-size: .9375rem
}

.form-control.h5 {
  font-size: .8125rem
}

.form-check {
  margin-bottom: 0
}

.form-check + .form-check {
  margin-top: .125rem
}

.form-check-input {
  width: 1rem;
  height: 1rem;
  margin-top: calc((1.5em - 1rem) / 2);
  cursor: pointer
}

.form-check-input:focus:not(:checked) {
  background-color: #cbdef9
}

.form-check:not(.form-switch):not(.form-check-circle) .form-check-input:checked {
  background-size: 75% 75%
}

.form-switch {
  min-height: 1.5rem
}

.form-switch > .form-check-input {
  height: 1.5rem;
  margin-top: 0
}

.form-switch > .form-check-label {
  margin-top: calc((1.5rem - 1.5em) / 2)
}

.form-check-circle {
  padding-left: 1.875rem
}

.form-check-circle > .form-check-input {
  width: 1.375rem;
  height: 1.375rem;
  margin-left: -1.875rem;
  border-radius: 50%;
  background-size: .75rem .5625rem
}

.form-check-circle > .form-check-input, .form-check-circle > .form-check-input:checked {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 12 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.333 1L4 8.333.667 5' stroke='%23fff' stroke-width='1.333' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")
}

.form-check-circle > .form-check-label {
  margin-top: calc((1.375rem - 1em) / 2)
}

.input-group-merge > .form-control {
  border-right-width: 0
}

.input-group-merge > .input-group-text {
  border-left-width: 0;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.input-group-merge > .form-control:focus + .input-group-text {
  border-color: #2c7be5
}

.input-group > :not(:first-child):not(.dropdown-menu) {
  margin-left: 0
}

.input-group-merge.input-group-reverse {
  display: flex;
  flex-direction: row-reverse
}

.input-group-merge.input-group-reverse > .form-control {
  padding-right: .75rem;
  padding-left: 0;
  border-radius: 0 .375rem .375rem 0;
  border-right-width: 1px;
  border-left-width: 0
}

.input-group-merge.input-group-reverse > .input-group-text {
  border-radius: .375rem 0 0 .375rem;
  border-left-width: 1px;
  border-right-width: 0
}

.input-group-merge.input-group-reverse:not(.input-group-rounded) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: .375rem;
  border-bottom-right-radius: .375rem
}

.input-group-sm.input-group-merge.input-group-reverse > .form-control {
  padding-right: .5rem;
  border-radius: 0 .25rem .25rem 0
}

.input-group-sm.input-group-merge.input-group-reverse > .input-group-text {
  border-radius: .25rem 0 0 .25rem
}

.input-group-sm.input-group-merge.input-group-reverse:not(.input-group-rounded) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: .25rem;
  border-bottom-right-radius: .25rem
}

.input-group-lg.input-group-merge.input-group-reverse > .form-control {
  padding-right: 1.25rem;
  border-radius: 0 .5rem .5rem 0
}

.input-group-lg.input-group-merge.input-group-reverse > .input-group-text {
  border-radius: .5rem 0 0 .5rem
}

.input-group-lg.input-group-merge.input-group-reverse:not(.input-group-rounded) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
  border-top-right-radius: .5rem;
  border-bottom-right-radius: .5rem
}

.input-group-rounded.input-group-merge > .form-control {
  border-top-left-radius: 50rem;
  border-bottom-left-radius: 50rem
}

.input-group-rounded.input-group-merge > .input-group-text {
  border-top-right-radius: 50rem;
  border-bottom-right-radius: 50rem
}

.input-group-rounded.input-group-merge.input-group-reverse > .form-control {
  border-radius: 0 50rem 50rem 0
}

.input-group-rounded.input-group-merge.input-group-reverse > .input-group-text {
  border-radius: 50rem 0 0 50rem
}

.input-group-flush.input-group-merge > .form-control, .input-group-flush.input-group-merge > .input-group-text {
  padding-top: 0;
  padding-bottom: 0;
  border-color: transparent;
  background-color: transparent
}

.input-group-flush.input-group-merge > .form-control {
  padding-right: 0;
  padding-left: 0
}

.input-group-flush.input-group-merge > .form-control:focus + .input-group-text {
  border-color: transparent
}

.input-group-flush.input-group-merge:not(.input-group-reverse) > .input-group-text {
  padding-right: 0
}

.input-group-flush.input-group-merge.input-group-reverse > .input-group-text {
  padding-left: 0
}

.input-group-merge > .form-control.is-valid + .input-group-text {
  border-color: #00d97e
}

.input-group-merge > .form-control.is-invalid + .input-group-text {
  border-color: #e63757
}

.form-control.is-invalid:focus, .form-control.is-valid:focus {
  box-shadow: none
}

.form-group {
  margin-bottom: 1.375rem
}

.btn-group-lg > .btn, .btn-lg {
  line-height: 1.5
}

.btn-group-sm > .btn, .btn-sm {
  line-height: 1.75
}

.btn-white {
  border-color: #e3ebf6
}

.btn-white:focus, .btn-white:hover {
  background-color: #f9fbfd;
  border-color: #d2ddec
}

.btn-group-toggle .btn-check:checked + .btn-white, .btn-group-toggle .btn-white:not(:disabled):not(.disabled).active, .btn-group-toggle .btn-white:not(:disabled):not(.disabled):active {
  background-color: #1e3a5c;
  border-color: #2c7be5;
  box-shadow: none;
  color: #2c7be5
}

.btn-group-toggle .btn-white.focus, .btn-group-toggle .btn-white:focus {
  box-shadow: none
}

.btn-white-20 {
  background-color: hsla(0, 0%, 100%, .2);
  border-color: transparent;
  color: #fff
}

.btn-white-20:focus, .btn-white-20:hover {
  background-color: hsla(0, 0%, 100%, .12);
  border-color: transparent;
  color: #fff
}

.btn-outline-secondary:not(:hover):not([aria-expanded=true]):not([aria-pressed=true]) {
  border-color: #d2ddec
}

.btn-rounded-circle {
  width: calc(1.5em + 1rem + 2px);
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%
}

.btn-group-lg > .btn-rounded-circle.btn, .btn-rounded-circle.btn-lg {
  width: calc(1.5em + 1.5rem + 2px)
}

.btn-group-sm > .btn-rounded-circle.btn, .btn-rounded-circle.btn-sm {
  width: calc(1.75em + .25rem + 2px)
}

.btn-group .btn + .btn {
  margin-left: 0
}

.dropdown-toggle:after {
  width: auto;
  height: auto;
  border: none !important;
  vertical-align: middle;
  font-family: Feather;
  content: "\e92e"
}

.dropup > .dropdown-toggle:after {
  content: "\e931"
}

.dropright > .dropdown-toggle:after {
  content: "\e930"
}

.dropleft > .dropdown-toggle:before {
  content: "\e92f";
  width: auto;
  height: auto;
  border: none !important;
  vertical-align: middle;
  font-family: Feather
}

.dropdown-item.dropdown-toggle {
  display: flex;
  justify-content: space-between
}

.dropdown-menu {
  -webkit-animation: dropdownMenu .15s;
  animation: dropdownMenu .15s
}

@-webkit-keyframes dropdownMenu {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

@keyframes dropdownMenu {
  0% {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.dropdown-ellipses {
  font-size: 1.0625rem;
  color: #d2ddec
}

.dropdown-ellipses:after {
  display: none
}

.dropdown-menu-card {
  min-width: 350px;
  padding-top: 0;
  padding-bottom: 0;
  background-color: #152e4d;
  border-color: #12263f
}

.dropdown-menu-card .card-header {
  min-height: 3.125rem
}

.dropdown-menu-card .card-body {
  max-height: 350px;
  overflow-y: auto
}

.dropdown-menu-sm {
  padding-top: .25rem;
  padding-bottom: .25rem;
  font-size: .8125rem
}

.dropdown-menu-sm .dropdown-item {
  padding: .1875rem .75rem
}

.nav-tabs .nav-item {
  margin-left: .75rem;
  margin-right: .75rem
}

.nav-tabs .nav-link {
  padding: 1rem 0;
  border-bottom: 1px solid transparent;
  border-left-width: 0;
  border-right-width: 0;
  border-top-width: 0
}

.nav-tabs .nav-link:not(.active) {
  color: #95aac9
}

.nav-tabs .nav-link:not(.active):hover {
  color: #6e84a3
}

.nav-tabs .nav-item:first-child {
  margin-left: 0
}

.nav-tabs .nav-item:last-child {
  margin-right: 0
}

.nav-tabs .nav-item.show .nav-link {
  border-color: transparent
}

.nav-overflow {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 1px
}

.nav-overflow::-webkit-scrollbar {
  display: none
}

.nav-tabs-sm {
  font-size: .8125rem
}

.nav-tabs-sm .nav-item {
  margin-left: .5rem;
  margin-right: .5rem
}

.nav-tabs-sm .nav-link {
  padding-top: 1.15385rem;
  padding-bottom: 1.15385rem
}

.nav-sm .nav-link {
  font-size: .8125rem
}

.nav.btn-group .btn-white.active {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff
}

.navbar {
  border-width: 0 0 1px;
  border-style: solid
}

.navbar > [class*=container] {
  padding-left: 0;
  padding-right: 0
}

.navbar-dark {
  background-color: #fff;
  border-color: #fff
}

.navbar-dark .navbar-heading {
  color: #6e84a3
}

.navbar-dark .navbar-divider {
  background-color: #e3ebf6
}

.navbar-dark .navbar-user {
  border-top-color: #e3ebf6
}

.navbar-dark .navbar-user-link {
  color: #6e84a3
}

.navbar-dark .navbar-user-link:focus, .navbar-dark .navbar-user-link:hover {
  color: #12263f
}

.navbar-dark .navbar-brand {
  -webkit-filter: none;
  filter: none
}

.navbar-dark .navbar-collapse:before {
  border-top-color: #e3ebf6
}

.navbar-dark.navbar-expand-xs .navbar-nav .dropdown-item {
  color: #6e84a3
}

.navbar-dark.navbar-expand-xs .navbar-nav .dropdown-item.active, .navbar-dark.navbar-expand-xs .navbar-nav .dropdown-item:active, .navbar-dark.navbar-expand-xs .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-expand-xs .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-expand-xs .navbar-nav .show > .nav-link {
  color: #12263f
}

.navbar-dark.navbar-expand-sm .navbar-nav .dropdown-item {
  color: #6e84a3
}

.navbar-dark.navbar-expand-sm .navbar-nav .dropdown-item.active, .navbar-dark.navbar-expand-sm .navbar-nav .dropdown-item:active, .navbar-dark.navbar-expand-sm .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-expand-sm .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-expand-sm .navbar-nav .show > .nav-link {
  color: #12263f
}

@media (max-width: 575.98px) {
  .navbar-dark.navbar-expand-md .navbar-nav .dropdown-item {
    color: #6e84a3
  }
  .navbar-dark.navbar-expand-md .navbar-nav .dropdown-item.active, .navbar-dark.navbar-expand-md .navbar-nav .dropdown-item:active, .navbar-dark.navbar-expand-md .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-expand-md .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-expand-md .navbar-nav .show > .nav-link {
    color: #12263f
  }
}

@media (max-width: 767.98px) {
  .navbar-dark.navbar-expand-lg .navbar-nav .dropdown-item {
    color: #6e84a3
  }
  .navbar-dark.navbar-expand-lg .navbar-nav .dropdown-item.active, .navbar-dark.navbar-expand-lg .navbar-nav .dropdown-item:active, .navbar-dark.navbar-expand-lg .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-expand-lg .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-expand-lg .navbar-nav .show > .nav-link {
    color: #12263f
  }
}

@media (max-width: 991.98px) {
  .navbar-dark.navbar-expand-xl .navbar-nav .dropdown-item {
    color: #6e84a3
  }
  .navbar-dark.navbar-expand-xl .navbar-nav .dropdown-item.active, .navbar-dark.navbar-expand-xl .navbar-nav .dropdown-item:active, .navbar-dark.navbar-expand-xl .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-expand-xl .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-expand-xl .navbar-nav .show > .nav-link {
    color: #12263f
  }
}

@media (max-width: 1199.98px) {
  .navbar-dark.navbar-expand-xxl .navbar-nav .dropdown-item {
    color: #6e84a3
  }
  .navbar-dark.navbar-expand-xxl .navbar-nav .dropdown-item.active, .navbar-dark.navbar-expand-xxl .navbar-nav .dropdown-item:active, .navbar-dark.navbar-expand-xxl .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-expand-xxl .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-expand-xxl .navbar-nav .show > .nav-link {
    color: #12263f
  }
}

.navbar-dark.navbar-vibrant {
  background-image: linear-gradient(to bottom right, rgba(18, 111, 235, .9), rgba(114, 3, 225, .9)), url(../img/covers/sidebar-cover.jpg);
  background-repeat: no-repeat, no-repeat;
  background-position: 50%, 50%;
  background-size: cover, cover;
  background-origin: border-box;
  border-color: transparent
}

@media (max-width: 575.98px) {
  .navbar-dark.navbar-vibrant {
    background-attachment: fixed, fixed
  }
}

.navbar-dark.navbar-vibrant .nav-link {
  color: hsla(0, 0%, 100%, .7)
}

.navbar-dark.navbar-vibrant .nav-item.active .nav-link, .navbar-dark.navbar-vibrant .nav-item .nav-link.active, .navbar-dark.navbar-vibrant .nav-link:focus, .navbar-dark.navbar-vibrant .nav-link:hover {
  color: #fff
}

.navbar-dark.navbar-vibrant .navbar-heading {
  color: hsla(0, 0%, 100%, .4)
}

.navbar-dark.navbar-vibrant .navbar-divider {
  background-color: hsla(0, 0%, 100%, .2)
}

.navbar-dark.navbar-vibrant .navbar-user {
  border-top-color: hsla(0, 0%, 100%, .2)
}

.navbar-dark.navbar-vibrant .navbar-user-link {
  color: hsla(0, 0%, 100%, .7)
}

.navbar-dark.navbar-vibrant .navbar-user-link:focus, .navbar-dark.navbar-vibrant .navbar-user-link:hover {
  color: #fff
}

.navbar-dark.navbar-vibrant .navbar-brand {
  -webkit-filter: brightness(0) invert(1);
  filter: brightness(0) invert(1)
}

.navbar-dark.navbar-vibrant .navbar-collapse:before {
  border-top-color: hsla(0, 0%, 100%, .2)
}

.navbar-dark.navbar-vibrant .navbar-nav .nav-link.active:before {
  border-color: #fff
}

.navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .dropdown-item {
  color: hsla(0, 0%, 100%, .7)
}

.navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .dropdown-item.active, .navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .dropdown-item:active, .navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-vibrant.navbar-expand-xs .navbar-nav .show > .nav-link {
  color: #fff
}

.navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .dropdown-item {
  color: hsla(0, 0%, 100%, .7)
}

.navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .dropdown-item.active, .navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .dropdown-item:active, .navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-vibrant.navbar-expand-sm .navbar-nav .show > .nav-link {
  color: #fff
}

@media (max-width: 575.98px) {
  .navbar-dark.navbar-vibrant.navbar-expand-md .navbar-nav .dropdown-item {
    color: hsla(0, 0%, 100%, .7)
  }
  .navbar-dark.navbar-vibrant.navbar-expand-md .navbar-nav .dropdown-item.active, .navbar-dark.navbar-vibrant.navbar-expand-md .navbar-nav .dropdown-item:active, .navbar-dark.navbar-vibrant.navbar-expand-md .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-vibrant.navbar-expand-md .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-vibrant.navbar-expand-md .navbar-nav .show > .nav-link {
    color: #fff
  }
}

@media (max-width: 767.98px) {
  .navbar-dark.navbar-vibrant.navbar-expand-lg .navbar-nav .dropdown-item {
    color: hsla(0, 0%, 100%, .7)
  }
  .navbar-dark.navbar-vibrant.navbar-expand-lg .navbar-nav .dropdown-item.active, .navbar-dark.navbar-vibrant.navbar-expand-lg .navbar-nav .dropdown-item:active, .navbar-dark.navbar-vibrant.navbar-expand-lg .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-vibrant.navbar-expand-lg .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-vibrant.navbar-expand-lg .navbar-nav .show > .nav-link {
    color: #fff
  }
}

@media (max-width: 991.98px) {
  .navbar-dark.navbar-vibrant.navbar-expand-xl .navbar-nav .dropdown-item {
    color: hsla(0, 0%, 100%, .7)
  }
  .navbar-dark.navbar-vibrant.navbar-expand-xl .navbar-nav .dropdown-item.active, .navbar-dark.navbar-vibrant.navbar-expand-xl .navbar-nav .dropdown-item:active, .navbar-dark.navbar-vibrant.navbar-expand-xl .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-vibrant.navbar-expand-xl .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-vibrant.navbar-expand-xl .navbar-nav .show > .nav-link {
    color: #fff
  }
}

@media (max-width: 1199.98px) {
  .navbar-dark.navbar-vibrant.navbar-expand-xxl .navbar-nav .dropdown-item {
    color: hsla(0, 0%, 100%, .7)
  }
  .navbar-dark.navbar-vibrant.navbar-expand-xxl .navbar-nav .dropdown-item.active, .navbar-dark.navbar-vibrant.navbar-expand-xxl .navbar-nav .dropdown-item:active, .navbar-dark.navbar-vibrant.navbar-expand-xxl .navbar-nav .dropdown-item:focus, .navbar-dark.navbar-vibrant.navbar-expand-xxl .navbar-nav .dropdown-item:hover, .navbar-dark.navbar-vibrant.navbar-expand-xxl .navbar-nav .show > .nav-link {
    color: #fff
  }
}

.navbar-dark.navbar-vibrant .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255,0.7)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light {
  background-color: #152e4d;
  border-color: #152e4d
}

.navbar-light .navbar-heading {
  color: #6e84a3
}

.navbar-light .navbar-divider {
  background-color: #1e3a5c
}

.navbar-light .navbar-user {
  border-top-color: #1e3a5c
}

.navbar-light .navbar-user-link {
  color: #6e84a3
}

.navbar-light .navbar-user-link:focus, .navbar-light .navbar-user-link:hover {
  color: #fff
}

.navbar-light .navbar-brand {
  -webkit-filter: none;
  filter: none
}

.navbar-light .navbar-collapse:before {
  border-top-color: #1e3a5c
}

.navbar-brand {
  margin-right: 0
}

.navbar-brand-img, .navbar-brand > img {
  max-width: 100%;
  max-height: 1.5rem
}

.navbar-collapse {
  width: calc(100% + 2rem);
  margin-left: -1rem;
  margin-right: -1rem;
  padding-left: 1rem;
  padding-right: 1rem
}

.navbar-collapse:before {
  content: "";
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: .75rem -1rem
}

.navbar-nav {
  margin-left: -1rem;
  margin-right: -1rem
}

.navbar-nav .nav-link {
  padding-left: 1rem;
  padding-right: 1rem
}

.navbar-nav .nav-link.active {
  position: relative
}

.navbar-nav .nav-link.active:before {
  content: "";
  position: absolute;
  left: 0;
  top: .5rem;
  bottom: .5rem;
  border-left: 2px solid #2c7be5
}

.navbar-nav .nav-link > .fe {
  padding-bottom: .125em;
  font-size: 1.0625rem;
  line-height: 1.40625rem
}

.navbar-nav .dropdown-menu {
  border: none
}

.navbar-nav .dropdown-menu .dropdown-menu {
  margin-left: .75rem
}

.navbar-expand-sm .navbar-nav .dropdown-menu, .navbar-expand-xs .navbar-nav .dropdown-menu {
  background-color: transparent
}

@media (max-width: 575.98px) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    background-color: transparent
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    background-color: transparent
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    background-color: transparent
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    background-color: transparent
  }
}

.navbar-expand-xs, .navbar-expand-xs > [class*=container] {
  padding-left: 0;
  padding-right: 0
}

.navbar-expand-xs .navbar-collapse {
  flex-grow: inherit;
  flex-basis: auto;
  width: auto
}

.navbar-expand-xs .navbar-collapse:before {
  display: none
}

.navbar-expand-xs .navbar-nav {
  margin-left: 0;
  margin-right: 0
}

.navbar-expand-xs .navbar-nav .nav-link {
  padding: .625rem .5rem
}

.navbar-expand-xs .navbar-nav .nav-link.active {
  position: relative
}

.navbar-expand-xs .navbar-nav .nav-link.active:before {
  top: auto;
  bottom: calc(-.75rem - 1px);
  right: .5rem;
  left: .5rem;
  border-left: 0;
  border-bottom: 1px solid #2c7be5
}

.navbar-expand-xs .dropdown:hover > .dropdown-menu, .navbar-expand-xs .dropleft:hover > .dropdown-menu, .navbar-expand-xs .dropright:hover > .dropdown-menu, .navbar-expand-xs .dropup:hover > .dropdown-menu {
  display: block
}

.navbar-expand-xs .navbar-nav .dropdown-menu {
  border: 1px solid #12263f
}

.navbar-expand-xs .navbar-nav .dropdown-menu .dropdown-menu {
  margin-left: 0
}

@media (min-width: 576px) {
  .navbar-expand-sm, .navbar-expand-sm > [class*=container] {
    padding-left: 0;
    padding-right: 0
  }
  .navbar-expand-sm .navbar-collapse {
    flex-grow: inherit;
    flex-basis: auto;
    width: auto
  }
  .navbar-expand-sm .navbar-collapse:before {
    display: none
  }
  .navbar-expand-sm .navbar-nav {
    margin-left: 0;
    margin-right: 0
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding: .625rem .5rem
  }
  .navbar-expand-sm .navbar-nav .nav-link.active {
    position: relative
  }
  .navbar-expand-sm .navbar-nav .nav-link.active:before {
    top: auto;
    bottom: calc(-.75rem - 1px);
    right: .5rem;
    left: .5rem;
    border-left: 0;
    border-bottom: 1px solid #2c7be5
  }
  .navbar-expand-sm .dropdown:hover > .dropdown-menu, .navbar-expand-sm .dropleft:hover > .dropdown-menu, .navbar-expand-sm .dropright:hover > .dropdown-menu, .navbar-expand-sm .dropup:hover > .dropdown-menu {
    display: block
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    border: 1px solid #12263f
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0
  }
}

@media (min-width: 768px) {
  .navbar-expand-md, .navbar-expand-md > [class*=container] {
    padding-left: 0;
    padding-right: 0
  }
  .navbar-expand-md .navbar-collapse {
    flex-grow: inherit;
    flex-basis: auto;
    width: auto
  }
  .navbar-expand-md .navbar-collapse:before {
    display: none
  }
  .navbar-expand-md .navbar-nav {
    margin-left: 0;
    margin-right: 0
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding: .625rem .5rem
  }
  .navbar-expand-md .navbar-nav .nav-link.active {
    position: relative
  }
  .navbar-expand-md .navbar-nav .nav-link.active:before {
    top: auto;
    bottom: calc(-.75rem - 1px);
    right: .5rem;
    left: .5rem;
    border-left: 0;
    border-bottom: 1px solid #2c7be5
  }
  .navbar-expand-md .dropdown:hover > .dropdown-menu, .navbar-expand-md .dropleft:hover > .dropdown-menu, .navbar-expand-md .dropright:hover > .dropdown-menu, .navbar-expand-md .dropup:hover > .dropdown-menu {
    display: block
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    border: 1px solid #12263f
  }
  .navbar-expand-md .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg, .navbar-expand-lg > [class*=container] {
    padding-left: 0;
    padding-right: 0
  }
  .navbar-expand-lg .navbar-collapse {
    flex-grow: inherit;
    flex-basis: auto;
    width: auto
  }
  .navbar-expand-lg .navbar-collapse:before {
    display: none
  }
  .navbar-expand-lg .navbar-nav {
    margin-left: 0;
    margin-right: 0
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding: .625rem .5rem
  }
  .navbar-expand-lg .navbar-nav .nav-link.active {
    position: relative
  }
  .navbar-expand-lg .navbar-nav .nav-link.active:before {
    top: auto;
    bottom: calc(-.75rem - 1px);
    right: .5rem;
    left: .5rem;
    border-left: 0;
    border-bottom: 1px solid #2c7be5
  }
  .navbar-expand-lg .dropdown:hover > .dropdown-menu, .navbar-expand-lg .dropleft:hover > .dropdown-menu, .navbar-expand-lg .dropright:hover > .dropdown-menu, .navbar-expand-lg .dropup:hover > .dropdown-menu {
    display: block
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    border: 1px solid #12263f
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl, .navbar-expand-xl > [class*=container] {
    padding-left: 0;
    padding-right: 0
  }
  .navbar-expand-xl .navbar-collapse {
    flex-grow: inherit;
    flex-basis: auto;
    width: auto
  }
  .navbar-expand-xl .navbar-collapse:before {
    display: none
  }
  .navbar-expand-xl .navbar-nav {
    margin-left: 0;
    margin-right: 0
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding: .625rem .5rem
  }
  .navbar-expand-xl .navbar-nav .nav-link.active {
    position: relative
  }
  .navbar-expand-xl .navbar-nav .nav-link.active:before {
    top: auto;
    bottom: calc(-.75rem - 1px);
    right: .5rem;
    left: .5rem;
    border-left: 0;
    border-bottom: 1px solid #2c7be5
  }
  .navbar-expand-xl .dropdown:hover > .dropdown-menu, .navbar-expand-xl .dropleft:hover > .dropdown-menu, .navbar-expand-xl .dropright:hover > .dropdown-menu, .navbar-expand-xl .dropup:hover > .dropdown-menu {
    display: block
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    border: 1px solid #12263f
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0
  }
}

@media (min-width: 1400px) {
  .navbar-expand-xxl, .navbar-expand-xxl > [class*=container] {
    padding-left: 0;
    padding-right: 0
  }
  .navbar-expand-xxl .navbar-collapse {
    flex-grow: inherit;
    flex-basis: auto;
    width: auto
  }
  .navbar-expand-xxl .navbar-collapse:before {
    display: none
  }
  .navbar-expand-xxl .navbar-nav {
    margin-left: 0;
    margin-right: 0
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding: .625rem .5rem
  }
  .navbar-expand-xxl .navbar-nav .nav-link.active {
    position: relative
  }
  .navbar-expand-xxl .navbar-nav .nav-link.active:before {
    top: auto;
    bottom: calc(-.75rem - 1px);
    right: .5rem;
    left: .5rem;
    border-left: 0;
    border-bottom: 1px solid #2c7be5
  }
  .navbar-expand-xxl .dropdown:hover > .dropdown-menu, .navbar-expand-xxl .dropleft:hover > .dropdown-menu, .navbar-expand-xxl .dropright:hover > .dropdown-menu, .navbar-expand-xxl .dropup:hover > .dropdown-menu {
    display: block
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    border: 1px solid #12263f
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu .dropdown-menu {
    margin-left: 0
  }
}

.navbar[class*=fixed-] {
  z-index: 1030
}

.navbar-overflow {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: -.75rem;
  overflow-x: auto
}

.navbar-overflow::-webkit-scrollbar {
  display: none
}

.navbar-overflow .navbar-nav {
  flex-direction: row;
  flex-wrap: nowrap;
  margin-left: -.5rem;
  margin-right: -.5rem
}

.navbar-overflow .navbar-nav .nav-link {
  padding-bottom: 1.25rem
}

.navbar-overflow .navbar-nav .nav-link.active:before {
  bottom: 0
}

.navbar-breadcrumb {
  padding: .5rem 0
}

.navbar-user {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around
}

.navbar-user .dropdown-toggle:after {
  display: none
}

.navbar-nav .nav-link > .fe {
  display: inline-block;
  min-width: 1.75rem
}

.navbar-vertical .navbar-nav .nav-link {
  display: flex;
  align-items: center
}

.navbar-vertical .navbar-nav .nav-link[data-toggle=collapse]:after {
  display: block;
  content: "\e92e";
  font-family: Feather;
  margin-left: auto;
  transition: transform .2s
}

.navbar-vertical .navbar-nav .nav-link[data-toggle=collapse][aria-expanded=true]:after {
  transform: rotate(-180deg)
}

.navbar-vertical .navbar-nav .nav .nav-link {
  padding-left: 2.75rem
}

.navbar-vertical .navbar-nav .nav .nav .nav-link {
  padding-left: 3.25rem
}

.navbar-vertical .navbar-nav .nav .nav .nav .nav-link {
  padding-left: 3.75rem
}

.navbar-vertical .navbar-heading {
  padding-top: .5rem;
  padding-bottom: .5rem;
  font-size: .625rem;
  text-transform: uppercase;
  letter-spacing: .04em
}

.navbar-vertical.navbar-expand-xs {
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  max-width: 290px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  overflow-y: scroll;
}

.navbar-vertical.navbar-expand-xs::-webkit-scrollbar {
  width: 10px;
}

.navbar-vertical.navbar-expand-xs::-webkit-scrollbar-track {
  background: transparent;
}

.navbar-vertical.navbar-expand-xs::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-vertical.navbar-expand-xs > [class*=container] {
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  padding-left: 0;
  padding-right: 0
}

@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-xs > [class*=container] {
    min-height: none;
    height: 100%
  }
}

.navbar-vertical.navbar-expand-xs.fixed-left {
  left: 0;
  border-width: 0 1px 0 0
}

.navbar-vertical.navbar-expand-xs.fixed-right {
  right: 0;
  border-width: 0 0 0 1px
}

.navbar-vertical.navbar-expand-xs .navbar-collapse {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: auto;
  min-width: 100%;
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem
}

.navbar-vertical.navbar-expand-xs .navbar-collapse > * {
  min-width: 100%
}

.navbar-vertical.navbar-expand-xs .navbar-nav {
  flex-direction: column;
  margin-left: -1.5rem;
  margin-right: -1.5rem
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link {
  padding: .5rem 1.5rem
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link.active:before {
  top: .5rem;
  bottom: .5rem;
  left: 0;
  right: auto;
  border-left: 2px solid #2c7be5;
  border-bottom: 0
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav-link {
  padding-left: 3.25rem
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav .nav-link {
  padding-left: 4rem
}

.navbar-vertical.navbar-expand-xs .navbar-nav .nav .nav .nav .nav-link {
  padding-left: 4.75rem
}

.navbar-vertical.navbar-expand-xs .navbar-brand {
  display: block;
  text-align: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem
}

.navbar-vertical.navbar-expand-xs .navbar-brand-img {
  max-height: 3rem
}

.navbar-vertical.navbar-expand-xs .navbar-user {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
  padding: 1.5rem 1.5rem .75rem;
  border-top-width: 1px;
  border-top-style: solid
}

.navbar-vertical.navbar-expand-xs .navbar-user .dropup .dropdown-menu {
  left: 50%;
  transform: translateX(-50%)
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 290px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-y: scroll;
  }

  .navbar-vertical.navbar-expand-sm::-webkit-scrollbar {
    width: 10px;
  }

  .navbar-vertical.navbar-expand-sm::-webkit-scrollbar-track {
    background: transparent;
  }

  .navbar-vertical.navbar-expand-sm::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .navbar-vertical.navbar-expand-sm > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0
  }
}

@media (min-width: 576px) and (-ms-high-contrast: active), (min-width: 576px) and (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-sm > [class*=container] {
    min-height: none;
    height: 100%
  }
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.fixed-left {
    left: 0;
    border-width: 0 1px 0 0
  }
  .navbar-vertical.navbar-expand-sm.fixed-right {
    right: 0;
    border-width: 0 0 0 1px
  }
  .navbar-vertical.navbar-expand-sm .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: auto;
    min-width: 100%;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
  .navbar-vertical.navbar-expand-sm .navbar-collapse > * {
    min-width: 100%
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link {
    padding: .5rem 1.5rem
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav-link.active:before {
    top: .5rem;
    bottom: .5rem;
    left: 0;
    right: auto;
    border-left: 2px solid #2c7be5;
    border-bottom: 0
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav-link {
    padding-left: 3.25rem
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem
  }
  .navbar-vertical.navbar-expand-sm .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 4.75rem
  }
  .navbar-vertical.navbar-expand-sm .navbar-brand {
    display: block;
    text-align: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }
  .navbar-vertical.navbar-expand-sm .navbar-brand-img {
    max-height: 3rem
  }
  .navbar-vertical.navbar-expand-sm .navbar-user {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem .75rem;
    border-top-width: 1px;
    border-top-style: solid
  }
  .navbar-vertical.navbar-expand-sm .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%)
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 290px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-y: scroll;
  }

  .navbar-vertical.navbar-expand-md::-webkit-scrollbar {
    width: 10px;
  }

  .navbar-vertical.navbar-expand-md::-webkit-scrollbar-track {
    background: transparent;
  }

  .navbar-vertical.navbar-expand-md::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .navbar-vertical.navbar-expand-md > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0
  }
}

@media (min-width: 768px) and (-ms-high-contrast: active), (min-width: 768px) and (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-md > [class*=container] {
    min-height: none;
    height: 100%
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.fixed-left {
    left: 0;
    border-width: 0 1px 0 0
  }
  .navbar-vertical.navbar-expand-md.fixed-right {
    right: 0;
    border-width: 0 0 0 1px
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: auto;
    min-width: 100%;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
  .navbar-vertical.navbar-expand-md .navbar-collapse > * {
    min-width: 100%
  }
  .navbar-vertical.navbar-expand-md .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
    padding: .5rem 1.5rem
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
    top: .5rem;
    bottom: .5rem;
    left: 0;
    right: auto;
    border-left: 2px solid #2c7be5;
    border-bottom: 0
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav-link {
    padding-left: 3.25rem
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem
  }
  .navbar-vertical.navbar-expand-md .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 4.75rem
  }
  .navbar-vertical.navbar-expand-md .navbar-brand {
    display: block;
    text-align: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }
  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: 3rem
  }
  .navbar-vertical.navbar-expand-md .navbar-user {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem .75rem;
    border-top-width: 1px;
    border-top-style: solid
  }
  .navbar-vertical.navbar-expand-md .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%)
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 290px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-y: scroll;
  }

  .navbar-vertical.navbar-expand-lg::-webkit-scrollbar {
    width: 10px;
  }

  .navbar-vertical.navbar-expand-lg::-webkit-scrollbar-track {
    background: transparent;
  }

  .navbar-vertical.navbar-expand-lg::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .navbar-vertical.navbar-expand-lg > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0
  }
}

@media (min-width: 992px) and (-ms-high-contrast: active), (min-width: 992px) and (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-lg > [class*=container] {
    min-height: none;
    height: 100%
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.fixed-left {
    left: 0;
    border-width: 0 1px 0 0
  }
  .navbar-vertical.navbar-expand-lg.fixed-right {
    right: 0;
    border-width: 0 0 0 1px
  }
  .navbar-vertical.navbar-expand-lg .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: auto;
    min-width: 100%;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
  .navbar-vertical.navbar-expand-lg .navbar-collapse > * {
    min-width: 100%
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
    padding: .5rem 1.5rem
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active:before {
    top: .5rem;
    bottom: .5rem;
    left: 0;
    right: auto;
    border-left: 2px solid #2c7be5;
    border-bottom: 0
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav-link {
    padding-left: 3.25rem
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem
  }
  .navbar-vertical.navbar-expand-lg .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 4.75rem
  }
  .navbar-vertical.navbar-expand-lg .navbar-brand {
    display: block;
    text-align: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }
  .navbar-vertical.navbar-expand-lg .navbar-brand-img {
    max-height: 3rem
  }
  .navbar-vertical.navbar-expand-lg .navbar-user {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem .75rem;
    border-top-width: 1px;
    border-top-style: solid
  }
  .navbar-vertical.navbar-expand-lg .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%)
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 290px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-y: scroll;
  }

  .navbar-vertical.navbar-expand-xl::-webkit-scrollbar {
    width: 10px;
  }

  .navbar-vertical.navbar-expand-xl::-webkit-scrollbar-track {
    background: transparent;
  }

  .navbar-vertical.navbar-expand-xl::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .navbar-vertical.navbar-expand-xl > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0
  }
}

@media (min-width: 1200px) and (-ms-high-contrast: active), (min-width: 1200px) and (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-xl > [class*=container] {
    min-height: none;
    height: 100%
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.fixed-left {
    left: 0;
    border-width: 0 1px 0 0
  }
  .navbar-vertical.navbar-expand-xl.fixed-right {
    right: 0;
    border-width: 0 0 0 1px
  }
  .navbar-vertical.navbar-expand-xl .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: auto;
    min-width: 100%;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
  .navbar-vertical.navbar-expand-xl .navbar-collapse > * {
    min-width: 100%
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link {
    padding: .5rem 1.5rem
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav-link.active:before {
    top: .5rem;
    bottom: .5rem;
    left: 0;
    right: auto;
    border-left: 2px solid #2c7be5;
    border-bottom: 0
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav-link {
    padding-left: 3.25rem
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem
  }
  .navbar-vertical.navbar-expand-xl .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 4.75rem
  }
  .navbar-vertical.navbar-expand-xl .navbar-brand {
    display: block;
    text-align: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }
  .navbar-vertical.navbar-expand-xl .navbar-brand-img {
    max-height: 3rem
  }
  .navbar-vertical.navbar-expand-xl .navbar-user {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem .75rem;
    border-top-width: 1px;
    border-top-style: solid
  }
  .navbar-vertical.navbar-expand-xl .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%)
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    max-width: 290px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    overflow-y: scroll;
  }

  .navbar-vertical.navbar-expand-xxl::-webkit-scrollbar {
    width: 10px;
  }

  .navbar-vertical.navbar-expand-xxl::-webkit-scrollbar-track {
    background: transparent;
  }

  .navbar-vertical.navbar-expand-xxl::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }

  .navbar-vertical.navbar-expand-xxl > [class*=container] {
    flex-direction: column;
    align-items: stretch;
    min-height: 100%;
    padding-left: 0;
    padding-right: 0
  }
}

@media (min-width: 1400px) and (-ms-high-contrast: active), (min-width: 1400px) and (-ms-high-contrast: none) {
  .navbar-vertical.navbar-expand-xxl > [class*=container] {
    min-height: none;
    height: 100%
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.fixed-left {
    left: 0;
    border-width: 0 1px 0 0
  }
  .navbar-vertical.navbar-expand-xxl.fixed-right {
    right: 0;
    border-width: 0 0 0 1px
  }
  .navbar-vertical.navbar-expand-xxl .navbar-collapse {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: auto;
    min-width: 100%;
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem
  }
  .navbar-vertical.navbar-expand-xxl .navbar-collapse > * {
    min-width: 100%
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav {
    flex-direction: column;
    margin-left: -1.5rem;
    margin-right: -1.5rem
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link {
    padding: .5rem 1.5rem
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav-link.active:before {
    top: .5rem;
    bottom: .5rem;
    left: 0;
    right: auto;
    border-left: 2px solid #2c7be5;
    border-bottom: 0
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav .nav-link {
    padding-left: 3.25rem
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav .nav .nav-link {
    padding-left: 4rem
  }
  .navbar-vertical.navbar-expand-xxl .navbar-nav .nav .nav .nav .nav-link {
    padding-left: 4.75rem
  }
  .navbar-vertical.navbar-expand-xxl .navbar-brand {
    display: block;
    text-align: center;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem
  }
  .navbar-vertical.navbar-expand-xxl .navbar-brand-img {
    max-height: 3rem
  }
  .navbar-vertical.navbar-expand-xxl .navbar-user {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    padding: 1.5rem 1.5rem .75rem;
    border-top-width: 1px;
    border-top-style: solid
  }
  .navbar-vertical.navbar-expand-xxl .navbar-user .dropup .dropdown-menu {
    left: 50%;
    transform: translateX(-50%)
  }
}

.navbar-vertical-sm.navbar-expand-xs {
  max-width: 66px;
  padding-left: .75rem;
  padding-right: .75rem;
  overflow: visible
}

.navbar-vertical-sm.navbar-expand-xs .navbar-brand {
  padding-top: .45313rem;
  padding-bottom: 1.20313rem
}

.navbar-vertical-sm.navbar-expand-xs .navbar-brand-img {
  width: auto;
  max-height: 1.5rem
}

.navbar-vertical-sm.navbar-expand-xs .navbar-collapse {
  margin-left: -.75rem;
  margin-right: -.75rem;
  padding-left: .75rem;
  padding-right: .75rem
}

.navbar-vertical-sm.navbar-expand-xs .navbar-nav {
  margin-left: -.75rem;
  margin-right: -.75rem
}

.navbar-vertical-sm.navbar-expand-xs .navbar-nav > .nav-item > .nav-link {
  justify-content: center;
  padding-left: .75rem;
  padding-right: .75rem;
  text-align: center
}

.navbar-vertical-sm.navbar-expand-xs .navbar-nav > .nav-item > .dropdown-toggle:after {
  display: none
}

.navbar-vertical-sm.navbar-expand-xs .navbar-user {
  margin-left: -.75rem;
  margin-right: -.75rem;
  padding-left: .75rem;
  padding-right: .75rem
}

.navbar-vertical-sm.navbar-expand-xs .navbar-user .dropright .dropdown-menu {
  top: auto;
  bottom: 0
}

@media (min-width: 576px) {
  .navbar-vertical-sm.navbar-expand-sm {
    max-width: 66px;
    padding-left: .75rem;
    padding-right: .75rem;
    overflow: visible
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-brand {
    padding-top: .45313rem;
    padding-bottom: 1.20313rem
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-brand-img {
    width: auto;
    max-height: 1.5rem
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-collapse {
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding-left: .75rem;
    padding-right: .75rem
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-nav {
    margin-left: -.75rem;
    margin-right: -.75rem
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-nav > .nav-item > .nav-link {
    justify-content: center;
    padding-left: .75rem;
    padding-right: .75rem;
    text-align: center
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-nav > .nav-item > .dropdown-toggle:after {
    display: none
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-user {
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding-left: .75rem;
    padding-right: .75rem
  }
  .navbar-vertical-sm.navbar-expand-sm .navbar-user .dropright .dropdown-menu {
    top: auto;
    bottom: 0
  }
}

@media (min-width: 768px) {
  .navbar-vertical-sm.navbar-expand-md {
    max-width: 66px;
    padding-left: .75rem;
    padding-right: .75rem;
    overflow: visible
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-brand {
    padding-top: .45313rem;
    padding-bottom: 1.20313rem
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-brand-img {
    width: auto;
    max-height: 1.5rem
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-collapse {
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding-left: .75rem;
    padding-right: .75rem
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-nav {
    margin-left: -.75rem;
    margin-right: -.75rem
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-nav > .nav-item > .nav-link {
    justify-content: center;
    padding-left: .75rem;
    padding-right: .75rem;
    text-align: center
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-nav > .nav-item > .dropdown-toggle:after {
    display: none
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-user {
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding-left: .75rem;
    padding-right: .75rem
  }
  .navbar-vertical-sm.navbar-expand-md .navbar-user .dropright .dropdown-menu {
    top: auto;
    bottom: 0
  }
}

@media (min-width: 992px) {
  .navbar-vertical-sm.navbar-expand-lg {
    max-width: 66px;
    padding-left: .75rem;
    padding-right: .75rem;
    overflow: visible
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-brand {
    padding-top: .45313rem;
    padding-bottom: 1.20313rem
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-brand-img {
    width: auto;
    max-height: 1.5rem
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-collapse {
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding-left: .75rem;
    padding-right: .75rem
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-nav {
    margin-left: -.75rem;
    margin-right: -.75rem
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-nav > .nav-item > .nav-link {
    justify-content: center;
    padding-left: .75rem;
    padding-right: .75rem;
    text-align: center
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-nav > .nav-item > .dropdown-toggle:after {
    display: none
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-user {
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding-left: .75rem;
    padding-right: .75rem
  }
  .navbar-vertical-sm.navbar-expand-lg .navbar-user .dropright .dropdown-menu {
    top: auto;
    bottom: 0
  }
}

@media (min-width: 1200px) {
  .navbar-vertical-sm.navbar-expand-xl {
    max-width: 66px;
    padding-left: .75rem;
    padding-right: .75rem;
    overflow: visible
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-brand {
    padding-top: .45313rem;
    padding-bottom: 1.20313rem
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-brand-img {
    width: auto;
    max-height: 1.5rem
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-collapse {
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding-left: .75rem;
    padding-right: .75rem
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-nav {
    margin-left: -.75rem;
    margin-right: -.75rem
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-nav > .nav-item > .nav-link {
    justify-content: center;
    padding-left: .75rem;
    padding-right: .75rem;
    text-align: center
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-nav > .nav-item > .dropdown-toggle:after {
    display: none
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-user {
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding-left: .75rem;
    padding-right: .75rem
  }
  .navbar-vertical-sm.navbar-expand-xl .navbar-user .dropright .dropdown-menu {
    top: auto;
    bottom: 0
  }
}

@media (min-width: 1400px) {
  .navbar-vertical-sm.navbar-expand-xxl {
    max-width: 66px;
    padding-left: .75rem;
    padding-right: .75rem;
    overflow: visible
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-brand {
    padding-top: .45313rem;
    padding-bottom: 1.20313rem
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-brand-img {
    width: auto;
    max-height: 1.5rem
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-collapse {
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding-left: .75rem;
    padding-right: .75rem
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-nav {
    margin-left: -.75rem;
    margin-right: -.75rem
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-nav > .nav-item > .nav-link {
    justify-content: center;
    padding-left: .75rem;
    padding-right: .75rem;
    text-align: center
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-nav > .nav-item > .dropdown-toggle:after {
    display: none
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-user {
    margin-left: -.75rem;
    margin-right: -.75rem;
    padding-left: .75rem;
    padding-right: .75rem
  }
  .navbar-vertical-sm.navbar-expand-xxl .navbar-user .dropright .dropdown-menu {
    top: auto;
    bottom: 0
  }
}

.card {
  margin-bottom: 1.5rem;
  border-color: #152e4d;
  box-shadow: 0 .75rem 1.5rem rgba(18, 38, 63, .5)
}

.card > * {
  flex-shrink: 0
}

.card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 60px;
  padding-top: .75rem;
  padding-bottom: .75rem
}

.card-header > :first-child {
  flex: 1
}

.card-header + .card-footer {
  border-top-width: 0
}

.card-header-title {
  margin-bottom: 0
}

.card-header-tabs {
  margin: -.75rem 0
}

.card-header-tabs .nav-link {
  padding-top: calc((60px - 1.5em) / 2) !important;
  padding-bottom: calc((60px - 1.5em) / 2) !important
}

.card-header-tabs .nav-link.active {
  border-bottom-color: #2c7be5
}

.card-table {
  margin-bottom: 0
}

.card-table thead th {
  border-top-width: 0
}

.card-table tbody td:first-child, .card-table thead th:first-child {
  padding-left: 1.5rem !important
}

.card-table tbody td:last-child, .card-table thead th:last-child {
  padding-right: 1.5rem !important
}

.card > .card-table:first-child > tbody:first-child > tr:first-child > td:first-child, .card > .card-table:first-child > tbody:first-child > tr:first-child > th:first-child, .card > .card-table:first-child > tfoot:first-child > tr:first-child > td:first-child, .card > .card-table:first-child > tfoot:first-child > tr:first-child > th:first-child, .card > .card-table:first-child > thead:first-child > tr:first-child > td:first-child, .card > .card-table:first-child > thead:first-child > tr:first-child > th:first-child, .card > .table-responsive:first-child > .card-table > tbody:first-child > tr:first-child > td:first-child, .card > .table-responsive:first-child > .card-table > tbody:first-child > tr:first-child > th:first-child, .card > .table-responsive:first-child > .card-table > tfoot:first-child > tr:first-child > td:first-child, .card > .table-responsive:first-child > .card-table > tfoot:first-child > tr:first-child > th:first-child, .card > .table-responsive:first-child > .card-table > thead:first-child > tr:first-child > td:first-child, .card > .table-responsive:first-child > .card-table > thead:first-child > tr:first-child > th:first-child {
  border-top-left-radius: .5rem
}

.card > .card-table:first-child > tbody:first-child > tr:first-child > td:last-child, .card > .card-table:first-child > tbody:first-child > tr:first-child > th:last-child, .card > .card-table:first-child > tfoot:first-child > tr:first-child > td:last-child, .card > .card-table:first-child > tfoot:first-child > tr:first-child > th:last-child, .card > .card-table:first-child > thead:first-child > tr:first-child > td:last-child, .card > .card-table:first-child > thead:first-child > tr:first-child > th:last-child, .card > .table-responsive:first-child > .card-table > tbody:first-child > tr:first-child > td:last-child, .card > .table-responsive:first-child > .card-table > tbody:first-child > tr:first-child > th:last-child, .card > .table-responsive:first-child > .card-table > tfoot:first-child > tr:first-child > td:last-child, .card > .table-responsive:first-child > .card-table > tfoot:first-child > tr:first-child > th:last-child, .card > .table-responsive:first-child > .card-table > thead:first-child > tr:first-child > td:last-child, .card > .table-responsive:first-child > .card-table > thead:first-child > tr:first-child > th:last-child {
  border-top-right-radius: .5rem
}

.card-avatar {
  display: block !important;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1rem
}

.card-avatar-top {
  margin-top: -3rem
}

.card-avatar-top.avatar-xs {
  margin-top: -2.3125rem
}

.card-avatar-top.avatar-sm {
  margin-top: -2.75rem
}

.card-avatar-top.avatar-lg {
  margin-top: -3.5rem
}

.card-avatar-top.avatar-xl {
  margin-top: -4.0625rem
}

.card-avatar-top.avatar-xxl {
  margin-top: -5.5rem
}

.card-dropdown {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem
}

.card-inactive {
  border-color: #1e3a5c;
  border-style: dashed;
  background-color: transparent;
  box-shadow: none
}

.card-flush {
  background: none;
  border: none;
  box-shadow: none
}

.card-sm .card-body, .card-sm .card-footer-boxed {
  padding: 1rem
}

.card-header-flush {
  border-bottom: 0
}

.card-header-flush + .card-body {
  padding-top: 0
}

.card-fill {
  height: calc(100% - 1.5rem)
}

.card-fill .card-body {
  flex-grow: 0;
  margin-top: auto;
  margin-bottom: auto
}

.card-fill-xs {
  height: calc(100% - 1.5rem)
}

.card-fill-xs .card-body {
  flex-grow: 0;
  margin-top: auto;
  margin-bottom: auto
}

@media (min-width: 576px) {
  .card-fill-sm {
    height: calc(100% - 1.5rem)
  }
  .card-fill-sm .card-body {
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto
  }
}

@media (min-width: 768px) {
  .card-fill-md {
    height: calc(100% - 1.5rem)
  }
  .card-fill-md .card-body {
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto
  }
}

@media (min-width: 992px) {
  .card-fill-lg {
    height: calc(100% - 1.5rem)
  }
  .card-fill-lg .card-body {
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto
  }
}

@media (min-width: 1200px) {
  .card-fill-xl {
    height: calc(100% - 1.5rem)
  }
  .card-fill-xl .card-body {
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto
  }
}

@media (min-width: 1400px) {
  .card-fill-xxl {
    height: calc(100% - 1.5rem)
  }
  .card-fill-xxl .card-body {
    flex-grow: 0;
    margin-top: auto;
    margin-bottom: auto
  }
}

.card-adjust {
  height: 0;
  min-height: 100%
}

.card-adjust .card {
  height: calc(100% - 1.5rem)
}

.card-adjust .card-body {
  height: calc(100% - 60px);
  overflow-y: auto
}

.card-adjust-xs {
  height: 0;
  min-height: 100%
}

.card-adjust-xs .card {
  height: calc(100% - 1.5rem)
}

.card-adjust-xs .card-body {
  height: calc(100% - 60px);
  overflow-y: auto
}

@media (min-width: 576px) {
  .card-adjust-sm {
    height: 0;
    min-height: 100%
  }
  .card-adjust-sm .card {
    height: calc(100% - 1.5rem)
  }
  .card-adjust-sm .card-body {
    height: calc(100% - 60px);
    overflow-y: auto
  }
}

@media (min-width: 768px) {
  .card-adjust-md {
    height: 0;
    min-height: 100%
  }
  .card-adjust-md .card {
    height: calc(100% - 1.5rem)
  }
  .card-adjust-md .card-body {
    height: calc(100% - 60px);
    overflow-y: auto
  }
}

@media (min-width: 992px) {
  .card-adjust-lg {
    height: 0;
    min-height: 100%
  }
  .card-adjust-lg .card {
    height: calc(100% - 1.5rem)
  }
  .card-adjust-lg .card-body {
    height: calc(100% - 60px);
    overflow-y: auto
  }
}

@media (min-width: 1200px) {
  .card-adjust-xl {
    height: 0;
    min-height: 100%
  }
  .card-adjust-xl .card {
    height: calc(100% - 1.5rem)
  }
  .card-adjust-xl .card-body {
    height: calc(100% - 60px);
    overflow-y: auto
  }
}

@media (min-width: 1400px) {
  .card-adjust-xxl {
    height: 0;
    min-height: 100%
  }
  .card-adjust-xxl .card {
    height: calc(100% - 1.5rem)
  }
  .card-adjust-xxl .card-body {
    height: calc(100% - 60px);
    overflow-y: auto
  }
}

.card-footer-boxed {
  padding-right: 0;
  padding-bottom: 1.5rem;
  padding-left: 0;
  margin-left: 1.5rem;
  margin-right: 1.5rem
}

.card-pagination.pagination-tabs {
  margin-top: -1rem;
  margin-bottom: -1rem;
  border: 0
}

.card-pagination.pagination-tabs .page, .card-pagination.pagination-tabs .page-link {
  border-top-width: 0 !important
}

.card-pagination.pagination-tabs .active .page, .card-pagination.pagination-tabs .active .page-link {
  border-width: 0 0 1px !important
}

.card-divider {
  border-color: #1e3a5c
}

.breadcrumb-item + .breadcrumb-item:before {
  content: "\e930";
  align-self: center;
  font-size: .8rem;
  font-family: Feather;
  color: #d2ddec
}

.breadcrumb-sm {
  font-size: .8125rem
}

.breadcrumb-overflow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: auto
}

.breadcrumb-overflow::-webkit-scrollbar {
  display: none
}

.breadcrumb-overflow .breadcrumb-item {
  white-space: nowrap
}

.pagination .page, .pagination .page-link {
  line-height: 1.25;
  font-size: .9375rem
}

.pagination-lg .page, .pagination-lg .page-link {
  line-height: 1.5;
  font-size: .9375rem
}

.pagination-sm .page, .pagination-sm .page-link {
  line-height: 1.75;
  font-size: .8125rem
}

.pagination-tabs {
  border-radius: 0;
  border-top: 1px solid #1e3a5c
}

.pagination-tabs .page, .pagination-tabs .page-link {
  margin-top: -1px;
  padding: 1.25rem .75rem;
  background-color: transparent;
  border-width: 1px 0 0;
  border-radius: 0 !important;
  color: #6e84a3
}

.pagination-tabs .page-link:hover, .pagination-tabs .page:hover {
  color: #fff
}

.pagination-tabs .active .page, .pagination-tabs .active .page-link {
  background-color: transparent;
  border-color: #2c7be5;
  color: #fff
}

.pagination-overflow {
  flex-wrap: nowrap;
  overflow-x: auto
}

.pagination-overflow::-webkit-scrollbar {
  display: none
}

.pagination-overflow .page, .pagination-overflow .page-link {
  white-space: nowrap
}

.badge {
  vertical-align: middle
}

.btn .badge {
  top: -2px
}

.badge.rounded-pill {
  padding-right: .6em;
  padding-left: .6em
}

.badge.bg-info, .badge.bg-primary, .badge.bg-secondary, .badge.bg-success {
  color: #fff
}

.badge.bg-warning {
  color: #283e59
}

.badge.bg-danger, .badge.bg-dark, .badge.bg-light {
  color: #fff
}

.badge.bg-white {
  color: #283e59
}

.badge.bg-soft-primary {
  color: #2c7be5;
  background-color: #173760
}

.badge.bg-soft-primary[href]:focus, .badge.bg-soft-primary[href]:hover {
  color: #2c7be5;
  text-decoration: none;
  background-color: #122b4b
}

.badge.bg-soft-secondary {
  color: #6e84a3;
  background-color: #243953
}

.badge.bg-soft-secondary[href]:focus, .badge.bg-soft-secondary[href]:hover {
  color: #6e84a3;
  text-decoration: none;
  background-color: #1c2d41
}

.badge.bg-soft-success {
  color: #00d97e;
  background-color: #0e4a4c
}

.badge.bg-soft-success[href]:focus, .badge.bg-soft-success[href]:hover {
  color: #00d97e;
  text-decoration: none;
  background-color: #0a3536
}

.badge.bg-soft-info {
  color: #39afd1;
  background-color: #1a415c
}

.badge.bg-soft-info[href]:focus, .badge.bg-soft-info[href]:hover {
  color: #39afd1;
  text-decoration: none;
  background-color: #143348
}

.badge.bg-soft-warning {
  color: #f6c343;
  background-color: #404540
}

.badge.bg-soft-warning[href]:focus, .badge.bg-soft-warning[href]:hover {
  color: #f6c343;
  text-decoration: none;
  background-color: #343834
}

.badge.bg-soft-danger {
  color: #e63757;
  background-color: #3c2944
}

.badge.bg-soft-danger[href]:focus, .badge.bg-soft-danger[href]:hover {
  color: #e63757;
  text-decoration: none;
  background-color: #2e1f34
}

.badge.bg-soft-light {
  color: #152e4d;
  background-color: #132842
}

.badge.bg-soft-light[href]:focus, .badge.bg-soft-light[href]:hover {
  color: #152e4d;
  text-decoration: none;
  background-color: #0d1c2e
}

.badge.bg-soft-dark {
  color: #12263f;
  background-color: #12263f
}

.badge.bg-soft-dark[href]:focus, .badge.bg-soft-dark[href]:hover {
  color: #12263f;
  text-decoration: none;
  background-color: #0c1a2b
}

.badge.bg-soft-white {
  color: #fff;
  background-color: #415165
}

.badge.bg-soft-white[href]:focus, .badge.bg-soft-white[href]:hover {
  color: #fff;
  text-decoration: none;
  background-color: #374555
}

.alert-link {
  text-decoration: underline
}

.alert-primary {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5
}

.alert-primary .alert-link {
  color: #e6e6e6
}

.alert-primary .alert-link, .alert-primary .close {
  color: #fff
}

.alert-primary hr {
  background-color: #1b6edc
}

.alert-secondary {
  color: #fff;
  background-color: #6e84a3;
  border-color: #6e84a3
}

.alert-secondary .alert-link {
  color: #e6e6e6
}

.alert-secondary .alert-link, .alert-secondary .close {
  color: #fff
}

.alert-secondary hr {
  background-color: #607797
}

.alert-success {
  color: #fff;
  background-color: #00d97e;
  border-color: #00d97e
}

.alert-success .alert-link {
  color: #e6e6e6
}

.alert-success .alert-link, .alert-success .close {
  color: #fff
}

.alert-success hr {
  background-color: #00c06f
}

.alert-info {
  color: #fff;
  background-color: #39afd1;
  border-color: #39afd1
}

.alert-info .alert-link {
  color: #e6e6e6
}

.alert-info .alert-link, .alert-info .close {
  color: #fff
}

.alert-info hr {
  background-color: #2da2c3
}

.alert-warning {
  color: #283e59;
  background-color: #f6c343;
  border-color: #f6c343
}

.alert-warning .alert-link {
  color: #182536
}

.alert-warning .alert-link, .alert-warning .close {
  color: #283e59
}

.alert-warning hr {
  background-color: #f5bb2b
}

.alert-danger {
  color: #fff;
  background-color: #e63757;
  border-color: #e63757
}

.alert-danger .alert-link {
  color: #e6e6e6
}

.alert-danger .alert-link, .alert-danger .close {
  color: #fff
}

.alert-danger hr {
  background-color: #e32044
}

.alert-light {
  color: #fff;
  background-color: #152e4d;
  border-color: #152e4d
}

.alert-light .alert-link {
  color: #e6e6e6
}

.alert-light .alert-link, .alert-light .close {
  color: #fff
}

.alert-light hr {
  background-color: #102239
}

.alert-dark {
  color: #fff;
  background-color: #12263f;
  border-color: #12263f
}

.alert-dark .alert-link {
  color: #e6e6e6
}

.alert-dark .alert-link, .alert-dark .close {
  color: #fff
}

.alert-dark hr {
  background-color: #0c1a2b
}

.alert-white {
  color: #283e59;
  background-color: #fff;
  border-color: #fff
}

.alert-white .alert-link {
  color: #182536
}

.alert-white .alert-link, .alert-white .close {
  color: #283e59
}

.alert-white hr {
  background-color: #f2f2f2
}

.progress-bar:first-child {
  border-top-left-radius: 200px;
  border-bottom-left-radius: 200px
}

.progress-bar:last-child {
  border-top-right-radius: 200px;
  border-bottom-right-radius: 200px
}

.progress-sm {
  height: .25rem
}

.list-group-item-primary {
  color: color-yiq(#2c7be5);
  background-color: #2c7be5
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
  color: color-yiq(#2c7be5);
  background-color: #1b6edc
}

.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: color-yiq(#2c7be5);
  border-color: color-yiq(#2c7be5)
}

.list-group-item-secondary {
  color: color-yiq(#6e84a3);
  background-color: #6e84a3
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
  color: color-yiq(#6e84a3);
  background-color: #607797
}

.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: color-yiq(#6e84a3);
  border-color: color-yiq(#6e84a3)
}

.list-group-item-success {
  color: color-yiq(#00d97e);
  background-color: #00d97e
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
  color: color-yiq(#00d97e);
  background-color: #00c06f
}

.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: color-yiq(#00d97e);
  border-color: color-yiq(#00d97e)
}

.list-group-item-info {
  color: color-yiq(#39afd1);
  background-color: #39afd1
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
  color: color-yiq(#39afd1);
  background-color: #2da2c3
}

.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: color-yiq(#39afd1);
  border-color: color-yiq(#39afd1)
}

.list-group-item-warning {
  color: color-yiq(#f6c343);
  background-color: #f6c343
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
  color: color-yiq(#f6c343);
  background-color: #f5bb2b
}

.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: color-yiq(#f6c343);
  border-color: color-yiq(#f6c343)
}

.list-group-item-danger {
  color: color-yiq(#e63757);
  background-color: #e63757
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
  color: color-yiq(#e63757);
  background-color: #e32044
}

.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: color-yiq(#e63757);
  border-color: color-yiq(#e63757)
}

.list-group-item-light {
  color: color-yiq(#152e4d);
  background-color: #152e4d
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
  color: color-yiq(#152e4d);
  background-color: #102239
}

.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: color-yiq(#152e4d);
  border-color: color-yiq(#152e4d)
}

.list-group-item-dark {
  color: color-yiq(#12263f);
  background-color: #12263f
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
  color: color-yiq(#12263f);
  background-color: #0c1a2b
}

.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: color-yiq(#12263f);
  border-color: color-yiq(#12263f)
}

.list-group-item-white {
  color: color-yiq(#fff);
  background-color: #fff
}

.list-group-item-white.list-group-item-action:focus, .list-group-item-white.list-group-item-action:hover {
  color: color-yiq(#fff);
  background-color: #f2f2f2
}

.list-group-item-white.list-group-item-action.active {
  color: #fff;
  background-color: color-yiq(#fff);
  border-color: color-yiq(#fff)
}

.list-group-lg .list-group-item {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.list-group-flush > .list-group-item {
  padding-left: 0;
  padding-right: 0
}

.list-group-flush:not(:last-child) > .list-group-item:last-child {
  border-bottom-width: 1px
}

.list-group-focus .list-group-item:focus .text-focus {
  color: #2c7be5 !important
}

.list-group-activity .list-group-item {
  border: 0
}

.list-group-activity .list-group-item:not(:last-child):before {
  content: "";
  position: absolute;
  top: 1rem;
  left: 1.25rem;
  height: 100%;
  border-left: 1px solid #1e3a5c
}

.close {
  float: right
}

.close-sm {
  font-size: .9375rem
}

.modal.fade .modal-dialog {
  transform: translateY(-150px)
}

.modal.show .modal-dialog {
  transform: translate(0)
}

.modal-header .close {
  margin: -1.5rem -1.5rem -1.5rem auto
}

.modal-dialog-vertical {
  height: 100%;
  max-width: 350px;
  margin: 0
}

.modal-dialog-vertical .modal-content {
  height: inherit;
  border-width: 0 1px 0 0;
  border-radius: 0
}

.modal-dialog-vertical .modal-header {
  border-radius: inherit
}

.modal-dialog-vertical .modal-body {
  height: inherit;
  overflow-y: auto
}

.modal.fade .modal-dialog-vertical {
  transform: translateX(-100%)
}

.modal.show .modal-dialog-vertical {
  transform: translateX(0)
}

.modal.fixed-right {
  padding-right: 0 !important
}

.modal.fixed-right .modal-dialog-vertical {
  margin-left: auto
}

.modal.fixed-right.fade .modal-dialog-vertical {
  transform: translateX(100%)
}

.modal.fixed-right.show .modal-dialog-vertical {
  transform: translateX(0)
}

.modal-card {
  margin-bottom: 0
}

.modal-card .card-body {
  max-height: 350px;
  overflow-y: auto
}

.modal-header-tabs {
  margin-top: -1rem;
  margin-bottom: calc(-1rem - 1px)
}

.popover {
  padding: .8rem .95rem
}

.popover:hover {
  visibility: visible !important
}

.popover-header {
  margin-bottom: .25rem;
  border-bottom: 0
}

.popover-body-label, .popover-body-value {
  margin-left: .25rem
}

.popover-body-indicator {
  display: inline-block;
  width: .5rem;
  height: .5rem;
  border-radius: 50%
}

.popover-lg {
  max-width: 300px
}

.popover-dark {
  background-color: #12263f;
  border-color: #12263f
}

.popover-dark .popover-header {
  font-weight: 400;
  background-color: #12263f;
  color: #fff
}

.popover-dark.bs-popover-auto[x-placement^=top] .popover-arrow:after, .popover-dark.bs-popover-auto[x-placement^=top] .popover-arrow:before, .popover-dark.bs-popover-top .popover-arrow:after, .popover-dark.bs-popover-top .popover-arrow:before {
  border-top-color: #12263f
}

.popover-dark.bs-popover-auto[x-placement^=right] .popover-arrow:after, .popover-dark.bs-popover-auto[x-placement^=right] .popover-arrow:before, .popover-dark.bs-popover-right .popover-arrow:after, .popover-dark.bs-popover-right .popover-arrow:before {
  border-right-color: #12263f
}

.popover-dark.bs-popover-auto[x-placement^=bottom] .popover-arrow:after, .popover-dark.bs-popover-auto[x-placement^=bottom] .popover-arrow:before, .popover-dark.bs-popover-bottom .popover-arrow:after, .popover-dark.bs-popover-bottom .popover-arrow:before {
  border-bottom-color: #12263f
}

.popover-dark.bs-popover-auto[x-placement^=left] .popover-arrow:after, .popover-dark.bs-popover-auto[x-placement^=left] .popover-arrow:before, .popover-dark.bs-popover-left .popover-arrow:after, .popover-dark.bs-popover-left .popover-arrow:before {
  border-left-color: #12263f
}

.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
  font-size: 1rem
}

.avatar:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  background-image: url(../img/masks/avatar-status.svg), url(../img/masks/avatar-group.svg), url(../img/masks/avatar-group-hover.svg), url(../img/masks/avatar-group-hover-last.svg)
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover
}

.avatar-title {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  line-height: 0;
  background-color: #244166;
  color: #fff
}

.avatar-offline:before, .avatar-online:before {
  content: "";
  position: absolute;
  bottom: 5%;
  right: 5%;
  width: 20%;
  height: 20%;
  border-radius: 50%
}

.avatar-offline .avatar-img, .avatar-online .avatar-img {
  -webkit-mask-image: url(../img/masks/avatar-status.svg);
  mask-image: url(../img/masks/avatar-status.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%
}

.avatar-online:before {
  background-color: #00d97e
}

.avatar-offline:before {
  background-color: #b1c2d9
}

.avatar-xs {
  width: 1.625rem;
  height: 1.625rem;
  font-size: .54167rem
}

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem;
  font-size: .83333rem
}

.avatar-lg {
  width: 4rem;
  height: 4rem;
  font-size: 1.33333rem
}

.avatar-xl, .avatar-xxl {
  width: 5.125rem;
  height: 5.125rem;
  font-size: 1.70833rem
}

@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
    font-size: 2.66667rem
  }
}

.avatar.avatar-4by3 {
  width: 4rem
}

.avatar-xs.avatar-4by3 {
  width: 2.16667rem
}

.avatar-sm.avatar-4by3 {
  width: 3.33333rem
}

.avatar-lg.avatar-4by3 {
  width: 5.33333rem
}

.avatar-xl.avatar-4by3 {
  width: 6.83333rem
}

.avatar-xxl.avatar-4by3 {
  width: 10.66667rem
}

.avatar-group {
  display: inline-flex
}

.avatar-group .avatar + .avatar {
  margin-left: -.75rem
}

.avatar-group .avatar-xs + .avatar-xs {
  margin-left: -.40625rem
}

.avatar-group .avatar-sm + .avatar-sm {
  margin-left: -.625rem
}

.avatar-group .avatar-lg + .avatar-lg {
  margin-left: -1rem
}

.avatar-group .avatar-xl + .avatar-xl {
  margin-left: -1.28125rem
}

.avatar-group .avatar-xxl + .avatar-xxl {
  margin-left: -2rem
}

.avatar-group .avatar:not(:last-child) {
  -webkit-mask-image: url(../img/masks/avatar-group.svg);
  mask-image: url(../img/masks/avatar-group.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%
}

.avatar-group .avatar:hover {
  -webkit-mask-image: none;
  mask-image: none;
  z-index: 1
}

.avatar-group .avatar:hover + .avatar {
  -webkit-mask-image: url(../img/masks/avatar-group-hover.svg);
  mask-image: url(../img/masks/avatar-group-hover.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%
}

.avatar-group .avatar:hover + .avatar:last-child {
  -webkit-mask-image: url(../img/masks/avatar-group-hover-last.svg);
  mask-image: url(../img/masks/avatar-group-hover-last.svg)
}

.chart {
  position: relative;
  height: 300px
}

.chart.chart-appended {
  height: calc(300px - 3.71875rem)
}

.chart-sm {
  height: 225px
}

.chart-sm.chart-appended {
  height: calc(225px - 3.71875rem)
}

.chart-sparkline {
  width: 75px;
  height: 35px
}

.chart-legend {
  display: flex;
  justify-content: center;
  margin-top: 2.5rem;
  font-size: .8125rem;
  text-align: center;
  color: #6e84a3
}

.chart-legend-item {
  display: inline-flex;
  align-items: center
}

.chart-legend-item + .chart-legend-item {
  margin-left: 1rem
}

.chart-legend-indicator {
  display: inline-block;
  width: .5rem;
  height: .5rem;
  margin-right: .375rem;
  border-radius: 50%
}

#chart-tooltip {
  z-index: 0
}

#chart-tooltip .popover-arrow {
  top: 100%;
  left: 50%;
  transform: translateX(-50%) translateX(-.5rem)
}

.comment {
  margin-bottom: 1rem
}

.comment-body {
  display: inline-block;
  padding: 1rem 1.25rem;
  background-color: #1e3a5c;
  border-radius: .5rem
}

.comment-time {
  display: block;
  margin-bottom: .5625rem;
  font-size: .625rem;
  color: #6e84a3
}

.comment-text {
  font-size: .8125rem
}

.comment-text:last-child {
  margin-bottom: 0
}

.checklist {
  outline: none
}

.checklist .form-check {
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.checklist .form-check + .form-check {
  margin-top: .75rem
}

.checklist .form-check:first-child[style*="display: none"] + .form-check {
  margin-top: 0
}

.checklist .form-check.draggable-mirror {
  z-index: 1030
}

.checklist .form-check.draggable-source--is-dragging {
  opacity: .2
}

.checklist .form-check .form-check-input:checked + .form-check-label {
  text-decoration: line-through;
  color: #6e84a3
}

.header {
  margin-bottom: 2rem
}

.header-img-top {
  width: 100%;
  height: auto
}

.header-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #1e3a5c
}

.header.bg-dark .header-body, .header.bg-hero .header-body {
  border-bottom-color: #1e3a5c
}

.header-footer {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.header-pretitle {
  text-transform: uppercase;
  letter-spacing: .08em;
  color: #6e84a3
}

.header-title {
  margin-bottom: 0
}

.header-subtitle {
  margin-top: .375rem;
  margin-bottom: 0;
  color: #6e84a3
}

.header-tabs {
  margin-bottom: -1.5rem;
  border-bottom-width: 0
}

.header-tabs .nav-link {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem
}

.icon {
  display: inline-block
}

.icon > .fe {
  display: block;
  min-width: 1.5em;
  min-height: 1.5em;
  text-align: center;
  font-size: 1.0625rem
}

.icon.active {
  position: relative
}

.icon.active > .fe {
  -webkit-mask-image: url(../img/masks/icon-status.svg);
  mask-image: url(../img/masks/icon-status.svg);
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%
}

.icon.active:after {
  content: "";
  position: absolute;
  top: 10%;
  right: 20%;
  width: 20%;
  height: 20%;
  border-radius: 50%;
  background-color: #2c7be5
}

.container-fluid.kanban-container, .kanban-container.container-lg, .kanban-container.container-md, .kanban-container.container-sm, .kanban-container.container-xl {
  min-height: calc(100vh - 129px)
}

.container.kanban-container {
  min-height: calc(100vh - 198px)
}

.kanban-container {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch
}

.kanban-container > .row {
  flex-wrap: nowrap
}

.kanban-container > .row > [class*=col] {
  max-width: 375px
}

.kanban-category {
  min-height: 1rem
}

.kanban-item {
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.kanban-item.draggable-source--is-dragging {
  opacity: .2
}

.kanban-item.draggable-mirror {
  z-index: 1030
}

.card-body .kanban-item.draggable-mirror > .card {
  transform: rotate(-3deg)
}

.kanban-item > .card[data-toggle=modal] {
  cursor: pointer
}

.kanban-add-form .form-control[data-flatpickr] {
  width: 12ch
}

@media (min-width: 768px) {
  .navbar-vertical-sm:not([style*="display: none"]) ~ .main-content .container, .navbar-vertical-sm:not([style*="display: none"]) ~ .main-content .container-fluid, .navbar-vertical-sm:not([style*="display: none"]) ~ .main-content .container-lg, .navbar-vertical-sm:not([style*="display: none"]) ~ .main-content .container-md, .navbar-vertical-sm:not([style*="display: none"]) ~ .main-content .container-sm, .navbar-vertical-sm:not([style*="display: none"]) ~ .main-content .container-xl, .navbar-vertical:not([style*="display: none"]) ~ .main-content .container, .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-fluid, .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-lg, .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-md, .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-sm, .navbar-vertical:not([style*="display: none"]) ~ .main-content .container-xl {
    padding-left: 2.25rem !important;
    padding-right: 2.25rem !important
  }
}

.navbar-vertical.navbar-expand-xs.fixed-left:not([style*="display: none"]) ~ .main-content {
  margin-left: 290px
}

.navbar-vertical.navbar-expand-xs.fixed-right:not([style*="display: none"]) ~ .main-content {
  margin-right: 250px
}

@media (min-width: 576px) {
  .navbar-vertical.navbar-expand-sm.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 290px
  }
  .navbar-vertical.navbar-expand-sm.fixed-right:not([style*="display: none"]) ~ .main-content {
    margin-right: 250px
  }
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 290px
  }
  .navbar-vertical.navbar-expand-md.fixed-right:not([style*="display: none"]) ~ .main-content {
    margin-right: 250px
  }
}

@media (min-width: 992px) {
  .navbar-vertical.navbar-expand-lg.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 290px
  }
  .navbar-vertical.navbar-expand-lg.fixed-right:not([style*="display: none"]) ~ .main-content {
    margin-right: 250px
  }
}

@media (min-width: 1200px) {
  .navbar-vertical.navbar-expand-xl.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 290px
  }
  .navbar-vertical.navbar-expand-xl.fixed-right:not([style*="display: none"]) ~ .main-content {
    margin-right: 250px
  }
}

@media (min-width: 1400px) {
  .navbar-vertical.navbar-expand-xxl.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 290px
  }
  .navbar-vertical.navbar-expand-xxl.fixed-right:not([style*="display: none"]) ~ .main-content {
    margin-right: 250px
  }
}

.navbar-vertical-sm.navbar-expand-xs.fixed-left:not([style*="display: none"]) ~ .main-content {
  margin-left: 66px
}

.navbar-vertical-sm.navbar-expand-xs.fixed-right:not([style*="display: none"]) ~ .main-content {
  margin-right: 66px
}

@media (min-width: 576px) {
  .navbar-vertical-sm.navbar-expand-sm.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 66px
  }
  .navbar-vertical-sm.navbar-expand-sm.fixed-right:not([style*="display: none"]) ~ .main-content {
    margin-right: 66px
  }
}

@media (min-width: 768px) {
  .navbar-vertical-sm.navbar-expand-md.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 66px
  }
  .navbar-vertical-sm.navbar-expand-md.fixed-right:not([style*="display: none"]) ~ .main-content {
    margin-right: 66px
  }
}

@media (min-width: 992px) {
  .navbar-vertical-sm.navbar-expand-lg.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 66px
  }
  .navbar-vertical-sm.navbar-expand-lg.fixed-right:not([style*="display: none"]) ~ .main-content {
    margin-right: 66px
  }
}

@media (min-width: 1200px) {
  .navbar-vertical-sm.navbar-expand-xl.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 66px
  }
  .navbar-vertical-sm.navbar-expand-xl.fixed-right:not([style*="display: none"]) ~ .main-content {
    margin-right: 66px
  }
}

@media (min-width: 1400px) {
  .navbar-vertical-sm.navbar-expand-xxl.fixed-left:not([style*="display: none"]) ~ .main-content {
    margin-left: 66px
  }
  .navbar-vertical-sm.navbar-expand-xxl.fixed-right:not([style*="display: none"]) ~ .main-content {
    margin-right: 66px
  }
}

.choices {
  position: relative;
  cursor: pointer
}

.choices:focus {
  outline: none
}

.choices:after {
  display: none
}

.choices > .dropdown-menu {
  width: 100%
}

.choices > .dropdown-menu .form-control {
  width: calc(100% - 1.5rem);
  margin: .375rem .75rem
}

.choices > .dropdown-menu .dropdown-item {
  padding-left: .75rem;
  padding-right: .75rem
}

.choices > .dropdown-menu .dropdown-item.is-highlighted {
  color: #fff
}

.choices > .form-control-sm + .dropdown-menu, .choices > .form-select-sm + .dropdown-menu {
  min-width: 100%;
  width: auto
}

.choices > .form-control-sm + .dropdown-menu .dropdown-item, .choices > .form-select-sm + .dropdown-menu .dropdown-item {
  padding-right: .5rem;
  padding-left: .5rem;
  font-size: .8125rem
}

.choices > .form-control-sm + .dropdown-menu .form-control, .choices > .form-select-sm + .dropdown-menu .form-control {
  margin-right: .5rem;
  margin-left: .5rem
}

.choices__placeholder {
  color: #95aac9;
  opacity: 1
}

.choices.is-open > .form-control, .choices.is-open > .form-select {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0
}

.choices.is-open > .form-control + .dropdown-menu, .choices.is-open > .form-select + .dropdown-menu {
  margin-top: -1px;
  border-top-right-radius: 0;
  border-top-left-radius: 0
}

.choices__list--multiple + .form-control {
  display: inline-block;
  min-height: 0;
  width: auto;
  padding: 0;
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  font-size: .9375rem
}

.form-control-lg + .choices__list--multiple + .form-control, .form-select-lg + .choices__list--multiple + .form-control {
  font-size: .9375rem
}

.form-control-sm + .choices__list--multiple + .form-control, .form-select-sm + .choices__list--multiple + .form-control {
  font-size: .8125rem
}

.choices__list--multiple {
  display: inline
}

.choices__list--multiple > .choices__item {
  display: inline-block;
  margin-right: .2rem;
  padding-right: .375rem;
  padding-left: .375rem;
  font-size: .8125rem;
  background-color: #152e4d;
  border-radius: .1875rem
}

.choices__list--multiple > .choices__item > .choices__button {
  width: .5rem;
  margin-left: .5rem;
  padding: 0;
  outline: none;
  border-width: 0;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: 50%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='%23fff' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M18 6L6 18M6 6l12 12'/%3E%3C/svg%3E");
  background-size: 100% auto;
  text-indent: -9999px;
  opacity: .5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.choices__list--multiple > .choices__item:hover > .choices__button {
  opacity: 1
}

.dropzone {
  position: relative;
  display: flex;
  flex-direction: column
}

.dz-message {
  padding: 5rem 1rem;
  background-color: #1e3a5c;
  border: 1px dashed #1e3a5c;
  border-radius: .375rem;
  text-align: center;
  color: #6e84a3;
  transition: all .2s ease-in-out;
  order: -1;
  cursor: pointer;
  z-index: 999
}

.dz-message:hover {
  border-color: #6e84a3;
  color: #fff
}

.dz-button {
  background: none;
  border: 0;
  font-size: inherit;
  color: inherit
}

.dz-drag-hover .dz-message {
  border-color: #2c7be5;
  color: #2c7be5
}

.dropzone-multiple .dz-message {
  padding-top: 2rem;
  padding-bottom: 2rem
}

.dropzone-single.dz-max-files-reached .dz-message {
  background-color: rgba(18, 38, 63, .9);
  color: #fff;
  opacity: 0
}

.dropzone-single.dz-max-files-reached .dz-message:hover {
  opacity: 1
}

.dz-preview-cover, .dz-preview-single {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: .375rem
}

.dz-preview-img {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: .375rem
}

.dz-preview-multiple .list-group-item:last-child {
  padding-bottom: 0;
  border-bottom: 0
}

.fe, [data-dz-size] strong {
  font-weight: 400
}

.fe {
  font-family: Feather !important;
  font-style: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale
}

.fe-activity:before {
  content: "\e900"
}

.fe-airplay:before {
  content: "\e901"
}

.fe-alert-circle:before {
  content: "\e902"
}

.fe-alert-octagon:before {
  content: "\e903"
}

.fe-alert-triangle:before {
  content: "\e904"
}

.fe-align-center:before {
  content: "\e905"
}

.fe-align-justify:before {
  content: "\e906"
}

.fe-align-left:before {
  content: "\e907"
}

.fe-align-right:before {
  content: "\e908"
}

.fe-anchor:before {
  content: "\e909"
}

.fe-aperture:before {
  content: "\e90a"
}

.fe-archive:before {
  content: "\e90b"
}

.fe-arrow-down:before {
  content: "\e90c"
}

.fe-arrow-down-circle:before {
  content: "\e90d"
}

.fe-arrow-down-left:before {
  content: "\e90e"
}

.fe-arrow-down-right:before {
  content: "\e90f"
}

.fe-arrow-left:before {
  content: "\e910"
}

.fe-arrow-left-circle:before {
  content: "\e911"
}

.fe-arrow-right:before {
  content: "\e912"
}

.fe-arrow-right-circle:before {
  content: "\e913"
}

.fe-arrow-up:before {
  content: "\e914"
}

.fe-arrow-up-circle:before {
  content: "\e915"
}

.fe-arrow-up-left:before {
  content: "\e916"
}

.fe-arrow-up-right:before {
  content: "\e917"
}

.fe-at-sign:before {
  content: "\e918"
}

.fe-award:before {
  content: "\e919"
}

.fe-bar-chart:before {
  content: "\e91a"
}

.fe-bar-chart-2:before {
  content: "\e91b"
}

.fe-battery:before {
  content: "\e91c"
}

.fe-battery-charging:before {
  content: "\e91d"
}

.fe-bell:before {
  content: "\e91e"
}

.fe-bell-off:before {
  content: "\e91f"
}

.fe-bluetooth:before {
  content: "\e920"
}

.fe-bold:before {
  content: "\e921"
}

.fe-book:before {
  content: "\e922"
}

.fe-book-open:before {
  content: "\e923"
}

.fe-bookmark:before {
  content: "\e924"
}

.fe-box:before {
  content: "\e925"
}

.fe-briefcase:before {
  content: "\e926"
}

.fe-calendar:before {
  content: "\e927"
}

.fe-camera:before {
  content: "\e928"
}

.fe-camera-off:before {
  content: "\e929"
}

.fe-cast:before {
  content: "\e92a"
}

.fe-check:before {
  content: "\e92b"
}

.fe-check-circle:before {
  content: "\e92c"
}

.fe-check-square:before {
  content: "\e92d"
}

.fe-chevron-down:before {
  content: "\e92e"
}

.fe-chevron-left:before {
  content: "\e92f"
}

.fe-chevron-right:before {
  content: "\e930"
}

.fe-chevron-up:before {
  content: "\e931"
}

.fe-chevrons-down:before {
  content: "\e932"
}

.fe-chevrons-left:before {
  content: "\e933"
}

.fe-chevrons-right:before {
  content: "\e934"
}

.fe-chevrons-up:before {
  content: "\e935"
}

.fe-chrome:before {
  content: "\e936"
}

.fe-circle:before {
  content: "\e937"
}

.fe-clipboard:before {
  content: "\e938"
}

.fe-clock:before {
  content: "\e939"
}

.fe-cloud:before {
  content: "\e93a"
}

.fe-cloud-drizzle:before {
  content: "\e93b"
}

.fe-cloud-lightning:before {
  content: "\e93c"
}

.fe-cloud-off:before {
  content: "\e93d"
}

.fe-cloud-rain:before {
  content: "\e93e"
}

.fe-cloud-snow:before {
  content: "\e93f"
}

.fe-code:before {
  content: "\e940"
}

.fe-codepen:before {
  content: "\e941"
}

.fe-command:before {
  content: "\e942"
}

.fe-compass:before {
  content: "\e943"
}

.fe-copy:before {
  content: "\e944"
}

.fe-corner-down-left:before {
  content: "\e945"
}

.fe-corner-down-right:before {
  content: "\e946"
}

.fe-corner-left-down:before {
  content: "\e947"
}

.fe-corner-left-up:before {
  content: "\e948"
}

.fe-corner-right-down:before {
  content: "\e949"
}

.fe-corner-right-up:before {
  content: "\e94a"
}

.fe-corner-up-left:before {
  content: "\e94b"
}

.fe-corner-up-right:before {
  content: "\e94c"
}

.fe-cpu:before {
  content: "\e94d"
}

.fe-credit-card:before {
  content: "\e94e"
}

.fe-crop:before {
  content: "\e94f"
}

.fe-crosshair:before {
  content: "\e950"
}

.fe-database:before {
  content: "\e951"
}

.fe-delete:before {
  content: "\e952"
}

.fe-disc:before {
  content: "\e953"
}

.fe-dollar-sign:before {
  content: "\e954"
}

.fe-download:before {
  content: "\e955"
}

.fe-download-cloud:before {
  content: "\e956"
}

.fe-droplet:before {
  content: "\e957"
}

.fe-edit:before {
  content: "\e958"
}

.fe-edit-2:before {
  content: "\e959"
}

.fe-edit-3:before {
  content: "\e95a"
}

.fe-external-link:before {
  content: "\e95b"
}

.fe-eye:before {
  content: "\e95c"
}

.fe-eye-off:before {
  content: "\e95d"
}

.fe-facebook:before {
  content: "\e95e"
}

.fe-fast-forward:before {
  content: "\e95f"
}

.fe-feather:before {
  content: "\e960"
}

.fe-file:before {
  content: "\e961"
}

.fe-file-minus:before {
  content: "\e962"
}

.fe-file-plus:before {
  content: "\e963"
}

.fe-file-text:before {
  content: "\e964"
}

.fe-film:before {
  content: "\e965"
}

.fe-filter:before {
  content: "\e966"
}

.fe-flag:before {
  content: "\e967"
}

.fe-folder:before {
  content: "\e968"
}

.fe-folder-minus:before {
  content: "\e969"
}

.fe-folder-plus:before {
  content: "\e96a"
}

.fe-gift:before {
  content: "\e96b"
}

.fe-git-branch:before {
  content: "\e96c"
}

.fe-git-commit:before {
  content: "\e96d"
}

.fe-git-merge:before {
  content: "\e96e"
}

.fe-git-pull-request:before {
  content: "\e96f"
}

.fe-github:before {
  content: "\e970"
}

.fe-gitlab:before {
  content: "\e971"
}

.fe-globe:before {
  content: "\e972"
}

.fe-grid:before {
  content: "\e973"
}

.fe-hard-drive:before {
  content: "\e974"
}

.fe-hash:before {
  content: "\e975"
}

.fe-headphones:before {
  content: "\e976"
}

.fe-heart:before {
  content: "\e977"
}

.fe-help-circle:before {
  content: "\e978"
}

.fe-home:before {
  content: "\e979"
}

.fe-image:before {
  content: "\e97a"
}

.fe-inbox:before {
  content: "\e97b"
}

.fe-info:before {
  content: "\e97c"
}

.fe-instagram:before {
  content: "\e97d"
}

.fe-italic:before {
  content: "\e97e"
}

.fe-layers:before {
  content: "\e97f"
}

.fe-layout:before {
  content: "\e980"
}

.fe-life-buoy:before {
  content: "\e981"
}

.fe-link:before {
  content: "\e982"
}

.fe-link-2:before {
  content: "\e983"
}

.fe-linkedin:before {
  content: "\e984"
}

.fe-list:before {
  content: "\e985"
}

.fe-loader:before {
  content: "\e986"
}

.fe-lock:before {
  content: "\e987"
}

.fe-log-in:before {
  content: "\e988"
}

.fe-log-out:before {
  content: "\e989"
}

.fe-mail:before {
  content: "\e98a"
}

.fe-map:before {
  content: "\e98b"
}

.fe-map-pin:before {
  content: "\e98c"
}

.fe-maximize:before {
  content: "\e98d"
}

.fe-maximize-2:before {
  content: "\e98e"
}

.fe-menu:before {
  content: "\e98f"
}

.fe-message-circle:before {
  content: "\e990"
}

.fe-message-square:before {
  content: "\e991"
}

.fe-mic:before {
  content: "\e992"
}

.fe-mic-off:before {
  content: "\e993"
}

.fe-minimize:before {
  content: "\e994"
}

.fe-minimize-2:before {
  content: "\e995"
}

.fe-minus:before {
  content: "\e996"
}

.fe-minus-circle:before {
  content: "\e997"
}

.fe-minus-square:before {
  content: "\e998"
}

.fe-monitor:before {
  content: "\e999"
}

.fe-moon:before {
  content: "\e99a"
}

.fe-more-horizontal:before {
  content: "\e99b"
}

.fe-more-vertical:before {
  content: "\e99c"
}

.fe-move:before {
  content: "\e99d"
}

.fe-music:before {
  content: "\e99e"
}

.fe-navigation:before {
  content: "\e99f"
}

.fe-navigation-2:before {
  content: "\e9a0"
}

.fe-octagon:before {
  content: "\e9a1"
}

.fe-package:before {
  content: "\e9a2"
}

.fe-paperclip:before {
  content: "\e9a3"
}

.fe-pause:before {
  content: "\e9a4"
}

.fe-pause-circle:before {
  content: "\e9a5"
}

.fe-percent:before {
  content: "\e9a6"
}

.fe-phone:before {
  content: "\e9a7"
}

.fe-phone-call:before {
  content: "\e9a8"
}

.fe-phone-forwarded:before {
  content: "\e9a9"
}

.fe-phone-incoming:before {
  content: "\e9aa"
}

.fe-phone-missed:before {
  content: "\e9ab"
}

.fe-phone-off:before {
  content: "\e9ac"
}

.fe-phone-outgoing:before {
  content: "\e9ad"
}

.fe-pie-chart:before {
  content: "\e9ae"
}

.fe-play:before {
  content: "\e9af"
}

.fe-play-circle:before {
  content: "\e9b0"
}

.fe-plus:before {
  content: "\e9b1"
}

.fe-plus-circle:before {
  content: "\e9b2"
}

.fe-plus-square:before {
  content: "\e9b3"
}

.fe-pocket:before {
  content: "\e9b4"
}

.fe-power:before {
  content: "\e9b5"
}

.fe-printer:before {
  content: "\e9b6"
}

.fe-radio:before {
  content: "\e9b7"
}

.fe-refresh-ccw:before {
  content: "\e9b8"
}

.fe-refresh-cw:before {
  content: "\e9b9"
}

.fe-repeat:before {
  content: "\e9ba"
}

.fe-rewind:before {
  content: "\e9bb"
}

.fe-rotate-ccw:before {
  content: "\e9bc"
}

.fe-rotate-cw:before {
  content: "\e9bd"
}

.fe-rss:before {
  content: "\e9be"
}

.fe-save:before {
  content: "\e9bf"
}

.fe-scissors:before {
  content: "\e9c0"
}

.fe-search:before {
  content: "\e9c1"
}

.fe-send:before {
  content: "\e9c2"
}

.fe-server:before {
  content: "\e9c3"
}

.fe-settings:before {
  content: "\e9c4"
}

.fe-share:before {
  content: "\e9c5"
}

.fe-share-2:before {
  content: "\e9c6"
}

.fe-shield:before {
  content: "\e9c7"
}

.fe-shield-off:before {
  content: "\e9c8"
}

.fe-shopping-bag:before {
  content: "\e9c9"
}

.fe-shopping-cart:before {
  content: "\e9ca"
}

.fe-shuffle:before {
  content: "\e9cb"
}

.fe-sidebar:before {
  content: "\e9cc"
}

.fe-skip-back:before {
  content: "\e9cd"
}

.fe-skip-forward:before {
  content: "\e9ce"
}

.fe-slack:before {
  content: "\e9cf"
}

.fe-slash:before {
  content: "\e9d0"
}

.fe-sliders:before {
  content: "\e9d1"
}

.fe-smartphone:before {
  content: "\e9d2"
}

.fe-speaker:before {
  content: "\e9d3"
}

.fe-square:before {
  content: "\e9d4"
}

.fe-star:before {
  content: "\e9d5"
}

.fe-stop-circle:before {
  content: "\e9d6"
}

.fe-sun:before {
  content: "\e9d7"
}

.fe-sunrise:before {
  content: "\e9d8"
}

.fe-sunset:before {
  content: "\e9d9"
}

.fe-tablet:before {
  content: "\e9da"
}

.fe-tag:before {
  content: "\e9db"
}

.fe-target:before {
  content: "\e9dc"
}

.fe-terminal:before {
  content: "\e9dd"
}

.fe-thermometer:before {
  content: "\e9de"
}

.fe-thumbs-down:before {
  content: "\e9df"
}

.fe-thumbs-up:before {
  content: "\e9e0"
}

.fe-toggle-left:before {
  content: "\e9e1"
}

.fe-toggle-right:before {
  content: "\e9e2"
}

.fe-trash:before {
  content: "\e9e3"
}

.fe-trash-2:before {
  content: "\e9e4"
}

.fe-trending-down:before {
  content: "\e9e5"
}

.fe-trending-up:before {
  content: "\e9e6"
}

.fe-triangle:before {
  content: "\e9e7"
}

.fe-truck:before {
  content: "\e9e8"
}

.fe-tv:before {
  content: "\e9e9"
}

.fe-twitter:before {
  content: "\e9ea"
}

.fe-type:before {
  content: "\e9eb"
}

.fe-umbrella:before {
  content: "\e9ec"
}

.fe-underline:before {
  content: "\e9ed"
}

.fe-unlock:before {
  content: "\e9ee"
}

.fe-upload:before {
  content: "\e9ef"
}

.fe-upload-cloud:before {
  content: "\e9f0"
}

.fe-user:before {
  content: "\e9f1"
}

.fe-user-check:before {
  content: "\e9f2"
}

.fe-user-minus:before {
  content: "\e9f3"
}

.fe-user-plus:before {
  content: "\e9f4"
}

.fe-user-x:before {
  content: "\e9f5"
}

.fe-users:before {
  content: "\e9f6"
}

.fe-video:before {
  content: "\e9f7"
}

.fe-video-off:before {
  content: "\e9f8"
}

.fe-voicemail:before {
  content: "\e9f9"
}

.fe-volume:before {
  content: "\e9fa"
}

.fe-volume-1:before {
  content: "\e9fb"
}

.fe-volume-2:before {
  content: "\e9fc"
}

.fe-volume-x:before {
  content: "\e9fd"
}

.fe-watch:before {
  content: "\e9fe"
}

.fe-wifi:before {
  content: "\e9ff"
}

.fe-wifi-off:before {
  content: "\ea00"
}

.fe-wind:before {
  content: "\ea01"
}

.fe-x:before {
  content: "\ea02"
}

.fe-x-circle:before {
  content: "\ea03"
}

.fe-x-square:before {
  content: "\ea04"
}

.fe-youtube:before {
  content: "\ea05"
}

.fe-zap:before {
  content: "\ea06"
}

.fe-zap-off:before {
  content: "\ea07"
}

.fe-zoom-in:before {
  content: "\ea08"
}

.fe-zoom-out:before {
  content: "\ea09"
}

.flatpickr-calendar {
  background-color: #1e3a5c;
  border: 1px solid #1e3a5c;
  color: #fff;
  box-shadow: none
}

.flatpickr-calendar * {
  color: inherit !important;
  fill: currentColor !important
}

.flatpickr-calendar.arrowTop:after, .flatpickr-calendar.arrowTop:before {
  border-bottom-color: #1e3a5c
}

.flatpickr-calendar .flatpickr-months {
  padding-top: .625rem;
  padding-bottom: .625rem
}

.flatpickr-calendar .flatpickr-next-month, .flatpickr-calendar .flatpickr-prev-month {
  top: .625rem
}

.flatpickr-calendar .flatpickr-current-month {
  font-size: 115%
}

.flatpickr-calendar .flatpickr-day {
  border-radius: .375rem
}

.flatpickr-calendar .flatpickr-day:hover {
  background-color: #152e4d;
  border-color: #1e3a5c
}

.flatpickr-calendar .flatpickr-day.prevMonthDay {
  color: #6e84a3 !important
}

.flatpickr-calendar .flatpickr-day.today {
  border-color: #1e3a5c
}

.flatpickr-calendar .flatpickr-day.selected {
  background-color: #2c7be5;
  border-color: #2c7be5;
  color: #fff !important
}

.flatpickr-calendar .flatpickr-day.inRange {
  background-color: #152e4d;
  border: none;
  border-radius: 0;
  box-shadow: -5px 0 0 #152e4d, 5px 0 0 #152e4d
}

.hljs {
  padding: 0
}

.ql-container {
  font-family: var(--bs-font-sans-serif)
}

.ql-toolbar {
  position: relative;
  padding: .5rem .75rem;
  background-color: #1e3a5c;
  border: 1px solid #1e3a5c;
  border-radius: .375rem .375rem 0 0;
  color: #fff
}

.ql-toolbar + .ql-container {
  margin-top: -1px
}

.ql-toolbar + .ql-container .ql-editor {
  border-top-left-radius: 0;
  border-top-right-radius: 0
}

.ql-editor {
  min-height: 5.625rem;
  display: block;
  width: 100%;
  padding: .5rem .75rem;
  font-size: .9375rem;
  line-height: 1.5;
  color: #fff;
  background-color: #1e3a5c;
  background-clip: padding-box;
  border: 1px solid #1e3a5c;
  border-radius: .375rem;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .ql-editor {
    transition: none
  }
}

.ql-editor::-ms-expand {
  background-color: transparent;
  border: 0
}

.ql-editor:focus {
  border-color: #2c7be5
}

.ql-hidden {
  position: absolute;
  transform: scale(0)
}

.ql-editor.ql-blank:before {
  top: .5rem;
  left: .75rem;
  font-style: normal;
  color: #95aac9
}

.ql-editor:focus:before {
  display: none
}

.ql-formats {
  padding-left: .5rem;
  padding-right: .5rem
}

.ql-formats:first-child {
  padding-left: 0
}

.ql-formats:last-child {
  padding-right: 0
}

.ql-toolbar button {
  padding: 0 .25rem;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: all .2s ease-in-out
}

.ql-toolbar button:hover {
  color: #2c7be5
}

.ql-toolbar button:first-child {
  margin-left: -.25rem
}

.ql-toolbar .ql-active {
  color: #2c7be5
}

.ql-toolbar button svg {
  height: 1.0625rem;
  width: 1.0625rem
}

.ql-toolbar .ql-stroke {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none
}

.ql-toolbar .ql-thin {
  stroke-width: 1
}

.ql-toolbar .ql-fill {
  fill: currentColor
}

.ql-toolbar input.ql-image {
  position: absolute;
  transform: scale(0)
}

.ql-tooltip {
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  width: 18.5rem;
  background-color: #152e4d;
  border: 1px solid #12263f;
  border-radius: .5rem;
  padding: .5rem .75rem;
  margin-top: .6rem;
  box-shadow: 0 0 1rem rgba(18, 38, 63, .03)
}

.ql-tooltip:after, .ql-tooltip:before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 100%;
  transform: translateX(-50%)
}

.ql-tooltip:before {
  border-bottom: .6rem solid #12263f;
  border-left: .6rem solid transparent;
  border-right: .6rem solid transparent
}

.ql-tooltip:after {
  border-bottom: .5rem solid #152e4d;
  border-left: .5rem solid transparent;
  border-right: .5rem solid transparent
}

.ql-container .ql-tooltip:hover {
  display: flex !important
}

.ql-tooltip .ql-preview {
  width: 100%;
  padding: .125rem .5rem;
  font-size: .8125rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .ql-tooltip .ql-preview {
    transition: none
  }
}

.ql-tooltip.ql-editing .ql-preview {
  display: none
}

.ql-tooltip input {
  display: none;
  width: 100%;
  padding-top: calc(.125rem + 1px);
  padding-bottom: calc(.125rem + 1px);
  background-color: transparent;
  font-size: .8125rem;
  line-height: 1.75;
  border: none;
  color: #fff
}

.ql-tooltip input:focus {
  outline: none
}

.ql-tooltip.ql-editing input {
  display: block
}

.ql-tooltip .ql-action, .ql-tooltip .ql-remove {
  margin-left: .25rem
}

.ql-tooltip .ql-action:before, .ql-tooltip .ql-remove:before {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  cursor: pointer;
  padding: .125rem .5rem;
  font-size: .8125rem;
  border-radius: .25rem;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
  .ql-tooltip .ql-action:before, .ql-tooltip .ql-remove:before {
    transition: none
  }
}

.ql-tooltip .ql-action:before:focus, .ql-tooltip .ql-action:before:hover, .ql-tooltip .ql-remove:before:focus, .ql-tooltip .ql-remove:before:hover {
  text-decoration: none
}

.ql-tooltip .ql-action:before.focus, .ql-tooltip .ql-action:before:focus, .ql-tooltip .ql-remove:before.focus, .ql-tooltip .ql-remove:before:focus {
  outline: 0;
  box-shadow: none
}

.ql-tooltip .ql-action:before, .ql-tooltip.ql-editing .ql-action:before {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5
}

.ql-tooltip .ql-action:before:hover, .ql-tooltip.ql-editing .ql-action:before:hover {
  color: #fff;
  background-color: #1a68d1;
  border-color: #1862c6
}

.btn-check:focus + .ql-tooltip .ql-action:before, .btn-check:focus + .ql-tooltip.ql-editing .ql-action:before, .ql-tooltip .ql-action:before:focus, .ql-tooltip.ql-editing .ql-action:before:focus {
  color: #fff;
  background-color: #1a68d1;
  border-color: #1862c6;
  box-shadow: 0 0 0 .15rem rgba(76, 143, 233, .5)
}

.btn-check:active + .ql-tooltip .ql-action:before, .btn-check:active + .ql-tooltip.ql-editing .ql-action:before, .btn-check:checked + .ql-tooltip .ql-action:before, .btn-check:checked + .ql-tooltip.ql-editing .ql-action:before, .ql-tooltip .ql-action:before.active, .ql-tooltip .ql-action:before:active, .ql-tooltip.ql-editing .ql-action:before.active, .ql-tooltip.ql-editing .ql-action:before:active, .show > .ql-tooltip .ql-action:before.dropdown-toggle, .show > .ql-tooltip.ql-editing .ql-action:before.dropdown-toggle {
  color: #fff;
  background-color: #1862c6;
  border-color: #175dba
}

.btn-check:active + .ql-tooltip .ql-action:before:focus, .btn-check:active + .ql-tooltip.ql-editing .ql-action:before:focus, .btn-check:checked + .ql-tooltip .ql-action:before:focus, .btn-check:checked + .ql-tooltip.ql-editing .ql-action:before:focus, .ql-tooltip .ql-action:before.active:focus, .ql-tooltip .ql-action:before:active:focus, .ql-tooltip.ql-editing .ql-action:before.active:focus, .ql-tooltip.ql-editing .ql-action:before:active:focus, .show > .ql-tooltip .ql-action:before.dropdown-toggle:focus, .show > .ql-tooltip.ql-editing .ql-action:before.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(76, 143, 233, .5)
}

.ql-tooltip .ql-action:before.disabled, .ql-tooltip .ql-action:before:disabled, .ql-tooltip.ql-editing .ql-action:before.disabled, .ql-tooltip.ql-editing .ql-action:before:disabled {
  color: #fff;
  background-color: #2c7be5;
  border-color: #2c7be5
}

.ql-tooltip .ql-action:before {
  content: "Edit"
}

.ql-tooltip.ql-editing .ql-action:before {
  content: "Save"
}

.ql-tooltip .ql-remove:before {
  color: #283e59;
  background-color: #fff;
  content: "Remove";
  border-color: #e3ebf6
}

.ql-tooltip .ql-remove:before:hover {
  color: #283e59;
  background-color: #ececec;
  border-color: #e6e6e6
}

.btn-check:focus + .ql-tooltip .ql-remove:before, .ql-tooltip .ql-remove:before:focus {
  color: #283e59;
  background-color: #ececec;
  border-color: #e6e6e6;
  box-shadow: 0 0 0 .15rem rgba(223, 226, 230, .5)
}

.btn-check:active + .ql-tooltip .ql-remove:before, .btn-check:checked + .ql-tooltip .ql-remove:before, .ql-tooltip .ql-remove:before.active, .ql-tooltip .ql-remove:before:active, .show > .ql-tooltip .ql-remove:before.dropdown-toggle {
  color: #283e59;
  background-color: #e6e6e6;
  border-color: #dfdfdf
}

.btn-check:active + .ql-tooltip .ql-remove:before:focus, .btn-check:checked + .ql-tooltip .ql-remove:before:focus, .ql-tooltip .ql-remove:before.active:focus, .ql-tooltip .ql-remove:before:active:focus, .show > .ql-tooltip .ql-remove:before.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(223, 226, 230, .5)
}

.ql-tooltip .ql-remove:before.disabled, .ql-tooltip .ql-remove:before:disabled {
  color: #283e59;
  background-color: #fff;
  border-color: #fff
}

.ql-tooltip.ql-editing .ql-remove:before {
  display: none
}

.ql-editor blockquote {
  margin-bottom: 1.5rem;
  font-size: 1.17188rem
}

.ql-editor img {
  max-width: 100%;
  height: auto
}

.list-pagination > li + li {
  margin-left: -1px
}

.list-alert {
  position: fixed;
  bottom: 1.5rem;
  left: 50%;
  z-index: 1030;
  min-width: 350px;
  margin-bottom: 0;
  transform: translateX(-50%)
}

.list-alert:not(.show) {
  pointer-events: none
}

@media (min-width: 768px) {
  .navbar-vertical:not(.navbar-vertical-sm):not([style*="display: none"]) ~ .main-content .list-alert {
    left: calc(50% + 125px)
  }
}

.list-alert .close {
  top: 50%;
  transform: translateY(-50%)
}

.btn-light, .btn-white {
  color: #fff;
  background-color: #152e4d;
  border-color: #244166
}

.btn-light:hover, .btn-white:hover {
  color: #fff;
  background-color: #0d1c2f;
  border-color: #172940
}

.btn-check:focus + .btn-light, .btn-check:focus + .btn-white, .btn-light:focus, .btn-white:focus {
  color: #fff;
  background-color: #0d1c2f;
  border-color: #172940;
  box-shadow: 0 0 0 .15rem rgba(69, 94, 125, .5)
}

.btn-check:active + .btn-light, .btn-check:active + .btn-white, .btn-check:checked + .btn-light, .btn-check:checked + .btn-white, .btn-light.active, .btn-light:active, .btn-white.active, .btn-white:active, .show > .btn-light.dropdown-toggle, .show > .btn-white.dropdown-toggle {
  color: #fff;
  background-color: #0a1625;
  border-color: #132337
}

.btn-check:active + .btn-light:focus, .btn-check:active + .btn-white:focus, .btn-check:checked + .btn-light:focus, .btn-check:checked + .btn-white:focus, .btn-light.active:focus, .btn-light:active:focus, .btn-white.active:focus, .btn-white:active:focus, .show > .btn-light.dropdown-toggle:focus, .show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 .15rem rgba(69, 94, 125, .5)
}

.btn-light.disabled, .btn-light:disabled, .btn-white.disabled, .btn-white:disabled {
  color: #fff;
  background-color: #152e4d;
  border-color: #244166
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled):focus, .btn-light:not(:disabled):not(.disabled):hover, .btn-white:not(:disabled):not(.disabled).active, .btn-white:not(:disabled):not(.disabled).active:focus, .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled):focus, .btn-white:not(:disabled):not(.disabled):hover, .show > .btn-light.dropdown-toggle, .show > .btn-white.dropdown-toggle {
  background-color: #12263f;
  border-color: #1e3a5c;
  color: #fff
}

.dz-message {
  border-color: #12263f
}

.ql-toolbar {
  border-bottom-color: #12263f
}

.ql-editor {
  border-top-color: #12263f
}
